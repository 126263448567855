import styled from "styled-components"
import branding from "../branding/branding"
import { Row, Col, Nav } from "react-bootstrap"


export const ProfilePageContainer = styled.div`
    font-family: ${branding.font1};
    background-color: #fff;
`

export const ScrollContainerRoot = styled.div<{ guestBannerHeight: number }>`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 60px - 50px - ${props => props.guestBannerHeight + "px"}); /* topbar + guestBanner */
  overflow: hidden; 
  padding-bottom: 60px;
`

export const UserFullName = styled.h3`
    margin-bottom:  10px;
    font-weight: bold;
    font-family: ${branding.font1};
    width: 265px;
    text-align: center;

    font-size: 22px; 
    line-height: 16px;  

    @media (max-width: 1600px) {
        font-size: 20px; 
        line-height: 30px;    
    }

    @media (max-width: 1200px) {
        font-size: 16px; 
        line-height: 25px;    
    }
`

export const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width: 125px;
    height: 150px;

    .avatar-styled {
        width: 125px;
        height: 125px;
        line-height: 125px;
    }

    .avt-cont {
        width: 125px;
    }

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px; 

        .avatar-styled {
        width: 120px;
        height: 120px;
        line-height: 120px;
        }
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;  

        .avatar-styled {
        width: 100px;
        height: 100px;
        line-height: 100px;
        }
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;  

        .avatar-styled {
        width: 80px;
        height: 80px;
        line-height: 80px;
        }
    }
`

export const UserBasicInfoContainer = styled.div`
`

export const DummyDiv = styled.div`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    width: 265px;
`

export const UserPositionCompany = styled.p`
    white-space: pre-line;
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    width: 265px;
    text-align: center;
    padding-right: 5px;
    padding-bottom: 15px;
    

    @media (max-width: 1600px) {
        font-size: 16px; 
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px; 
        line-height: 20px;    
    }
`

export const UserImageAndNameContainer = styled(Row)`
    justify-content: center;
    text-align: center;
`
export const ProfileContactDetailsContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 265px;
`

export const ProfileContactDetailsText = styled.div`
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  color: ${branding.mainInfoColor};
  border-radius: 10px;
  margin-left: 20px;
  &:hover {
    color: ${branding.mainInfoColor};
    }

    @media (max-width: 1600px) {
        font-size: 16px; 
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px; 
        line-height: 18px;    
    }
`

export const ProfilePageSectionTitle = styled.div`
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
`

export const BiographyText = styled.p`
    white-space: pre-line;
    font-size: 14px;
    line-height: 20px;
`

export const CategoriesRoot = styled.div`
    border-top: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'}; 
`

export const CategoriesTitleRow = styled(Row)`
    margin: 30px 0 0 0;
`

export const CategoriesViewRow = styled(Row)`
    display: flex;
    margin: 20px -5px 20px -5px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

export const CategoriesItem = styled.div<{ bold?: Boolean, border?: string, textColor?: string }>`
    display: inline-block;
    padding: .5rem;
    font-size: 14px;
    border: ${props => props.border ?? (branding.mainBorder ?? '1px solid #d9d9d9')}; 
    color: ${props => props.textColor ?? (branding.personDetailPageContent.interestItemTextColor ?? "#000")}; 
    font-family: ${props => props.bold ? branding.font2 : branding.font1};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    text-transform: ${props => props.bold ? 'uppercase' : 'unset'};
    margin: .3rem;
    border-radius: 5px;
`

export const ProfileDetailRowHeader = styled(Row)`
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 10px 0;
    justify-content: center;
`


export const PersonImageAndLogo = styled(Col)`
    margin-top: 80px;
    border-right: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
`

export const ProfileDetailNameTeaserCol = styled(Col)`
    margin-top: 80px;
    margin-left: 50px;
    width: 673px;
`

export const ProfileContactsWrapper = styled(Nav)`
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
`

export const ProfileSocialsTitle = styled.div`
    margin-top: 15px;
    border-top: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding-top: 20px;
`

export const ProfileSocialsWrapper = styled(Nav)`
    margin-top: 15px;
    margin-bottom: 40px;
    padding-top: 20px;
`
export const ProfileSocialItem = styled(Nav.Link)`
    margin-right: 20px;

    &.nav-link {
        padding: 0 !important;
    }
`