import React, { useEffect, useState } from "react"
import { useAppState } from "../../globalStates/AppState"
import { loadChannelFirstEventDate, BackendServiceError, EventDateChannelFirstDetailsResponse } from "../../backendServices/BackendServices"
import moment from "moment"
import { useUserEventAccess } from "../../globalStates/UserEventAccess"
import PictureInPictureVideoPlayerReal from "./PictureInPictureVideoPlayerReal"
import PictureInPictureVideoPlayerDummy, { PictureInPictureDummyPlayerRootProps } from "./PictureInPictureVideoPlayerDummy"
import { EventDate } from "../../backendServices/Types"
import styled from "styled-components"
import CenteredLoader from "../../ui/CenteredLoader"
import { useRouteMatch } from "react-router-dom"
import { videoRoomPageRoute } from "../../navigationArea/RoutePaths"

/* component that switches between PictureInPictureVideoPlayerReal and -Dummy depending on user access to the eventDate */

function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    return moment(nextEventStartDate, "YYYY-MM-DD HH:mm").diff(moment(currentEventEndDate, "YYYY-MM-DD HH:mm")) / 2
}

function calculateEnd(currentEventEndDate: string) {
    const format = "YYYY-MM-DD HH:mm"
    return moment(moment(currentEventEndDate).format(format)).diff(moment().format(format))
}

export interface PipPlayerSize {
    width: number,
    height: number
}
export interface PipPlayerPosition {
    x: number,
    y: number
}


const PictureInPictureVideoPlayer: React.FC = () => {
    const appState = useAppState()
    const userAccessState = useUserEventAccess()
    const [currentEventDate, setCurrentEventDate] = useState<EventDate | undefined>()
    const [pipPlayerSize, setPipPlayerSize] = useState<PipPlayerSize>({width: 426, height: 240});
    const [pipPlayerPosition, setPipPlayerPosition] = useState<PipPlayerPosition>({x: window.innerWidth - pipPlayerSize.width - 40, y: window.innerHeight - pipPlayerSize.height - 48});
    const channel = appState.liveStreamChannel
    const userControlsEnabled = true;
    const isVideoPage = useRouteMatch(videoRoomPageRoute)

    useEffect(() => {
        function loadData() {
            if (appState.liveStreamChannel) {
                loadChannelFirstEventDate(appState.liveStreamChannel?.id).then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                        //
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        appState.setLiveStreamChannel(Object.assign({}, appState.liveStreamChannel, { eventDate: resp.currentEventDate }))

                        const currentEventDateTemp = resp.currentEventDate
                        setCurrentEventDate(currentEventDateTemp)

                        if (resp.currentEventDate && !resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate?.enddatetime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    loadData()
                                }, remainingCurrentEventDate);
                            }
                        }
                        else if (resp.currentEventDate && resp.nextEventDate) {
                            const currentEventDateEndTime = resp.currentEventDate.enddatetime
                            const remainingCurrentEventDate = calculateEnd(currentEventDateEndTime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {  // Trigger on event end
                                    // reload data at halfway mark between end of current and beginning of next event
                                    const changeExecution = calculateChange(currentEventDateEndTime, resp.nextEventDate?.startdatetime!)
                                    changeExecutionTimer = window.setTimeout(() => { 
                                        loadData()
                                    }, changeExecution);
                                }, remainingCurrentEventDate);
                            }
                        }
                    }
                })
            }
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }
        // eslint-disable-next-line
    }, [appState.liveStreamChannel?.url])

    useEffect(() => {
        if (currentEventDate)
            userAccessState.checkIsEventUnlocked(currentEventDate)
    }, [currentEventDate]) // eslint-disable-line


    if (!channel || isVideoPage)
        return null
    else if (!userAccessState.isLoaded)
        return (
                // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
                <PictureInPicturePlayerRoot className={"picture-in-picture-player"} positionY={pipPlayerPosition.y + "px"} positionX={pipPlayerPosition.x + "px"} initialWidth={pipPlayerSize.width + "px"} initialHeight={pipPlayerSize.height + "px"} >
                    <CenteredLoader />
                </PictureInPicturePlayerRoot>
        )
    else
        return (
            <>
                {
                    // if event is not private or user has access,
                    // we can display real PictureInPicture Video Player
                    // otherwise, we will display dummy PictureInPicture Video Player
                    userAccessState.isEventUnlocked ?
                        <PictureInPictureVideoPlayerReal userControlsEnabled={userControlsEnabled} setPipPlayerSize={setPipPlayerSize} pipPlayerSize={pipPlayerSize} setPipPlayerPosition={setPipPlayerPosition} pipPlayerPosition={pipPlayerPosition} />
                        :
                        <PictureInPictureVideoPlayerDummy setPipPlayerSize={setPipPlayerSize} pipPlayerSize={pipPlayerSize} setPipPlayerPosition={setPipPlayerPosition} pipPlayerPosition={pipPlayerPosition}/>
                }
            </>
        )
}

interface PictureInPicturePlayerRootProps extends PictureInPictureDummyPlayerRootProps{
    positionY: string, 
    positionX: string
}

const PictureInPicturePlayerRoot = styled.div<PictureInPicturePlayerRootProps>`
    ${props=>`width: ${props.initialWidth}`};
    ${props=>`height: ${props.initialHeight}`};
    position: absolute;
    ${props=>`transform: translate(${props.positionX}, ${props.positionY})`};
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, .5);
    background-color: #000;
`

export default PictureInPictureVideoPlayer