import React from 'react'
import { useHistory } from 'react-router'
import styled from "styled-components"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { systemCheckPageRoute } from '../../navigationArea/RoutePaths'
import LanguageToggler from '../../ui/LanguageToggle'
import { device } from '../../utils/Device'
import { CenterLoginStyleTicketBtn } from './CenterLoginSharedComponents'



const SystemCheckContainer = styled.div`
    position: fixed;
    bottom: 70px;
    width: 100%;
    font-family: ${branding.font1};
    text-align: center;

    .btn:focus, .btn:active {
        outline: none !important;
        box-shadow: none !important;
    }

    .btn-dark {
        font-size: .9rem;
        border-radius: 2rem;
        background-color: ${branding.loginRegistrationSite.centerLoginSystemCheckBackgroundColor ?? "rgba(0,0,0,.5)"};
    }
`;

const LoginFooterRoot = styled.div`
    position: fixed;
    display: flex;
    flex-flow: row;
    color: #fff;
    height: 50px;
    width: 100%;
    margin-left: auto;
    right: 0;
    bottom: 0;
`

const LoginFooterLeftArea = styled.div`
    flex-grow: 1;
    padding-top: 10px;
`

const LoginFooterRightArea = styled.div`
    display: flex;
    align-self: flex-end;
    text-align: right;
    white-space: nowrap;
    align-items: center;
    height: 100%;
`

const LoginFooterDiv = styled.div`
    padding-right: 20px;

    & a, & a:link, & a:hover, & a:active {
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};
        font-size: 14px;

        @media ${device.tablet} {
            color: ${branding.loginRegistrationSite.footerTextColor};
            font-size: inherit;
        }
    }
`

function LoginFooter() {
    const strings = useLanguageState().getStrings()
    const history = useHistory()

    return <>
        <SystemCheckContainer>
            <CenterLoginStyleTicketBtn onClick={() => history.push(systemCheckPageRoute)}>
                {strings.ticketSale.checkYourSystemButton}
            </CenterLoginStyleTicketBtn>
        </SystemCheckContainer>
        <LoginFooterRoot>
            <LoginFooterLeftArea>
                <LanguageToggler color={branding.loginRegistrationSite.footerTextColor} />
            </LoginFooterLeftArea>
            <LoginFooterRightArea>
                {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter && <>
                    <LoginFooterDiv>
                        {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter && branding.loginRegistrationSite.showTermsOfUseInFooter && <a href={strings.loginRegistrationSite.termsOfUseLinkUrl} target="_blank" rel="noopener noreferrer">
                            {strings.loginRegistrationSite.termsOfUseLink}
                        </a>}
                    </LoginFooterDiv>
                    <LoginFooterDiv>
                        {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter && branding.loginRegistrationSite.showPrivacyPolicyInFooter && <a href={strings.loginRegistrationSite.privacyPolicyLinkUrl} target="_blank" rel="noopener noreferrer">
                            {strings.loginRegistrationSite.privacyPolicyLink}
                        </a>}
                    </LoginFooterDiv>
                    <LoginFooterDiv>
                        {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter && branding.loginRegistrationSite.showImprintInFooter && <a href={strings.loginRegistrationSite.imprintLinkUrl} target="_blank" rel="noopener noreferrer">
                            {strings.loginRegistrationSite.imprintLink}
                        </a>}
                    </LoginFooterDiv>
                </>}
            </LoginFooterRightArea>
        </LoginFooterRoot >
    </>
}

export default LoginFooter