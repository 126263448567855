import { createState, useState, State } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import moment from "moment";
import { EntityType } from '../backendServices/Types';

type StateValues = {
    [key in EntityType]: { [key: string]: { fav: boolean; lastChange: Date | string; }; };
} & {
    lastSyncTime: string;
};

export interface FavoriteContextMethods {
    is: (type: EntityType, id: string) => boolean
    add: (type: EntityType, id: string) => void
    remove: (type: EntityType, id: string) => void
    toggle: (type: EntityType, id: string) => void
    get: (type: EntityType, keyOnly?: boolean | undefined) => string
    getLastSyncTime: () => string
    setLastSyncTime: (lastSyncTime: Date) => void
    reset: () => void
}

function getPrefix(type: EntityType) {
    switch (type) {
        case "organization":
            return "id_orga_"
        case "product":
            return "id_prod_"
        case "person":
            return "id_pers_"
        case "eventdate":
            return "id_evtd_"
        case "trademark":
            return "id_trad_"
        case "news":
            return "id_news_"
        case "sotuser":
            return "id_sotu_"
        default:
            break;
    }
}

function replacer(key: string, value: string) {
    if (key === "lastChange")
        return undefined;
    return value;
}

const useWrapState = ((state: State<StateValues>) => {
    state.attach(Persistence('virtualGuide-favorites'))

    return ({
        is: (type: EntityType, id: string) => {
            return state.get()[type] && state.get()[type][id] && state.get()[type][id].fav
        },
        add: (type: EntityType, id: string) => {
            state.set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}

                prevState[type][id] = { fav: true, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        remove: (type: EntityType, id: string) => {
            state.set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}

                prevState[type][id] = { fav: false, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        toggle: (type: EntityType, id: string) => {
            state.set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}
                const currentFav = prevState[type][id] && prevState[type][id].fav

                prevState[type][id] = { fav: !currentFav, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        get: (type: EntityType, keyOnly?: boolean) => {
            let tempArray: Array<string> = [];
            let favorites = state.get()[type]
            if (favorites) {
                const jsonString = JSON.stringify(favorites, replacer);
                const jsonObject = JSON.parse(jsonString);
                var prefix = getPrefix(type);

                for (var key in jsonObject) {
                    if (jsonObject.hasOwnProperty(key)) {
                        if (state.get()[type][key].fav)
                            tempArray.push(keyOnly ? key : prefix + key);
                    }
                }

                return tempArray.join(",");
            }
            return "";
        },
        getLastSyncTime: () => {
            const lastSyncTime = state.get().lastSyncTime
            return lastSyncTime ? lastSyncTime : moment(new Date(+0)).format("YYYY-MM-DD HH:mm:ss")
        },
        setLastSyncTime: (lastSyncTime: Date) => {
            state.set(prevState => {
                prevState.lastSyncTime = moment(lastSyncTime).format("YYYY-MM-DD HH:mm:ss")
                return prevState
            })
        },
        reset: () => {
            state.set(prevState => {
                prevState = {} as StateValues
                return prevState
            })
        }
    })
})
const state = createState(({} as StateValues))
export const useFavoriteState = () => useWrapState(useState(state))