import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { DataPrivacyDoc } from '../../backendServices/BackendServices';
import branding from "../../branding/branding";
import { useLanguageState } from '../../globalStates/LanguageState';
import { device } from '../../utils/Device';



export const ErrorMessagesContainer = styled.div`
    margin-top: 2rem;
    font-size: inherit;
    order: 20;

    & .invalid-email-link{
        color: ${branding.loginRegistrationSite.invalidEmailPanelWithLink.textColor};  
    }
`


const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};
    
    & a{
        color: ${branding.loginRegistrationSite.loginInputMailTextColor};
        text-decoration: underline;
    }
`

const CheckboxInput = styled.input`
    min-width: 15px;
    min-height: 15px;
    background: #FFF;
    border: 1px solid #000;
`

const CheckboxLabel = styled.label`
    text-align: left;
    margin-bottom: 0;

    @media ${device.tablet} {
        margin-bottom: 0;
    }
`

const Checkbox = (props: any) => (
    <CheckboxInput type="checkbox" {...props} />
)

export function usePrivacyCheckbox(dataPrivacyDoc: DataPrivacyDoc): [boolean, () => JSX.Element] {
    const privacyCheckboxVisible = branding.loginRegistrationSite.privacyDocsCheckbox.visible
    const privacySecondCheckboxVisible = branding.loginRegistrationSite.privacyDocsSecondCheckbox.visible
    const [accepted, setAccepted] = useState(!privacyCheckboxVisible || false)
    const [acceptedSecond, setAcceptedsecond] = useState(!privacySecondCheckboxVisible || false)
    const strings = useLanguageState().getStrings()

    return [accepted && acceptedSecond,
    () => <>
        {privacyCheckboxVisible &&
            <CheckboxParent className="mt-3" style={{order: 10}}>
                <Checkbox checked={accepted} id="privacyCheckbox" onChange={() => {
                    setAccepted(!accepted)
                }} />
                <CheckboxLabel htmlFor="privacyCheckbox" className="ml-3">{strings.loginRegistrationSite.privacyDocsCheckbox.labelBeforeLink}<a href={dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.privacyDocsCheckbox.linkTitle}</a>{strings.loginRegistrationSite.privacyDocsCheckbox.labelAfterLink}</CheckboxLabel>
            </CheckboxParent >
        }

        {privacySecondCheckboxVisible &&
            <CheckboxParent className="mt-3" style={{order: 11}}>
                <Checkbox checked={acceptedSecond} id="privacySecondCheckbox" onChange={() => {
                    setAcceptedsecond(!acceptedSecond)
                }} />
                <CheckboxLabel htmlFor="privacySecondCheckbox" className="ml-3">{strings.loginRegistrationSite.privacyDocsSecondCheckbox.labelBeforeLink}<a href={dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.privacyDocsSecondCheckbox.linkTitle}</a>{strings.loginRegistrationSite.privacyDocsSecondCheckbox.labelAfterLink}</CheckboxLabel>
            </CheckboxParent >
        }

    </>
    ]
}



export const CenterLoginStyleTicketBtn = styled(Button)`
    font-size: 16px;
    height: 45px;
    min-width: 250px;
    width: auto;
    font-family: ${branding.font1};
    border-radius: 5px;
    border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
    background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnBgColor};
    color: ${branding.loginRegistrationSite.centerLoginStyleTicketTextColorBtn};
    padding: .5rem 2rem;
    
    &:hover {
        background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverBgColor};
        border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
        outline: none !important;
        color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverTextColor};
    }

    &:focus {
        outline: none;
    }

    @media ${device.tablet} {
        border-radius: 40px;
    }
`


export const CenterLoginStyleTicketButton = () => {
    const strings = useLanguageState().getStrings();
    return <CenterLoginStyleTicketBtn onClick={() => window.open(`${branding.ticketSale.ticketsUrl}`, "_blank")}>{strings.loginRegistrationSite.buyTicketButton}</CenterLoginStyleTicketBtn>
}

export const CenterLoginTextFieldContainer = styled.div`
    margin: 10px 0;
`

export const CenterLoginStyleHeader = styled.div`
    font-family: ${branding.font2};
    position: absolute; 
    top: 0; 
    left: 0;
    right: 0;
    color: ${branding.secondaryTextColor ?? "#fff"};
    display: flex; 
    justify-content: center;
    flex-direction: column; 
    align-items: center;
`

export const CenterLoginStyleHeaderTitle = styled.h1`
    font-size: 24px;
    white-space: pre;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#000"};
    font-weight: 700;

    @media ${device.tablet} {
        font-size: 2.3vw;
    }

    @media ${device.tablet} {
        font-weight: 500;
        font-size: ${branding.loginRegistrationSite.loginRegistrationTitleFontSize ?? "3vw"};
        color: ${branding.loginRegistrationSite.loginTitleTextColor ?? "#000"};
    }
`

export const CenterLoginStyleHeaderSubitle = styled.p`
    font-size: 10px;
    white-space: pre;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#fff"};    

    @media ${device.tablet} {
        color: ${branding.loginRegistrationSite.loginSubTitleTextColor ?? "#fff"};
        font-weight: ${branding.loginRegistrationSite.loginSubTitleFontWeight ?? "normal"};
    }
`

export const CenterLoginStyleErrorMessage = styled.div`
    color: ${branding.dangerButtonColor};
`

export const CenterLoginStyleInputContainer = styled.div<{ windowHeight?: number }>`
    width: 100%;
    min-height: 370px;
    padding: ${props => props.windowHeight && props.windowHeight < 1200 ? "2rem" : "2.5rem"}; 
    border-radius: 5px;
    position: absolute;
    top: ${props => props.windowHeight && props.windowHeight < 1200 ? "55%" : "50%"};
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#fff"};
    font-family: ${branding.font1};
    font-size: 14px;
    display: flex;
    flex-flow: column;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${branding.loginRegistrationSite.loginBoxOverlayColor ?? "rgba(0,0,0,.5)"};
        backdrop-filter: blur(3px);
        z-index: -1;
        border-radius: 5px;
    }
    &.loading::before {
        z-index: 2;
    }

    &:after {
        content:'';
        display: block;
        width: 100%;
        margin-top: 10px;
        border-top: 1px solid #000;
        height: 0;
        order: 100;

        @media ${device.tablet} {
            display: none;
        }
    }

    
    @media ${device.tablet} {
        width: 600px;
        border: 2px solid ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
        color: ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
        font-size: inherit;
    }
`

export const CenterLoginStyleForgotPassword = styled.div`
    text-align: right;
    font-size: .9rem;
    padding: 0;
    margin: -5px 0 10px;
    color: ${branding.loginRegistrationSite.changeEmailAddressLinkColor ?? "#fff"};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export const CenterLoginStyleSubmitButton = styled(Button)`
    display: block;
    outline: none;
    width: 100%;
    height: 45px;
    margin: 0;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    border: none;
    background-color: ${branding.loginRegistrationSite.loginButtonBackgroundColor}!important;
    color: ${branding.loginRegistrationSite.loginButtonTextColor}!important;
    order: 9;
   
    &.btn:disabled {
        background-color: ${branding.loginRegistrationSite.loginButtonDisabledBackgroundColor}!important;
        opacity: 1;
        border: none;
    }

    @media ${device.tablet} {
        width: 250px;
        margin: 2rem auto 0;
        border-radius: 40px;
        order: 40;
    }
`


export const CenterLoginStyleLoaderAttributes: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3
}



export const CenterLoginStyleContactUsLabel = styled.div`
   font-family: ${branding.font1};
   width: 100%;
   margin-bottom: 10px;
   text-align: center;
   color: ${branding.loginRegistrationSite.contactUsLabelColor ?? "#000"};
`