import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Row, Col } from 'react-bootstrap'
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { getInterest } from "../../backendServices/BackendServices"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { IconPhone, IconMobile, IconEmail, IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconInstagram, IconYouTube, IconPinterest, IconLanguages, IconLocation, IconFacebookOriginal, IconTwitterOriginal, IconLinkedInOriginal, IconXingOriginal, IconInstagramOriginal, IconYouTubeOriginal, IconPinterestOrginal } from "../../ui/Icons"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { MyProfilePageMode, EditProfileSection, MyProfileSubPageProps } from "./MyProfilePageContent"
import { getCategorySectionTitle } from "./EditMyProfileLayout"
import { Category } from "../../backendServices/Types"
import CompanyDetails from "../../ui/CompanyDetails"
import { getListOfLanguagesString } from "./LanguageSwitcherHelper"
import { CategoryType, SocialMediaType } from "./MyProfilePageContentBranding"
import { findCountryName } from "./CountrySwitcherHelper"
import { ProfileContactsWrapper, ProfileContactDetailsContainer, ProfileContactDetailsText, ProfileSocialsTitle, ProfilePageSectionTitle, ProfileSocialsWrapper, ProfileSocialItem, ProfileDetailRowHeader, PersonImageAndLogo, UserImageDiv, UserFullName, UserPositionCompany, ProfileDetailNameTeaserCol, BiographyText, CategoriesRoot, CategoriesTitleRow, CategoriesViewRow, CategoriesItem, UserBasicInfoContainer, DummyDiv } from "../../ui/ProfilePageStyledComponents"
import { EditProfile } from "./MobileMyProfilePageContent"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useHistory } from "react-router-dom"
import { editMyProfilePageRoute } from "../../navigationArea/RoutePaths"

const version = document.getElementById("version")?.getAttribute("data-value")

const corussoftLogo = "/corus_RGB_L_dark.png"

const BottomMenuCompanyLogo = styled.div<{ showShadow: boolean }>`
    background-color: #fff;
    position: relative;
    border-top: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    bottom: 70px;
    height: 60px;
    font-family: ${branding.font1};
    font-weight: 300;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: ${props => props.showShadow ? branding.primaryScrollDarkShadowBTT : 'initial'};
`



const ScrollContainerRoot = styled.div<{ guestBannerHeight: number }>`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 60px - 50px - ${props => props.guestBannerHeight + "px"}); /* topbar + guestBanner */
  overflow: hidden; 
  padding-bottom: 60px;
`

/*********************************************************************************************
 * site assembly
**********************************************************************************************/
export interface MyProfileSubPageSectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    setEditProfileSection: (section: EditProfileSection) => void
}

const ViewMyProfileLayout: React.FunctionComponent<MyProfileSubPageProps> = (props: MyProfileSubPageProps) => {
    const [contentAreaScrollable, setContentAreaScrollable] = useState(false)
    const [hideOnScrollEnd, setHideOnScrollEnd] = useState(false)

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScrollEnd(scrollValues.contentScrollHeight === scrollValues.clientHeight + scrollValues.scrollTop)
        } else {
            setHideOnScrollEnd(true)
        }
    }


    return (<div style={{ fontFamily: branding.font1, color: branding.mainInfoColor }}>
        <div style={{ backgroundColor: "white" }}>
            <ScrollContainerRoot guestBannerHeight={props.guestBannerHeight}>
                <ContentScrollContainer handleScroll={onScroll} isVerticallyScrollable={setContentAreaScrollable}>
                    <div style={{ position: "relative" }}>
                        <ProfileDetailBody setMyProfileMode={props.setMyProfileMode} setEditProfileSection={props.setEditProfileSection} />
                    </div>
                </ContentScrollContainer>
            </ScrollContainerRoot>
        </div>


        <div style={{ position: "absolute", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <BottomMenuCompanyLogo showShadow={contentAreaScrollable && !hideOnScrollEnd}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ width: "80px", marginBottom: "10px" }} src={corussoftLogo} alt="Corussoft GmbH" />
                    <div style={{ fontSize: "14px" }}>Version {version}</div>
                </div>
            </BottomMenuCompanyLogo>
        </div>

    </div>
    );
}

export default ViewMyProfileLayout

// #region Profile Contact Details
function ProfileContacts(width: string) {
    const user = useLoggedInState().user()
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()
    // const hiddenFields = branding.myProfilePageContent.contactInfosHiddenFields

    function checkIfNoSocials(): boolean {

        const brandingList = branding.myProfilePageContent.availableSocialMedias

        if (user?.facebook && user?.facebook?.length! > 0 && brandingList.includes(SocialMediaType.FACEBOOK)) {
            return true
        }

        if (user?.twitter && user?.twitter?.length! > 0 && brandingList.includes(SocialMediaType.TWITTER)) {
            return true
        }

        if (user?.instagram && user?.instagram?.length! > 0 && brandingList.includes(SocialMediaType.INSTAGRAM)) {
            return true
        }

        if (user?.linkedIn && user?.linkedIn?.length! > 0 && brandingList.includes(SocialMediaType.LINKEDIN)) {
            return true
        }

        if (user?.youTube && user?.youTube?.length! > 0 && brandingList.includes(SocialMediaType.YOUTUBE)) {
            return true
        }

        if (user?.xing && user?.xing?.length! > 0 && brandingList.includes(SocialMediaType.XING)) {
            return true
        }

        if (user?.pinterest && user?.pinterest?.length! > 0 && brandingList.includes(SocialMediaType.PINTEREST)) {
            return true
        }

        return false
    }

    return (
        <ProfileContactsWrapper style={{ width: width }}>
            <Row>
                <Col xs={24} md={12}>
                    {user?.phone && <ProfileContactDetailsContainer>
                        {IconPhone({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{user?.phone}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    {user?.mobile && <ProfileContactDetailsContainer>
                        {IconMobile}
                        <ProfileContactDetailsText>{user?.mobile}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    <ProfileContactDetailsContainer>
                        {IconEmail({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{user?.email}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>

                    {user?.countrycode &&
                        <ProfileContactDetailsContainer style={{ alignItems: "flex-start" }}>
                            {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{findCountryName(user?.countrycode, lang)}</ProfileContactDetailsText>
                            {/* {user?.city && hiddenFields.indexOf(Field.CITY) < 0 && <ProfileContactDetailsText>{user.city}</ProfileContactDetailsText>} */}
                        </ProfileContactDetailsContainer>
                    }

                    {user?.languages && user?.languages.length > 0 &&
                        <ProfileContactDetailsContainer>
                            {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{getListOfLanguagesString(user?.languages, lang)}</ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    }
                </Col>
            </Row>

            {checkIfNoSocials() &&
                <>
                    <ProfileSocialsTitle>
                        <ProfilePageSectionTitle>{strings.myProfilePageContent.socialMediaSectionTitle}</ProfilePageSectionTitle>
                    </ProfileSocialsTitle>
                    <ProfileSocialsWrapper>

                        {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                            if (item === SocialMediaType.FACEBOOK) {
                                return <>
                                    {user?.facebook && <ProfileSocialItem key={index} href={user?.facebook} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconFacebookOriginal({width: '20', height: '20'}) : IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.TWITTER) {
                                return <>
                                    {user?.twitter && <ProfileSocialItem key={index} href={user?.twitter} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconTwitterOriginal({width: '20', height: '20'}) : IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.LINKEDIN) {
                                return <>
                                    {user?.linkedIn && <ProfileSocialItem key={index} href={user?.linkedIn} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconLinkedInOriginal({width: '20', height: '20'}) : IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.XING) {
                                return <>
                                    {user?.xing && <ProfileSocialItem key={index} href={user?.xing} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconXingOriginal({width: '20', height: '20'}) : IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.INSTAGRAM) {
                                return <>
                                    {user?.instagram && <ProfileSocialItem key={index} href={user?.instagram} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconInstagramOriginal({width: '20', height: '20'}) : IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                            </>
                            }

                            if (item === SocialMediaType.YOUTUBE) {
                                return <>
                                    {user?.youTube && <ProfileSocialItem key={index} href={user?.youTube} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconYouTubeOriginal({width: '20', height: '20'}) : IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.PINTEREST) {
                                return <>
                                    {user?.pinterest && <ProfileSocialItem key={index} href={user?.pinterest} target="_blank">
                                    {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconPinterestOrginal({width: '20', height: '20'}) : IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            return null
                        })
                        }


                    </ProfileSocialsWrapper>
                </>
            }
        </ProfileContactsWrapper >
    )
}
// #endregion

// #region Profile Details
export interface CategoryData {
    interests: Category[]
    offerings: Category[]
    lookingfor: Category[]

    selectedInterests: Category[]
    selectedOfferings: Category[]
    selectedLookingfor: Category[]
}

const EditProfileButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: 265px;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const ProfileDetailBody: React.FunctionComponent<MyProfileSubPageSectionProps> = (props: MyProfileSubPageSectionProps) => {
    const userState = useLoggedInState()

    const loggedInUser = userState.user()

    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const currentUserInfo: string = (lang === "de" ? userState.user()?.infotextDe : userState.user()?.infotext) || ""
    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const localizedPosition = lang === "de" ? (userState.user()?.positionDe ?? userState.user()?.position) : userState.user()?.position
    const currentUserPosition: string = [localizedPosition, userState.user()?.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const currentUserIndustry: string = (lang === "de" ? userState.user()?.industryDe : userState.user()?.industry) || ""

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    const history = useHistory()

    function getData() {
        getInterest().then(res => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter(i => (userState.user()?.interests ?? []).includes(i.id))
                const selectedOfferings = offerings.filter(i => (userState.user()?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter(i => (userState.user()?.lookingfor ?? []).includes(i.id))
                setCategoryData(
                    {
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selectedOfferings,
                        selectedLookingfor: selectedLookingfor
                    }
                )
            }
        })
    }

    const windowSize = useWindowDimensions()

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ProfileDetailRowHeader>
            {windowSize.width >= 1502 ?
                <PersonImageAndLogo xs={3} >
                    <UserBasicInfoContainer>
                        <Row style={{ justifyContent: "center" }}>
                            <UserImageDiv>
                                <AvatarWithDefault size={100} src={loggedInUser?.logoUrl} alt={[loggedInUser?.firstName, loggedInUser?.lastName].join(" ") ?? "#"} />
                            </UserImageDiv>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserFullName>{currentUserName}</UserFullName>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserPositionCompany>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</UserPositionCompany>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <DummyDiv />
                        </Row>
                    </UserBasicInfoContainer>

                    <Row style={{ justifyContent: "center" }}>
                        {ProfileContacts("auto")}
                    </Row>

                    <Row style={{ justifyContent: "center" }}>
                        <EditProfileButton onClick={() => { props.setMyProfileMode(MyProfilePageMode.EDIT); history.push(editMyProfilePageRoute); }}>
                            {strings.businessAreaPageContent.editProfileNoticeContinueToProfile}
                        </EditProfileButton>
                    </Row>
                </PersonImageAndLogo> :
                <PersonImageAndLogo md={12} style={{ borderRight: "none", marginLeft: "-70px" }}>
                    <UserBasicInfoContainer style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <UserImageDiv>
                                <AvatarWithDefault size={100} src={loggedInUser?.logoUrl} alt={[loggedInUser?.firstName, loggedInUser?.lastName].join(" ") ?? "#"} />
                            </UserImageDiv>
                        </Row>

                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <UserFullName style={{ width: "673px" }}>{currentUserName}</UserFullName>
                        </Row>

                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <UserPositionCompany style={{ width: "673px" }}>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</UserPositionCompany>
                        </Row>

                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <EditProfileButton onClick={() => { props.setMyProfileMode(MyProfilePageMode.EDIT); history.push(editMyProfilePageRoute); }}>
                                {strings.businessAreaPageContent.editProfileNoticeContinueToProfile}
                            </EditProfileButton>
                        </Row>

                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <DummyDiv style={{ width: "673px" }} />
                        </Row>
                    </UserBasicInfoContainer>

                    <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                        {ProfileContacts("673px")}
                    </Row>
                </PersonImageAndLogo>
                //will be refactored, this is a quick fix
            }

            <ProfileDetailNameTeaserCol xs={20} style={{ marginLeft: windowSize.width >= 1502 ? "60px" : "0px" }}>
                {false &&
                    <EditProfile setMyProfileMode={props.setMyProfileMode} section={EditProfileSection.SOCIALS} setSection={props.setEditProfileSection} guestBannerHeight={0} />
                }
                {currentUserInfo && <>
                    <ProfilePageSectionTitle>
                        {strings.myProfilePageContent.biographyMobileLabel}
                    </ProfilePageSectionTitle>
                    <CategoriesViewRow>
                        <div style={{ margin: ".3rem" }}>
                            <BiographyText>{currentUserInfo}</BiographyText>
                        </div>
                    </CategoriesViewRow>
                </>
                }

                <>
                    {branding.myProfilePageContent.categoriesOrder.map((item, index) => <ContactCategoryBindings key={index} type={item} data={categoryData} />)}
                </>



                {loggedInUser && loggedInUser.organizations && loggedInUser.organizations[0] &&
                    <CompanyDetails organization={loggedInUser.organizations[0]} />}
            </ProfileDetailNameTeaserCol>
        </ProfileDetailRowHeader >
    )
}
// #endregion

// #region Interests
export function getNoSelectedCategoriesMessage(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.noInterestsSelectedMessage

        case CategoryType.OFFERS:
            return strings.noOffersSelectedMessage

        case CategoryType.NEEDS:
            return strings.noNeedsSelectedMessage

        default:
            return strings.noInterestsSelectedMessage
    }
}

function getCategoriesLength(data: CategoryData, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return data.interests.length || 0

        case CategoryType.OFFERS:
            return data.offerings.length || 0

        case CategoryType.NEEDS:
            return data.lookingfor.length || 0

        default:
            return 0
    }
}

interface ContactCategoryBindingsProps {
    data: CategoryData
    type: CategoryType
}
export const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = (props) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent

    let content: JSX.Element = <div />



    if (getCategoriesLength(props.data, props.type) > 0) {
        let selectedDataSet: Category[] = [];
        switch (props.type) {
            case CategoryType.INTERESTS:
                selectedDataSet = props.data.selectedInterests
                break
            case CategoryType.OFFERS:
                selectedDataSet = props.data.selectedOfferings
                break
            case CategoryType.NEEDS:
                selectedDataSet = props.data.selectedLookingfor
                break
        }

        if (selectedDataSet.length > 0) {
            content = <>
                {selectedDataSet.map(category => {
                    return <CategoriesItem>{category.name}</CategoriesItem>
                })}
            </>
        }
        else {
            content =
                <div style={{ margin: ".3rem" }}>
                    <BiographyText>{getNoSelectedCategoriesMessage(strings, props.type)}</BiographyText>
                </div>
        }
    }

    return <CategoriesRoot>
        <CategoriesTitleRow>
            <ProfilePageSectionTitle>{getCategorySectionTitle(strings, props.type)}</ProfilePageSectionTitle>
        </CategoriesTitleRow>
        <CategoriesViewRow>
            {content}
        </CategoriesViewRow>
    </CategoriesRoot>
}

// #endregion

// #region Action Toolbar

