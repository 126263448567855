import { EntityType, Entity } from './../backendServices/Types';
import { backOff } from 'exponential-backoff';
import { loadExhibitorsData, ExhibitorListRequestParameter } from '../backendServices/BackendServices';
import { defaultLogger as logger } from "../globalStates/AppState";

export const ALL_ENTITY_TYPES: EntityType[] = ["product", "trademark", "eventdate", "news", "networking_user", "organization", "coupon"]

const pageSize = 27

/* #region Interfaces */
export enum SectionType {
    TOP = "top", ALL = "all"
}
export const sectionOrder = [SectionType.TOP, SectionType.ALL]

export interface Section {
    type: SectionType
    count: number
    entities: Entity[]
    hasMoreData: boolean
}

export type Sections = {
    [key in SectionType]?: Section
}

export interface SearchParameters {
    page: number
    order: string
    alpha: string | null
    showOnlyBookmarks: boolean
    searchValue?: string
    searchTitle?: string
    emptyCheckBoxes?: boolean
    entityTypes: EntityType[]
    favorites: string
    categoryFilter?: string
    firstFilterCategory?: string
    secondFilterCategory?: string
    basispremium?: number
}
/* #endregion */


/* #region  Helper methods */
const getEntityFilterName = (entityType: EntityType | "all") => {
    switch (entityType) {
        case "product":
            return "entity_prod"
        case "trademark":
            return "entity_trad"
        case "news":
            return "entity_news"
        case "eventdate":
            return "entity_evtd"
        case "networking_user":
            return "entity_sotu"
        case "coupon":
            return "entity_coup"
        default:
            return "entity_orga"
    }
}

export function getRequestParams(sectionType: SectionType, searchParams: SearchParameters): ExhibitorListRequestParameter {
    const filterList = []
    let order = "lexic"
    if (sectionType === SectionType.TOP) {
        filterList.push("featured_")
        order = "totl" //order entities by totl value and then alphabetically
    } else {
        order = "lexic"
    }

    let entityFilter = ""
    if (searchParams.showOnlyBookmarks && searchParams.favorites)
        entityFilter = searchParams.favorites
    else {
        const entityTypes = searchParams.entityTypes.length === 0 ? ALL_ENTITY_TYPES : searchParams.entityTypes
        entityFilter = entityTypes.map(x => getEntityFilterName(x)).join(", ")
    }

    filterList.push(entityFilter)

    if (searchParams.categoryFilter) {
        filterList.push(searchParams.categoryFilter)
    }

    if (searchParams.searchValue) {
        filterList.push(searchParams.searchValue)
    }

    if (searchParams.firstFilterCategory) {
        filterList.push("cat_" + searchParams.firstFilterCategory)
    }

    if (searchParams.secondFilterCategory) {
        filterList.push("cat_" + searchParams.secondFilterCategory)
    }

    const requestParams: ExhibitorListRequestParameter = {
        numresultrows: pageSize,
        startresultrow: searchParams.page * pageSize,
        filterlist: filterList,
        order: order,
    }
    if (searchParams.alpha)
        requestParams.alpha = searchParams.alpha
    if (searchParams.basispremium)
        requestParams.basispremium = searchParams.basispremium    
    return requestParams
}

export const fetchDataHelper = async (searchParams: SearchParameters, sections: Sections) => {
    // Only work with existing results if we are on a next page
    const existingSections = searchParams.page > 0 ? sections : {}

    // List of data loaders for the different sections
    const loadSection = []
    for (let sectionType of sectionOrder) {
        // We don't want totl results for categories or coupons
        if (sectionType === SectionType.TOP && (searchParams.entityTypes.includes("category") && searchParams.entityTypes.includes("coupon") && searchParams.entityTypes.length === 2))
            continue

        // only load data if we either do not have results, or we have more incoming
        if (!existingSections[sectionType] || existingSections[sectionType]?.hasMoreData) {
            const requestParams = getRequestParams(sectionType, searchParams)
            loadSection.push(loadExhibitors(sectionType, requestParams))
        }
    }

    // If there is no section to load we can end here
    if (loadSection.length === 0)
        return {}

    // wait for all requests
    const loadedSections = await Promise.all(loadSection)

    // handle request results
    const newSections: Sections = {}
    for (let sectionType of sectionOrder) {
        let matchedSection: Section | null = null
        for (let loadedSection of loadedSections) {
            if (loadedSection.type === sectionType) {
                matchedSection = loadedSection
                break
            }
        }
        // No new data loaded. Use the existing one
        if (!matchedSection) {
            continue
        }

        // entirly new results.
        newSections[sectionType] = { type: sectionType, count: matchedSection.count, entities: matchedSection.entities, hasMoreData: matchedSection.hasMoreData }
    }

    return newSections
}

// actual data loading 
async function loadExhibitors(sectionType: SectionType, requestParams: ExhibitorListRequestParameter): Promise<Section> {
    const resp = await backOff(() => loadExhibitorsData(requestParams), {
        retry: (error: any, attemptNumber: number) => {
            logger.error({ message: "ExhibitorsPageContent loadExhibitors attempt " + attemptNumber + " failed.", errorMessage: error.message, errorStack: error.stack })
            return true
        }
    })

    return { type: sectionType, count: resp.count, entities: resp.entities, hasMoreData: resp.count > (requestParams.startresultrow + pageSize) }
}
/* #endregion */