import styled, { css } from "styled-components"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Row, Col } from 'react-bootstrap'
import CenteredLoader from "../../ui/CenteredLoader"
import { Contact, EventDate, Category, EntityType, ModalType } from "../../backendServices/Types"
import { loadPersonData, getInterest, loadUserData, BackendServiceError, SpeakerResponse, loadEventDateList } from "../../backendServices/BackendServices"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import TopBar from '../../navigationArea/TopBar'
import Breadcrumb from '../../navigationArea/Breadcrumb'
import { IconShare, IconPhone, IconMobile, IconEmail, IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconLiveStream, IconInstagram, IconYouTube, IconPinterest, IconLocation, IconLanguages, IconFacebookOriginal, IconTwitterOriginal, IconLinkedInOriginal, IconXingOriginal, IconInstagramOriginal, IconYouTubeOriginal, IconPinterestOrginal } from "../../ui/Icons"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"

import { DetailNavLink, DetailNavLinkType, buildDetailLink } from "./DetailNavLink"
import { useFavoriteState } from "../../globalStates/Favorites"

import ContextMenu from "../../ui/ContextMenu"
import { useMeetingContext } from "../../conference/context/MeetingContext"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { useAppState } from "../../globalStates/AppState"
import { CommunicationModals, createActions } from "../../communicationArea/CommunicationOptions"
import BackendError from "../../ui/BackendError"
import { useContactState } from "../../communicationArea/ContactState"
import { mergeWith, isEmpty } from "lodash"
import { ShareTargetType } from "../../backendServices/Types"
import RecommendOrganizationModal from "../../ui/RecommendOrganizationModal"
import MyProfilePageContent from "../myprofile/MyProfilePageContent"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { useCategoriesState } from "../../globalStates/CategoriesState"
import { PointsBadge } from "../program/EventDateEntry"
import moment from "moment"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { showfloorPageRoute, programPageRoute, speakersPageRoute, networkingPageRoute, exhibitorsPageRoute } from "../../navigationArea/RoutePaths"
import { findCountryName } from "../myprofile/CountrySwitcherHelper"
import { getListOfLanguagesString } from "../myprofile/LanguageSwitcherHelper"
import { format } from "date-fns"

import CompanyDetails, { CompanyLogoDiv, CompanyLogo, CompanyLogoDivDuplicate } from "../../ui/CompanyDetails"
import { CategoryType, SocialMediaType } from "../myprofile/MyProfilePageContentBranding"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { checkIfMobile } from "../.."
import MobilePersonDetailPageContent from "./MobilePersonDetailPageContent"
import BookmarkSpeakersEventsModal from "../../ui/BookmarkSpeakersEventsModal"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import { ProfileContactsWrapper, ProfileDetailRowHeader, PersonImageAndLogo, UserImageDiv, UserFullName, UserPositionCompany, ProfileDetailNameTeaserCol, ProfilePageSectionTitle, CategoriesViewRow, BiographyText, ProfileContactDetailsContainer, ProfileContactDetailsText, ProfileSocialsTitle, ProfileSocialsWrapper, ProfileSocialItem, CategoriesTitleRow, CategoriesItem, UserBasicInfoContainer, DummyDiv } from "../../ui/ProfilePageStyledComponents"

const germanLocale = require('date-fns/locale/de');

export enum ContactType {
    SPEAKER,
    EXHIBITOR,
    VISITOR
}


const PersonDetailPageContent: React.FunctionComponent = (props) => {
    const userLink = useLoggedInState();
    const loggedInUserId = userLink.user()?.profileId || "";
    const [contactType, setContactType] = useState<ContactType>(ContactType.VISITOR)

    const [contact, setContact] = useState<Contact>()

    const { personId, userId }: any = useParams()
    const [sotUserId, setSotUserId] = useState<string>("")

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const langState = useLanguageState()
    const strings = langState.getStrings()
    const lang = langState.getLanguage()

    const locations = calcBreadcrumbLocations(strings)

    const [breadcrumb, setBreadcrumb] = useState([{ to: "/", name: "" }])

    const favoriteState = useFavoriteState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()

    const [modalType, setModalType] = useState<ModalType>('none');
    const appState = useAppState()
    const contactState = useContactState()
    const [isUser, setIsUser] = useState(false)
    const connectionStatus = contact ? contactState.getConnectionStatus(contact.id) : 'UNRELATED'
    const userTypeStatus = contact ? contactState.getUserType(contact.id) : ''

    const windowSize = useWindowDimensions()

    const isBookmarked = favoriteState.is("person", contact?.person || "") || favoriteState.is("person", sotUserId)

    const [isMyProfile, setIsMyProfile] = useState<boolean>(false)


    const [showBookmarkEventsModal, setShowBookmarkEventsModal] = useState<boolean>(false)

    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState();
    const { topBarRef, setTopBarRef } = TopBannerSharedState();
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        (isUser ? 150 : 60)

    const menuCallback: (param: { isBookmark?: boolean, modalType?: ModalType, connectionStatus?: string }) => void = ({ isBookmark, modalType, connectionStatus }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    function mergePersonData(primaryData: SpeakerResponse, secondaryData?: SpeakerResponse) {
        var primaryContact = primaryData.content
        if (secondaryData) {
            const secondaryContact = secondaryData.content
            primaryContact = mergeWith({}, secondaryContact, primaryContact, (obj, src) => src == null || isEmpty(src) ? obj : src)

            if (!(primaryData.content.phone)) {
                primaryContact.phone = ""
                //if there is a phone number in person table, but not in seriesoftopicsuser 
                //table, no number is shown
            }
        }
        setContact(primaryContact)
        contactState.setAll([{ id: primaryContact.id, connectionStatus: primaryContact.myConnectionStatus, userType: primaryContact.type }])
        if (primaryContact.eventDates && primaryContact.eventDates.length > 0) {
            setContactType(ContactType.SPEAKER);
        } else if (primaryContact.organizations && primaryContact.organizations.length > 0) {
            setContactType(ContactType.EXHIBITOR);
        } else {
            setContactType(ContactType.VISITOR);
        }
    }

    async function loadUser(userId: string) {
        if (!loggedInUserId) {
            setError("User is not logged in")
            return
        }

        try {
            setIsUser(true)
            const response = await loadUserData({ targetProfileId: userId, loggedInUserId: loggedInUserId })
            if ((response as BackendServiceError).httpStatus) {
                setError((response as BackendServiceError).httpStatusText)
                return
            }

            const userDataResponse = (response as SpeakerResponse)
            const connectedPersonId = userDataResponse.content.person

            if (connectedPersonId) {
                const personData: SpeakerResponse = await loadPersonData({ id: connectedPersonId })
                mergePersonData(userDataResponse, personData)
            } else {
                mergePersonData(userDataResponse)
            }

            setSotUserId(userId)
        } catch (error) {
            setError(error)
        }

        setIsLoaded(true)
    }

    async function loadPerson(personId: string) {
        if (!loggedInUserId) {
            setError("User is not logged in")
            return
        }

        try {
            const personData: SpeakerResponse = await loadPersonData({ id: personId })
            const contactDataPerson = personData.content
            const connectedSotUserId = contactDataPerson.sotUserId

            if (connectedSotUserId) {
                const response = await loadUserData({ targetProfileId: connectedSotUserId, loggedInUserId: loggedInUserId })
                if ((response as BackendServiceError).httpStatus) {
                    setError((response as BackendServiceError).httpStatusText)
                    return
                }

                setIsUser(true)
                const userDataResponse = (response as SpeakerResponse)
                mergePersonData(userDataResponse, personData)
            } else {
                setIsUser(false)
                mergePersonData(personData)
            }
            setSotUserId(connectedSotUserId ? connectedSotUserId : personId)
        } catch (error) {
            setError(error)
        }

        setIsLoaded(true)
    }

    //loading person details
    useEffect(() => {
        if (userId) {
            loadUser(userId)
        } else {
            loadPerson(personId)
        }

        if (userId === loggedInUserId || personId === loggedInUserId) {
            setIsMyProfile(true)
        }
        else {
            setIsMyProfile(false)
        }

    }, [personId, userId, loggedInUserId, lang]) // eslint-disable-line react-hooks/exhaustive-deps

    const organizationId = contact?.organizations && contact?.organizations[0] ? contact?.organizations[0]?.id : ""

    const organizationName = contact?.organizations && contact?.organizations[0] ? contact?.organizations[0]?.name : ""

    useEffect(() => {
        if (contact) {
            const personName = [contact?.title, contact?.firstName, contact?.lastName].join(" ")
            switch (contactType) {
                case ContactType.SPEAKER:
                    setBreadcrumb([{ to: programPageRoute, name: strings.sideIconBar.programMenuText }, { to: speakersPageRoute, name: strings.programPageContent.speakersTabTitle }, { to: locations[1], name: personName }]);
                    appState.setCurrentItem("program")
                    break;
                case ContactType.EXHIBITOR:
                    setBreadcrumb([{ to: showfloorPageRoute, name: strings.sideIconBar.showfloorMenuText }, { to: exhibitorsPageRoute, name: strings.navigationArea.companiesItemTitle }, { to: buildDetailLink(organizationId, organizationName, "organization"), name: organizationName }, { to: locations[1], name: personName }]);
                    appState.setCurrentItem("organization")
                    break;
                default:
                    setBreadcrumb([{ to: networkingPageRoute, name: strings.sideIconBar.networkingMenuText }, { to: locations[1], name: personName }]);
                    appState.setCurrentItem("network")
                    break;
            }
        }
        // eslint-disable-next-line
    }, [contactType, contact])

    useEffect(() => {
        setIsLoaded(true)
        //eslint-disable-next-line
    }, [sotUserId, loggedInUserId])


    let content: JSX.Element = <div />

    if (checkIfMobile(windowSize)) {
        if (contact) {
            return <MobilePersonDetailPageContent contact={contact} />
        }
        else if (error) {
            return <div style={{ marginTop: "25%" }}>
                <BackendError />
            </div>
        }
    }

    if (error) {
        content = <div style={{ marginTop: "25%" }}>
            <BackendError />
        </div>
    } else if (contact) {
        if (!isLoaded) {
            content = <CenteredLoader />;
        }
        else {

            content = <>
                <Breadcrumb breadcrumb={breadcrumb} />
                {isUser && <div style={{ position: "relative", width: "calc(100% - 40px)", margin: "0 20px", borderBottom: branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9' }}>
                    <ContextMenuRoot>
                        {contact?.eventDates?.length! > 0 ?
                            <ContextMenu className="big" itemsPerRow={10} collapsed={false} items={() => createActions(
                                userLink.user(),
                                contact,
                                favoriteState,
                                contactState,
                                appState,
                                meeting,
                                chime,
                                strings,
                                isBookmarked,
                                connectionStatus,
                                userTypeStatus,
                                menuCallback,
                                undefined,
                                !isUser,
                                !isUser,
                                () => setShowBookmarkEventsModal(true))} /> :
                            <ContextMenu className="big" itemsPerRow={10} collapsed={false} items={() => createActions(
                                userLink.user(),
                                contact,
                                favoriteState,
                                contactState,
                                appState,
                                meeting,
                                chime,
                                strings,
                                isBookmarked,
                                connectionStatus,
                                userTypeStatus,
                                menuCallback,
                                undefined,
                                !isUser)} />
                        }

                    </ContextMenuRoot>
                </div>}
                <ContentScrollContainer adjustForHeaderWith={adjustHeight + "px"}>
                    <div style={{ position: "relative" }}>
                        <ProfileDetailBody person={contact} isUser={isUser} isSpeaker={contactType === ContactType.SPEAKER} connectionStatus={connectionStatus} setShowBookmarkEventsModal={setShowBookmarkEventsModal} />
                    </div>
                </ContentScrollContainer>

                {showBookmarkEventsModal && <BookmarkSpeakersEventsModal speakerId={contact ? contact.id : ""} onClose={() => setShowBookmarkEventsModal(false)} />}
            </>;
        }
    } else {
        content = <CenteredLoader />
    }

    return isMyProfile ? <MyProfilePageContent />
        : <div style={{ fontFamily: branding.font1 }}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <CommunicationModals show={modalType} contact={contact} onHide={() => setModalType('none')} />
            {content}
        </div>
}

export default PersonDetailPageContent


function ProfileContacts(props: PersonDetailBodyProps) {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()


    function checkIfNoSocials(): boolean {
        const brandingList = branding.myProfilePageContent.availableSocialMedias

        if (props.person.facebook && props.person.facebook.length > 0 && brandingList.includes(SocialMediaType.FACEBOOK)) {
            return true
        }

        if (props.person.twitter && props.person.twitter.length > 0 && brandingList.includes(SocialMediaType.TWITTER)) {
            return true
        }

        if (props.person.instagram && props.person.instagram.length > 0 && brandingList.includes(SocialMediaType.INSTAGRAM)) {
            return true
        }

        if (props.person.linkedIn && props.person.linkedIn.length > 0 && brandingList.includes(SocialMediaType.LINKEDIN)) {
            return true
        }

        if (props.person.youTube && props.person.youTube.length > 0 && brandingList.includes(SocialMediaType.YOUTUBE)) {
            return true
        }

        if (props.person.xing && props.person.xing.length > 0 && brandingList.includes(SocialMediaType.XING)) {
            return true
        }

        if (props.person.pinterest && props.person.pinterest.length > 0 && brandingList.includes(SocialMediaType.PINTEREST)) {
            return true
        }

        return false
    }

    return (
        <ProfileContactsWrapper style={{ width: props.width ?? "auto" }}>
            <Row>
                <Col xs={24} md={12}>

                    {props.person.phone && <ProfileContactDetailsContainer>
                        {IconPhone({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{props.person.phone}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    {props.person.mobile && <ProfileContactDetailsContainer>
                        {IconMobile}
                        <ProfileContactDetailsText>{props.person.mobile}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    {props.person.email && <ProfileContactDetailsContainer>
                        {IconEmail({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{props.person.email}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>
                    }

                    {props.person.countrycode &&
                        <ProfileContactDetailsContainer style={{ alignItems: "flex-start" }}>
                            {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{findCountryName(props.person.countrycode, lang)}</ProfileContactDetailsText>
                            {/* {props.person.city && hiddenFields.indexOf(Field.CITY) < 0 && <ProfileContactDetailsText>{user.city}</ProfileContactDetailsText>} */}
                        </ProfileContactDetailsContainer>
                    }

                    {props.person.languages && props.person.languages.length > 0 &&
                        <ProfileContactDetailsContainer>
                            {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{getListOfLanguagesString(props.person.languages, lang)}</ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    }


                </Col>
            </Row>

            {
                checkIfNoSocials() && <>
                    <ProfileSocialsTitle>
                        <ProfilePageSectionTitle>{strings.myProfilePageContent.socialMediaSectionTitle}</ProfilePageSectionTitle>
                    </ProfileSocialsTitle>
                    <ProfileSocialsWrapper>

                        {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                            if (item === SocialMediaType.FACEBOOK) {
                                return <>
                                    {props.person.facebook && <ProfileSocialItem key={index} href={props.person.facebook} target="_blank">
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconFacebookOriginal({ width: '20', height: '20' }) : IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.TWITTER) {
                                return <>
                                    {props.person.twitter && <ProfileSocialItem key={index} href={props.person.twitter} target="_blank">
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconTwitterOriginal({ width: '20', height: '20' }) : IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.LINKEDIN) {
                                return <>
                                    {props.person.linkedIn && <ProfileSocialItem key={index} href={props.person.linkedIn} target="_blank">
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconLinkedInOriginal({ width: '20', height: '20' }) : IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.XING) {
                                return <>
                                    {props.person.xing && <ProfileSocialItem key={index} href={props.person.xing}>
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconXingOriginal({ width: '20', height: '20' }) : IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.INSTAGRAM) {
                                return <>
                                    {props.person.instagram && <ProfileSocialItem key={index} href={props.person.instagram}>
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconInstagramOriginal({ width: '20', height: '20' }) : IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.YOUTUBE) {
                                return <>
                                    {props.person.youTube && <ProfileSocialItem key={index} href={props.person.youTube}>
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconYouTubeOriginal({ width: '20', height: '20' }) : IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            if (item === SocialMediaType.PINTEREST) {
                                return <>
                                    {props.person.pinterest && <ProfileSocialItem key={index} href={props.person.pinterest}>
                                        {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconPinterestOrginal({ width: '20', height: '20' }) : IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                    </ProfileSocialItem>}
                                </>
                            }

                            return null
                        })
                        }
                    </ProfileSocialsWrapper>
                </>
            }
        </ProfileContactsWrapper >
    )
}

// #region GLobal layout

const ContextMenuRoot = styled.div`
    max-width: 1300px;
    margin: auto;
    white-space: nowrap;
    font-size: 14px;

    @media (max-width: 1200px) {
        font-size: 12px;
    }

    @media (max-width: 1000px) {
        font-size: 10px    
    }
`


const EventDateTitle = styled.p`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePosition ?? "#000"};
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
`

const EventDateTimeLocation = styled.p`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePosition ?? "#000"};
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
`

const EventDateDescription = styled.p`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePosition ?? "#000"};
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 17px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 14px;        /* fallback */
    max-height: 28px;       /* fallback */
`
//#endregion

// #region Person Details



interface PersonDetailBodyProps {
    person: Contact
    isUser?: boolean
    isSpeaker?: boolean
    connectionStatus?: string
    width?: string
    setShowBookmarkEventsModal: (value: boolean) => void
}


const ProfileDetailBody: React.FunctionComponent<PersonDetailBodyProps> = (props: PersonDetailBodyProps) => {
    const strings = useLanguageState().getStrings()

    const currentUserInfo = (props.person.infotext && props.connectionStatus !== 'CONNECTED') ? props.person.infotext : props.person.description?.text ? props.person.description.text : null

    const currentUserName: string = [props.person?.title, props.person?.firstName, props.person?.lastName].filter(Boolean).join(" ")
    const currentUserPosition: string = [props.person.position, props.person.company || props.person.organization].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const currentUserIndustry: string = props.person.industry || ""

    const windowSize = useWindowDimensions()

    return (
        <ProfileDetailRowHeader>
            {windowSize.width >= 1502 ?
                <PersonImageAndLogo xs={3} >
                    <UserBasicInfoContainer>
                        <Row style={{ justifyContent: "center" }}>
                            <UserImageDiv>
                                <AvatarWithDefault size={100} src={props.person.logoUrl} alt={[props.person.firstName, props.person.lastName].join(" ") ?? "#"} backgroundSize="cover" />
                            </UserImageDiv>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserFullName>{currentUserName}</UserFullName>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserPositionCompany>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</UserPositionCompany>
                        </Row>
                        {props.isSpeaker && <Row style={{ justifyContent: "center" }}>

                            <ShareBookmarkButtons type="person" id={props.person.person ?? props.person.id} name={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ")} isUser={props.isUser} setShowBookmarkEventsModal={props.setShowBookmarkEventsModal} />

                        </Row>}

                        <Row style={{ justifyContent: "center" }}>
                            <DummyDiv />
                        </Row>
                    </UserBasicInfoContainer>

                    <Row style={{ justifyContent: "center" }}>
                        <ProfileContacts person={props.person} setShowBookmarkEventsModal={props.setShowBookmarkEventsModal} />
                    </Row>
                </PersonImageAndLogo> :
                <PersonImageAndLogo md={12} style={{ borderRight: "none", marginLeft: "-70px" }}>
                    <UserBasicInfoContainer style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <Row style={{ justifyContent: "center" }}>
                            <UserImageDiv>
                                <AvatarWithDefault size={100} src={props.person.logoUrl} alt={[props.person.firstName, props.person.lastName].join(" ") ?? "#"} backgroundSize="cover" />
                            </UserImageDiv>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserFullName style={{ width: "673px" }}>{currentUserName}</UserFullName>
                        </Row>

                        <Row style={{ justifyContent: "center" }}>
                            <UserPositionCompany style={{ width: "673px" }}>
                                {[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}
                            </UserPositionCompany>
                        </Row>
                        {props.isSpeaker && <Row style={{ justifyContent: "center" }}>

                            <ShareBookmarkButtons type="person" id={props.person.person ?? props.person.id} name={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ")} isUser={props.isUser} setShowBookmarkEventsModal={props.setShowBookmarkEventsModal} />

                        </Row>}

                        <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                            <DummyDiv style={{ width: "673px" }} />
                        </Row>
                    </UserBasicInfoContainer>

                    <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                        <ProfileContacts person={props.person} setShowBookmarkEventsModal={props.setShowBookmarkEventsModal} width="673px" />
                    </Row>
                </PersonImageAndLogo>
            }

            <ProfileDetailNameTeaserCol xs={20} style={{ marginLeft: windowSize.width >= 1502 ? "60px" : "0px" }}>
                {currentUserInfo && <>
                    <ProfilePageSectionTitle>
                        {strings.myProfilePageContent.biographyMobileLabel}
                    </ProfilePageSectionTitle>
                    <CategoriesViewRow>
                        <div style={{ margin: ".3rem" }}>
                            <BiographyText>{currentUserInfo}</BiographyText>
                        </div>
                    </CategoriesViewRow>
                </>
                }

                {false && <LiveEventDate eventDates={props.person?.eventDates?.length > 0 ? props.person.eventDates : []}></LiveEventDate>}
                <EventDates eventDates={props.person?.eventDates?.length > 0 ? props.person.eventDates : []} />

                <>
                    {branding.myProfilePageContent.categoriesOrder.map((item, key) => {
                        switch (item) {
                            case "INTERESTS":
                                return <>
                                    {props.person?.interests && props.person?.interests.length > 0 &&
                                        <ContactCategoryBindings person={props.person!} type={item} />
                                    }
                                </>

                            case "OFFERS":
                                return <>
                                    {props.person?.offering && props.person?.offering.length > 0 &&
                                        <ContactCategoryBindings person={props.person!} type={item} />
                                    }
                                </>

                            case "NEEDS":
                                return <>
                                    {props.person?.lookingfor && props.person?.lookingfor.length > 0 &&
                                        <ContactCategoryBindings person={props.person!} type={item} />
                                    }
                                </>

                            default: return null
                        }

                    })}
                </>


                {props.person.organizations && props.person.organizations[0] &&
                    <CompanyDetails organization={props.person.organizations[0]} />
                }
            </ProfileDetailNameTeaserCol>
        </ProfileDetailRowHeader >
    )
}

// #endregion 




// #endregion

// #region  Person EventDates  
const EventDateContentRow = styled(Row)`
    margin-left: 0px;
`

const EventDateContentCol = styled(Col)`
    padding: 20px 0;
`

const EventDateHeaderPositionRow = styled(Row) <{ display?: string }>`
    margin: 30px 0 10px 0;
    ${props => props.display ? `display: ${props.display};` : ""}
`

interface EventDateProps {
    eventDates: Array<EventDate>
}

const EventDates: React.FunctionComponent<EventDateProps> = React.memo((props) => {
    const strings = useLanguageState().getStrings()

    return (
        props.eventDates.length > 0 ?
            <EventDateContentRow>
                <EventDateHeaderPositionRow>
                    <ProfilePageSectionTitle>{strings.personDetailPageContent.upcomingSessionsTitle} ({props.eventDates.length}) </ProfilePageSectionTitle>
                </EventDateHeaderPositionRow>
                {

                    props.eventDates.map((eventDate, idx) => {
                        return (
                            <EventDateContentCol xs={12} key={idx} style={{ borderBottom: idx < (props.eventDates.length - 1) ? (branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9') : "none" }}>
                                <EventDateView eventDate={eventDate} type="eventdate" />
                            </EventDateContentCol>
                        )
                    }
                    )
                }
            </EventDateContentRow>
            : null
    )
})
interface LiveEventDateProps {
    eventDates: EventDate[]
}
const LiveEventDate: React.FunctionComponent<LiveEventDateProps> = React.memo((props) => {
    const [playing, setPlaying] = useState(false)
    const strings = useLanguageState().getStrings()

    const eventDate = props.eventDates[0]

    return (
        props.eventDates.length > 0 ?
            <>
                <EventDateHeaderPositionRow display="none" /*Display none at the momen of this phase. May be returned later to be shown */>
                    <ProfilePageSectionTitle>{strings.personDetailPageContent.liveSessionsTitle}</ProfilePageSectionTitle>
                    <Col xs={8} className="offset-2">
                        <LiveTileRoot onClick={() => setPlaying(!playing)}>
                            <LiveTileHeader>
                                <div>{IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}</div>
                                <h2>{eventDate.name}</h2>
                            </LiveTileHeader>
                        </LiveTileRoot>
                    </Col>
                </EventDateHeaderPositionRow>
            </>
            : null
    )
})

const LiveTileRoot = styled.div`
 position: relative;
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`
const LiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  padding: 15px;
  width: 100%;
  color: ${branding.personDetailPageContent.liveTileHeaderTextColor ?? "#fff"};
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;

`
const EventDateViewRow = styled(Row)`
    display: flex;
`
const EventDateViewIconCol = styled(Col)`
    display: flex;
    align-items: center;
`
const EventDateViewInfoCol = styled(Col)`
    display:flex;
    align-items:center;
    flex: auto;
`
const EventDateViewActionsCol = styled(Col)`
    display:flex;
    justify-content:flex-end;
    align-items:center;
`


interface EventDateViewProps {
    eventDate: EventDate
    type: DetailNavLinkType
}

const EventDateView: React.FunctionComponent<EventDateViewProps> = (props) => {

    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()
    const [eventDate, setEventDate] = useState<EventDate>(props.eventDate)

    function getFormattedDate(date: string, start: string) {
        return format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, { locale: lang === 'de' ? germanLocale : null }) + (lang === "de" ? " um " : " at ") + start //have to find a better way to do this but this works for now
    }

    useEffect(() => {
        if (categoriesState.getCategories().length)
            setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        loadEventDateList({
            'filterlist': 'entity_evtd, id_evtd_' + props.eventDate.id,
            'startresultrow': 0,
            'numresultrows': 1,
            'order': 'chrono',
            'lang': lang
        }).then((resp) => {
            if (resp.eventDates[0])
                setEventDate(resp.eventDates[0])
        }).catch((e: { message: React.SetStateAction<string> }) => {
        })
        // eslint-disable-next-line
    }, [props.eventDate])

    return (
        <EventDateViewRow style={{ width: "100%", marginRight: "0px" }}>
            <EventDateViewIconCol xs={2}>
                <DetailNavLink source="EVENTDATE" name={props.eventDate.name} id={props.eventDate.id} type={props.type}>
                    {
                        <div style={{ marginTop: "-10px" }}>
                            {
                                props.eventDate.organizationId ?
                                    <CompanyLogoDiv type="organization" id={props.eventDate.organizationId!} name={props.eventDate.organizationName!}>
                                        <CompanyLogo src={props.eventDate.organizationLogoUrl || "/branding/rsz_defaultgrey.png"} />
                                    </CompanyLogoDiv> :
                                    <CompanyLogoDivDuplicate>
                                        <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                                    </CompanyLogoDivDuplicate>
                            }
                        </div>
                    }
                </DetailNavLink>
            </EventDateViewIconCol>
            <EventDateViewInfoCol xs={8}>
                <DetailNavLink source="PERSON" name={props.eventDate.name} id={props.eventDate.id} type={props.type}>
                    <EventDateTimeLocation>
                        {props.eventDate && eventDate.date &&
                            <>
                                {getFormattedDate(eventDate.date, eventDate.start)}  {eventDate.location && ("| " + eventDate.location)}
                            </>
                        }

                        {(pointsBadgeData && eventDate.categories?.map(x => x.alias)?.includes(branding.programSchedule.pointsBadgeCategoryAlias)) &&
                            <PointsBadge
                                className={'ml-2'}
                                textColor={pointsBadgeData.colorText}
                                fillColor={pointsBadgeData.colorFill}
                                borderColor={pointsBadgeData.colorBorder} >
                                {strings.programSchedule.pointsBadgeText}
                            </PointsBadge>}
                    </EventDateTimeLocation>
                    <EventDateTitle>{eventDate.name || props.eventDate.name}</EventDateTitle>
                    <div className="mt-2">
                        {props.eventDate && eventDate.teaser &&
                            <EventDateDescription>{eventDate.teaser}</EventDateDescription>
                        }

                    </div>
                </DetailNavLink>
            </EventDateViewInfoCol>
            <EventDateViewActionsCol xs={2}>
                <div style={{ paddingBottom: "85px" }}>
                    <ShareBookmarkIcons type="eventdate" id={props.eventDate.id} name={props.eventDate.name} />
                </div>
            </EventDateViewActionsCol>
        </EventDateViewRow>
    )
}
// #endregion 

// #region  Share and bookmark icons component 
const ShareIcon = styled.div`
    cursor: pointer;
    margin-right:20px;
`
interface ShareBookmarkIconsProps {
    id: string
    type: EntityType
    name: string
    isUser?: boolean
    setShowBookmarkEventsModal?: (toggled: boolean) => void
}
const IconRootArea = styled.div`
    color: ${branding.mainInfoColor ?? '#000'};
    display: flex;
`

export const ShareBookmarkButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.sideIconBar.sideIconColorDark};;
    color: ${branding.sideIconBar.sideIconColorDark};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    min-width: 212px;
    width: auto;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`


const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = React.memo((props) => {
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)

    const link = props.type === "person" ? buildDetailLink(props.id, `/person/${props.name.split(" ")[0]}_${props.name.split(" ")[1]}`, props.isUser ? "user" : "person") : buildDetailLink(props.id, props.name, "eventdate")


    return (
        <IconRootArea>
            <ShareIcon onClick={() => setShowRecommendOrganizationModal(true)}>
                {IconShare({ fill: branding.personDetailPageContent.upcomingSessionShareIconColor ?? "#000" })}
            </ShareIcon>
            <BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} favIconBasic={true} type={props.type} id={props.id!} customOnBookmarkedFunction={() => {
                if (props.setShowBookmarkEventsModal) {
                    props.setShowBookmarkEventsModal(true)
                }
            }} />

            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.id} type={props.type === "person" ? ShareTargetType.PERSON : ShareTargetType.EVENTDATE} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}


        </IconRootArea>
    )
})

const ShareBookmarkButtons: React.FunctionComponent<ShareBookmarkIconsProps> = React.memo((props) => {
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)

    const languageState = useLanguageState();
    const [localization, setLocalization] = useState(languageState.getStrings())
    const lang = languageState.getLanguage()

    const link = props.type === "person" ? buildDetailLink(props.id, `/person/${props.name.split(" ")[0]}_${props.name.split(" ")[1]}`, props.isUser ? "user" : "person") : buildDetailLink(props.id, props.name, "eventdate")


    useEffect(() => {
        setLocalization(languageState.getStrings())
        // eslint-disable-next-line 
    }, [lang])

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <ShareBookmarkButton onClick={() => setShowRecommendOrganizationModal(true)}>
                <div style={{ display: "inline-block", float: "left", paddingLeft: "10px" }}>
                    {IconShare({ fill: branding.sideIconBar.sideIconColorDark ?? "#000" })}
                </div>

                <div style={{ display: "inline-block", float: "left", paddingLeft: "10px" }}>
                    {localization.personDetailPageContent.shareSpeakerButtonText}
                </div>

            </ShareBookmarkButton>

            <br />

            <BookmarkWithToggle fullButton={true} buttonText={localization.personDetailPageContent.bookmarkEventsButtonText} newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} favIconBasic={true} type={props.type} id={props.id!} customOnBookmarkedFunction={() => {
                if (props.setShowBookmarkEventsModal) {
                    props.setShowBookmarkEventsModal(true)
                }
            }} />

            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.id} type={props.type === "person" ? ShareTargetType.PERSON : ShareTargetType.EVENTDATE} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}


        </div >
    )
})
// #endregion 

export interface ContactCategoryBindingsProps {
    person: any
    type: CategoryType

    setIsEmpty?: (value: boolean) => void
}

function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsTitle

        case CategoryType.OFFERS:
            return strings.offersTitle

        case CategoryType.NEEDS:
            return strings.needsTitle

        default:
            return strings.interestsTitle
    }
}

const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = React.memo((props) => {
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const strings = langState.getStrings().personDetailPageContent
    const userState = useLoggedInState()

    const [categories, setCategories] = useState<{ common: Category[], unique: Category[] }>({ common: [], unique: [] })
    let content: JSX.Element = <div />

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [props.person, lang])

    function loadCategories() {
        getInterest().then(res => {
            if (res.content.interests || res.content.lookingforandoffering) {
                let selectedCategoryIds: String[] = []
                let allCategories: Category[] = []
                let myCategoriesTemp: any[] = []


                switch (props.type) {
                    case CategoryType.INTERESTS:
                        selectedCategoryIds = props.person.interests ?? []
                        allCategories = res.content.interests
                        myCategoriesTemp = userState.user()?.interests!
                        break

                    case CategoryType.OFFERS:
                        selectedCategoryIds = props.person.offering ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.offering!
                        break

                    case CategoryType.NEEDS:
                        selectedCategoryIds = props.person.lookingfor ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.lookingfor!
                        break
                }
                const commonCategories: Category[] = []
                const uniqueCategories: Category[] = []
                allCategories.forEach((i: Category) => {
                    if (myCategoriesTemp?.includes(i.id) && selectedCategoryIds?.includes(i.id))
                        commonCategories.push(i)
                    else if (myCategoriesTemp?.includes(i.id) === false && selectedCategoryIds?.includes(i.id))
                        uniqueCategories.push(i)
                })
                setCategories({ common: commonCategories, unique: uniqueCategories })
            }
        })
    }

    if (categories.unique.length || categories.common.length) {
        content = <>
            <CategoriesTitleRow>
                <ProfilePageSectionTitle>{getCategorySectionTitle(strings, props.type)} </ProfilePageSectionTitle>
            </CategoriesTitleRow>
            <CategoriesViewRow>
                {
                    categories.common.map(category => {
                        return (
                            <div key={category.id}><CategoriesItem bold={true}>{category.name}</CategoriesItem></div>
                        );
                    })
                }
                {
                    categories.unique.map(category => {
                        return (
                            <div key={category.id}><CategoriesItem bold={false}>{category.name}</CategoriesItem></div>
                        );
                    })
                }
            </CategoriesViewRow>
        </>
    }
    else {
        content = <></>
    }

    return content
})

// #endregion
