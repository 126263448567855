import React, { useEffect, useState } from "react";
import styled from "styled-components";
import branding from "../branding/branding";
import { IconBookmarkFilled, IconCardView, IconListView } from "./Icons";
import useWindowDimensions from "./WindowDimensionsHook";
import { ResponsiveContainer, device } from "../utils/Device";
import { checkIfMobile } from "..";

const CrsTabsRoot = styled.div<{ showOnlyActionButtons: boolean }>`
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0 auto;
  color: ${branding.crsTabs.tabItemDefaultTextColor};
  background-color: ${branding.crsTabs.defaultBackgroundColor};
  overflow: hidden;
  min-height: 30px;
  padding-bottom: ${(props) => (props.showOnlyActionButtons ? "44px" : "0")};

  @media ${device.tablet} {
    padding: 0 25px;
  }
`;

const TabsHeader = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid ${branding.crsTabs.defaultBorderColor};
  &:hover {
    overflow-x: auto;
  }
`;

const TabItem = styled.div<{ width: number }>`
  display: inline-block;
  box-sizing: content-box;
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: ${branding.font1};
  font-size: ${branding.crsTabs.tabItemDefaultFontSize};
  line-height: 12px;
  cursor: pointer;
  &.active {
    font-weight: bold;
    color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
  }
`;

const ActiveTabItemIndicator = styled.div<{ width: number; left: number }>`
  width: ${(props) => `${props.width}px`};
  height: 3px;
  position: absolute;
  bottom: 0;
  left: ${(props) => `${props.left}px`};
  background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
  transition: left 0.5s;
`;

const TabHeaderActions = styled.div`
  position: absolute;
  right: 23px;
  top: 0;
  bottom: 1px;
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

const TabHeaderActionsItem = styled.div<{ display?: string }>`
  cursor: pointer;
  display: ${(props) => props.display};
  :last-child {
    margin-left: 10px;
  }
`;

export enum ActionTypes {
  TILELIST = "TILELIST",
  BOOKMARK = "BOOKMARK",
}

export interface TabItemType {
  label: string;
  value: string;
  actions?: ActionTypes[];
}

export enum ViewMode {
  LIST,
  TILES,
}

interface CrsTabsProps {
  tabItems: TabItemType[];
  tabsContentHeight?: string;
  activeItem?: any;
  onTabItemClicked?: (tabItem: string) => void;
  viewMode?: ViewMode;
  onViewModeChange?: (viewMode: ViewMode) => void;
  hideBookmarkButton?: boolean;
  bookmarkFilter?: boolean;
  setBookmarkFilter?: (value: boolean) => void;
  responsive?: boolean;
  onViewModeToggle?: () => void;
  showOnlyActionButtons?: boolean;
  style?: React.CSSProperties | undefined;

  itemWidth?: number;
}

const CrsTabs = (props: CrsTabsProps) => {
  const [widestTabItemWidth, setWidestTabItemWidth] = useState(0);
  const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(0);
  const [selectedTabItem, setSelectedTabItem] = useState(
    props.activeItem
      ? props.tabItems.find(
        (item: TabItemType) => item.value === props.activeItem
      ) || props.tabItems[0]
      : props.tabItems[0]
  );
  const windowSize = useWindowDimensions();
  let itemPadding = props.itemWidth ?? (checkIfMobile(windowSize) ? 120 : 200);

  const onTabItemClicked = (tabItem: TabItemType) => {
    if (props.onTabItemClicked) {
      props?.onTabItemClicked(tabItem.value);
    }

    setSelectedTabItem(tabItem);
  };

  const setWidestTabItemWidthMethod = () => {
    let tabItems = document.querySelectorAll("div.tab-item");
    let maxWidth = 0;
    let totalWidth = maxWidth + itemPadding; // padding
    tabItems.forEach((element) => {
      if (element.clientWidth > maxWidth) maxWidth = element.clientWidth;
    });
    setWidestTabItemWidth(totalWidth);
  };

  useEffect(() => {
    setWidestTabItemWidthMethod();
    if (props.activeItem) {
      setSelectedTabItem(
        props.tabItems.find(
          (item: TabItemType) => item.value === props.activeItem
        ) || props.tabItems[0]
      );
      setActiveIndicatorLeft(
        props.tabItems.findIndex((x) => x.value === props.activeItem) *
        itemPadding
      );
    }
    // eslint-disable-next-line
  }, [props.tabItems]);

  const ListViewToggleButton = () => {
    const onToggleViewModeClicked = () => {
      if (props.viewMode === ViewMode.LIST) {
        if (props.onViewModeChange) {
          props.onViewModeChange!(ViewMode.TILES);
        } else if (props.onViewModeToggle) {
          props.onViewModeToggle();
        }
      } else {
        if (props.onViewModeChange) {
          props.onViewModeChange!(ViewMode.LIST);
        } else if (props.onViewModeToggle) {
          props.onViewModeToggle();
        }
      }
    };

    return (
      <TabHeaderActionsItem
        onClick={onToggleViewModeClicked}
        display={props.responsive && windowSize.width < 1350 ? "none" : "flex"}
      >
        {props.viewMode === ViewMode.TILES
          ? IconListView({ fill: branding.crsTabs.defaultActionItemColor })
          : IconCardView({
            fill: branding.crsTabs.defaultActionItemActiveStateColor,
          })}
      </TabHeaderActionsItem>
    );
  };

  const BookmarkButton = () => {
    function handleClick() {
      if (props.setBookmarkFilter && props.bookmarkFilter !== undefined) {
        props.setBookmarkFilter(!props.bookmarkFilter);
      }
    }
    return (
      <ResponsiveContainer>
        <TabHeaderActionsItem onClick={handleClick}>
          {props.bookmarkFilter
            ? branding.defaultToggleIcon
              ? IconBookmarkFilled({
                fill: branding.crsTabs.defaultActionItemActiveStateColor,
              })
              : IconBookmarkFilled({
                fill: branding.crsTabs.defaultActionItemColor,
              })
            : IconBookmarkFilled({
              fill: branding.crsTabs.defaultActionItemColor,
            })}
        </TabHeaderActionsItem>
      </ResponsiveContainer>
    );
  };

  return (
    <CrsTabsRoot
      showOnlyActionButtons={props.showOnlyActionButtons || false}
      style={props.style}
    >
      <TabsHeader
        style={{ display: props.showOnlyActionButtons ? "none" : "block" }}
      >
        {props.tabItems.map((tabItem, index) => {
          return (
            <TabItem
              key={index}
              className={[
                selectedTabItem.value === tabItem.value ? "active" : "",
                "tab-item",
              ].join(" ")}
              width={widestTabItemWidth}
              onClick={() => onTabItemClicked(tabItem)}
            >
              {tabItem.label}
            </TabItem>
          );
        })}
        <ActiveTabItemIndicator
          width={widestTabItemWidth}
          left={activeIndicatorLeft}
        />
      </TabsHeader>
      <TabHeaderActions>
        {selectedTabItem.actions?.includes(ActionTypes.TILELIST) && (
          <ListViewToggleButton />
        )}
        {selectedTabItem.actions?.includes(ActionTypes.BOOKMARK) && (
          <BookmarkButton />
        )}
      </TabHeaderActions>
    </CrsTabsRoot>
  );
};

export default CrsTabs;
