import React, { useState, useEffect } from "react"
import branding from "../../branding/branding"
import ViewMyProfileLayout from "./ViewMyProfileLayout"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb, { BreadcrumbItem } from "../../navigationArea/Breadcrumb"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { useLanguageState } from "../../globalStates/LanguageState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import MobileMyProfilePageContent from "./MobileMyProfilePageContent"
import { checkIfMobile } from "../.."
import EditMyProfileLayout from "./EditMyProfileLayout"



export enum MyProfilePageMode { VIEW, EDIT }

export enum EditProfileSection {
    CONTACT = "CONTACT",
    SOCIALS = "SOCIALS",
    INTERESTS = "INTERESTS",
    LOOKINGFORANDOFFERING = "LOOKINGFORANDOFFERING"
}
export interface MyProfileSubPageProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    setEditProfileSection: (section: EditProfileSection) => void
    guestBannerHeight: number
}

const MyProfilePageContent: React.FunctionComponent = (props) => {
    const params = new URLSearchParams(window.location.search).get('mode')
    const lang = useLanguageState().getLanguage()

    const [myProfileMode, setMyProfileMode] = useState<MyProfilePageMode>(params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW)
    const [editProfileSection, setEditProfileSection] = useState<EditProfileSection>(EditProfileSection.CONTACT)

    const strings = useLanguageState().getStrings()
    const locations = calcBreadcrumbLocations(strings)
    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState();
    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0;

    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([])

    useEffect(() => {
        setMyProfileMode(params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW)
    }, [params])

    useEffect(() => {
        if (myProfileMode === MyProfilePageMode.VIEW) {
            setBreadcrumb([{ to: "/", name: strings.sideIconBar.lobbyMenuText },
            { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
            { to: locations[0], name: strings.myProfilePageContent.myProfileTitle }])
        }
        else {
            setBreadcrumb([{ to: "/", name: strings.sideIconBar.lobbyMenuText },
            { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
            { to: locations[0], name: strings.myProfilePageContent.myProfileTitle, onClick: () => setMyProfileMode(MyProfilePageMode.VIEW) },
            { to: locations[0], name: strings.myProfilePageContent.editProfileTitle }])
        }
        // eslint-disable-next-line
    }, [myProfileMode, lang])

    const windowSize = useWindowDimensions()

    if (checkIfMobile(windowSize)) {
        return <MobileMyProfilePageContent />
    }


    return <div style={{ fontFamily: branding.font1, backgroundColor: "white" }}>
        <GuestUserBanner setRef={setGuestUserBannerRef} />
        <TopBar />
        <Breadcrumb breadcrumb={breadcrumb} />
        {
            myProfileMode === MyProfilePageMode.VIEW &&
            <ViewMyProfileLayout setMyProfileMode={setMyProfileMode} setEditProfileSection={setEditProfileSection} guestBannerHeight={guestBannerHeight} />
        }
        {
            myProfileMode === MyProfilePageMode.EDIT &&
            <EditMyProfileLayout setMyProfileMode={setMyProfileMode} guestBannerHeight={guestBannerHeight} setSection={setEditProfileSection} section={editProfileSection} />
        }
    </div>
}

export default MyProfilePageContent
