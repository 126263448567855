import React, { useState, useEffect } from "react"
import { Contact, Category, } from "../../backendServices/Types"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { IconPhone, IconLocation, IconLanguages, IconEmail, IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconInstagram, IconYouTube, IconPinterest, IconThreeDotsMenu, IconArrowUpLangSwitcher, IconArrowDownLangSwitcher, IconFacebookOriginal, IconTwitterOriginal, IconLinkedInOriginal, IconXingOriginal, IconInstagramOriginal, IconYouTubeOriginal, IconPinterestOrginal } from "../../ui/Icons"

import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { findCountryName } from "../myprofile/CountrySwitcherHelper"
import { getListOfLanguagesString } from "../myprofile/LanguageSwitcherHelper"
import MobileMyProfilePageContent, { ProfileRoot, ProfileSectionsContainer, ProfileSectionRoot, UserImageDiv, Title, Paragraph, ContactSectionIconContainer, ContactSectionTextContainer, BiographyContainer, ContactSectionButtonsContainer, StyledButton, SocialMediaContainer, ProfileSocialsWrapper, ProfileSocialItem, InterestsTitleRow, InterestItem, InterestsViewRow, SectionTopButton } from "../myprofile/MobileMyProfilePageContent"
import { SocialMediaType, CategoryType } from "../myprofile/MyProfilePageContentBranding"
import { getInterest } from "../../backendServices/BackendServices"
import { ContactCategoryBindingsProps } from "./PersonDetailPageContent"
import CompanyDetails from "../../ui/CompanyDetails"
import styled from "styled-components"


const MobilePersonDetailPageContent: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId

    const [isMyProfile, setIsMyProfile] = useState<boolean>(false)

    useEffect(() => {
        if (props.contact.id === loggedInUserId || props.contact.sotUserId === loggedInUserId) {
            setIsMyProfile(true)
        }
        else {
            setIsMyProfile(false)
        }

    }, [props.contact]) // eslint-disable-line react-hooks/exhaustive-deps

    return isMyProfile ? <MobileMyProfilePageContent /> :
        <ProfileRoot>
            <ProfileSectionsContainer>
                <ContactSection contact={props.contact} />
                <SocialMediaSection contact={props.contact} />

                {
                    branding.myProfilePageContent.categoriesOrder.includes(CategoryType.INTERESTS) &&
                    <InterestsSection contact={props.contact} />
                }

                {
                    (branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS) || branding.myProfilePageContent.categoriesOrder.includes(CategoryType.NEEDS)) &&
                    <OffersNeedsSection contact={props.contact} />
                }

                <CompanySection contact={props.contact} />
            </ProfileSectionsContainer>
        </ProfileRoot>


}

export default MobilePersonDetailPageContent

interface SectionProps {
    contact: Contact
}

const ContactSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const currentUserInfo: string = props.contact.infotext || ""
    const currentUserName: string = [props.contact.firstName, props.contact.lastName].filter(Boolean).join(" ")
    const localizedPosition = props.contact.position
    const currentUserPosition: string = [localizedPosition, props.contact.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const currentUserIndustry: string = props.contact.industry || ""

    return <ProfileSectionRoot>
        <SectionTopButton>
            {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
        </SectionTopButton>
        <UserImageDiv>
            <AvatarWithDefault size={80} src={props.contact.logoUrl} alt={[props.contact.firstName, props.contact.lastName].join(" ") ?? "#"} />
        </UserImageDiv>
        <Title>{currentUserName}</Title>
        <Paragraph>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</Paragraph>

        {props.contact.countrycode &&
            <Paragraph>
                <ContactSectionIconContainer>
                    {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                </ContactSectionIconContainer>

                <ContactSectionTextContainer>
                    {findCountryName(props.contact.countrycode, lang)}
                </ContactSectionTextContainer>
            </Paragraph>}

        {props.contact.languages && props.contact.languages?.length! > 0 &&
            <Paragraph>
                <ContactSectionIconContainer>
                    {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                </ContactSectionIconContainer>

                <ContactSectionTextContainer>
                    {getListOfLanguagesString(props.contact.languages, lang)}
                </ContactSectionTextContainer>
            </Paragraph>

        }

        {currentUserInfo !== "" &&
            <BiographyContainer>
                <Title>{strings.myProfilePageContent.biographyMobileLabel}</Title>

                <Paragraph>{currentUserInfo}</Paragraph>
            </BiographyContainer>
        }

        <ContactSectionButtonsContainer>
            {props.contact.phone &&
                <StyledButton>
                    <a href={"tel:" + props.contact.phone} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                        <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <ContactSectionIconContainer>
                                {IconPhone({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ContactSectionIconContainer>

                            <ContactSectionTextContainer>
                                {strings.myProfilePageContent.phoneLabel}
                            </ContactSectionTextContainer>
                        </Paragraph>
                    </a>
                </StyledButton>
            }

            {props.contact.email &&
                <StyledButton>
                    <a href={"mailto:" + props.contact.email} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                        <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <ContactSectionIconContainer>
                                {IconEmail({ fill: branding.sideIconBar.sideIconColorDark })}
                            </ContactSectionIconContainer>

                            <ContactSectionTextContainer>
                                {strings.myProfilePageContent.emailLabel}
                            </ContactSectionTextContainer>
                        </Paragraph>
                    </a>
                </StyledButton>
            }

        </ContactSectionButtonsContainer>

    </ProfileSectionRoot>
}

const SocialMediaSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const strings = useLanguageState().getStrings()

    function checkIfNoSocials(): boolean {
        const brandingList = branding.myProfilePageContent.availableSocialMedias

        if (props.contact.facebook && props.contact.facebook.length > 0 && brandingList.includes(SocialMediaType.FACEBOOK)) {
            return true
        }

        if (props.contact.twitter && props.contact.twitter.length > 0 && brandingList.includes(SocialMediaType.TWITTER)) {
            return true
        }

        if (props.contact.instagram && props.contact.instagram.length > 0 && brandingList.includes(SocialMediaType.INSTAGRAM)) {
            return true
        }

        if (props.contact.linkedIn && props.contact.linkedIn.length > 0 && brandingList.includes(SocialMediaType.LINKEDIN)) {
            return true
        }

        if (props.contact.youTube && props.contact.youTube.length > 0 && brandingList.includes(SocialMediaType.YOUTUBE)) {
            return true
        }

        if (props.contact.xing && props.contact.xing.length > 0 && brandingList.includes(SocialMediaType.XING)) {
            return true
        }

        if (props.contact.pinterest && props.contact.pinterest.length > 0 && brandingList.includes(SocialMediaType.PINTEREST)) {
            return true
        }

        return false
    }

    if (!checkIfNoSocials()) {
        return null
    }

    return <ProfileSectionRoot>
        <SocialMediaContainer>
            <Title>{strings.myProfilePageContent.socialMediaSectionTitle}</Title>

            <ProfileSocialsWrapper>
                {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                    if (item === SocialMediaType.FACEBOOK) {
                        return <>
                            {props.contact.facebook && <ProfileSocialItem key={index} href={props.contact.facebook} target="_blank">
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconFacebookOriginal({width: '20', height: '20'}) : IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return <>
                            {props.contact.twitter && <ProfileSocialItem key={index} href={props.contact.twitter} target="_blank">
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconTwitterOriginal({width: '20', height: '20'}) : IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return <>
                            {props.contact.linkedIn && <ProfileSocialItem key={index} href={props.contact.linkedIn} target="_blank">
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconLinkedInOriginal({width: '20', height: '20'}) : IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.XING) {
                        return <>
                            {props.contact.xing && <ProfileSocialItem key={index} href={props.contact.xing}>
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconXingOriginal({width: '20', height: '20'}) : IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return <>
                            {props.contact.instagram && <ProfileSocialItem key={index} href={props.contact.instagram}>
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconInstagramOriginal({width: '20', height: '20'}) : IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return <>
                            {props.contact.youTube && <ProfileSocialItem key={index} href={props.contact.youTube}>
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconYouTubeOriginal ({width: '20', height: '20'}) : IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return <>
                            {props.contact.pinterest && <ProfileSocialItem key={index} href={props.contact.pinterest}>
                            {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconPinterestOrginal({width: '20', height: '20'}) : IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    return null
                })
                }


            </ProfileSocialsWrapper>
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const CompanySection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    return <>
        {props.contact.organizations && props.contact.organizations[0] &&
            <ProfileSectionRoot>
                <SocialMediaContainer>
                    <CompanyDetails organization={props.contact.organizations[0]} />
                </SocialMediaContainer>
            </ProfileSectionRoot>
        }
    </>
}

function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsTitle

        case CategoryType.OFFERS:
            return strings.offersTitle

        case CategoryType.NEEDS:
            return strings.needsTitle

        default:
            return strings.interestsTitle
    }
}

const InterestsSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    if (isEmpty) {
        return null
    }

    return <ProfileSectionRoot>
        <SocialMediaContainer>
            <ContactCategoryBindings type={CategoryType.INTERESTS} person={props.contact} setIsEmpty={setIsEmpty} />
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const OffersNeedsSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const [isOffersEmpty, setIsOffersEmpty] = useState<boolean>(false)
    const [isNeedsEmpty, setIsNeedsEmpty] = useState<boolean>(false)

    if ((!(branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS)) && !(branding.myProfilePageContent.categoriesOrder.includes(CategoryType.NEEDS))) || (isOffersEmpty && isNeedsEmpty)) {
        return null
    }


    return <ProfileSectionRoot>
        <SocialMediaContainer>
            <>
                {branding.myProfilePageContent.categoriesOrder.map((item, index) => {
                    switch (item) {
                        case "OFFERS":
                            return <ContactCategoryBindings key={index} type={CategoryType.OFFERS} person={props.contact} setIsEmpty={setIsOffersEmpty} />

                        case "NEEDS":
                            return <ContactCategoryBindings key={index} type={CategoryType.NEEDS} person={props.contact} setIsEmpty={setIsNeedsEmpty} />

                        default: return null
                    }

                })}
            </>
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const ViewMoreButtonContainer = styled.div`
    float: right;
    margin-top: -5px;
    margin-right: 10px;
`

const ViewMoreButtonTextContainer = styled.div`
    font-size: 12px;
    display: inline-block;
`

const ViewMoreButtonIconContainer = styled.div`
    display: inline-block;
    margin-left: 5px;
`

const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = React.memo((props) => {
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const strings = langState.getStrings().personDetailPageContent

    const categoriesLimitNumber = branding.personDetailPageContent.categoriesLimitNumber
    const [numberOfCategories, setNumberOfCategories] = useState<number>(0)

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const [categories, setCategories] = useState<Category[]>([])
    let content: JSX.Element = <div />

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [props.person, lang])

    function loadCategories() {
        getInterest().then(res => {
            if (res.content.interests || res.content.lookingforandoffering) {
                let selectedCategoryIds: String[] = []
                let allCategories: Category[] = []

                switch (props.type) {
                    case CategoryType.INTERESTS:
                        selectedCategoryIds = props.person.interests ?? []
                        allCategories = res.content.interests
                        break

                    case CategoryType.OFFERS:
                        selectedCategoryIds = props.person.offering ?? []
                        allCategories = res.content.lookingforandoffering
                        break

                    case CategoryType.NEEDS:
                        selectedCategoryIds = props.person.lookingfor ?? []
                        allCategories = res.content.lookingforandoffering
                        break
                }
                const tempCategories: Category[] = []

                setNumberOfCategories(selectedCategoryIds.length)

                if (numberOfCategories > categoriesLimitNumber) {
                    setIsExpanded(true)
                }

                allCategories.forEach((i: Category) => {
                    if (selectedCategoryIds?.includes(i.id))
                        tempCategories.push(i)
                })

                setCategories(tempCategories)

                if (props.setIsEmpty) {
                    if (tempCategories.length === 0) {
                        props.setIsEmpty(true)
                    }
                }
            }
        })
    }

    if (categories.length > 0) {
        content = <>
            <InterestsTitleRow>
                <Title>{getCategorySectionTitle(strings, props.type)} </Title>
                {(numberOfCategories > categoriesLimitNumber) &&
                    <ViewMoreButtonContainer onClick={() => setIsExpanded(!isExpanded)}>
                        <ViewMoreButtonTextContainer>
                            {strings.viewMoreCategoriesButtonText}
                        </ViewMoreButtonTextContainer>
                        <ViewMoreButtonIconContainer>
                            {isExpanded ? IconArrowUpLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark }) : IconArrowDownLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ViewMoreButtonIconContainer>
                    </ViewMoreButtonContainer>
                }
            </InterestsTitleRow>
            <InterestsViewRow>

                {(isExpanded ? categories : categories.slice(0, categoriesLimitNumber)).map((category, key) => {
                    return (
                        <div key={category.id}><InterestItem>{category.name}</InterestItem></div>
                    );
                })
                }
            </InterestsViewRow>
        </>
    }
    else {
        content = <></>
    }

    return content
})