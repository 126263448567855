import { useLoggedInState } from './LoggedInUser';
import { createState, State, useState } from '@hookstate/core';
import { getUserRestrictedAreaAccess, RestrictedAreaTypes, BackendServiceError, UserRestrictedAreaAccess, getAccessStatusForAllEvents } from '../backendServices/BackendServices';
import { EventDate } from '../backendServices/Types';
import { throttle } from 'lodash';

const THROTTLE_MS = 10 * 1000 // 10 seconds

export enum EventDateAccessType {
    "GRANTED" = "GRANTED",
    "REQUESTED" = "REQUESTED"
}

export interface UserEventAccessContext {
    isLoaded: boolean
    setIsLoaded: (isLoaded: boolean) => void
    userEventDateAccess?: UserRestrictedAreaAccess | null
    setUserEventDateAccess: (access: UserRestrictedAreaAccess | null) => void
    isEventUnlocked: boolean
    checkIsEventUnlocked: (eventDate: EventDate) => void
    userEventDateAccessList?: UserRestrictedAreaAccess[] | undefined
    getUserAccessForAll: () => void
}

export interface UserEventAccessState {
    isLoaded: boolean
    isEventUnlocked: boolean
    userEventDateAccess?: UserRestrictedAreaAccess | null
    userEventDateAccessList?: UserRestrictedAreaAccess[] | undefined
}

const state = createState<UserEventAccessState>({ isLoaded: true, isEventUnlocked: false })

const useStateWrapper = (state: State<UserEventAccessState>) => {
    const loggedInUser = useLoggedInState().user()

    const checkStaffAccess = (eventDate: EventDate) => {
        return ((loggedInUser?.organizations?.find(o => o.id === eventDate?.organizationId) !== undefined))!
    }

    return ({
        isLoaded: state.isLoaded.value,
        setIsLoaded: (isLoaded: boolean) => {
            state.set(prevState => {
                prevState.isLoaded = isLoaded
                return prevState
            })
        },
        userEventDateAccess: state.userEventDateAccess.value,
        setUserEventDateAccess: (access: UserRestrictedAreaAccess | null) => {
            state.set(prevState => {
                prevState.userEventDateAccess = access
                return prevState
            })
        },
        isEventUnlocked: state.isEventUnlocked.value,
        checkIsEventUnlocked: (eventDate: EventDate) => {
            if (eventDate && eventDate.isPrivate && loggedInUser?.profileId) {
                state.set(prevState => {
                    prevState.isLoaded = false
                    return prevState
                })
                getUserAccessStatusThrottledRequest(eventDate, loggedInUser?.profileId)
                    ?.then(resp => {
                        const userAccessTemp: UserRestrictedAreaAccess | undefined = resp
                        const userHasAccess = userAccessTemp && userAccessTemp?.status === EventDateAccessType.GRANTED
                        const userIsStaff = checkStaffAccess(eventDate)
                        state.set(prevState => {
                            prevState.isLoaded = true
                            prevState.userEventDateAccess = userAccessTemp
                            prevState.isEventUnlocked = userHasAccess || userIsStaff
                            return prevState
                        })
                    })
                    .catch(err => {
                        state.set(prevState => {
                            prevState.isLoaded = true
                            prevState.isEventUnlocked = false
                            return prevState
                        })
                    })
            } else {
                state.set(prevState => {
                    prevState.isLoaded = true
                    prevState.isEventUnlocked = true
                    return prevState
                })
            }
        },
        userEventDateAccessList: state.userEventDateAccessList.value,
        getUserAccessForAll: () => {
            if (loggedInUser) {
                state.set(prevState => {
                    prevState.isLoaded = false
                    return prevState
                })

                getUserAccessStatusListThrottledRequest()
                    ?.then(resp => {
                        state.set(prevState => {
                            prevState.isLoaded = true
                            prevState.userEventDateAccessList = resp
                            return prevState
                        })
                    })
                    .catch(err => {
                        state.set(prevState => {
                            prevState.isLoaded = true
                            return prevState
                        })
                    })
            }
        }
    })
}

export const useUserEventAccess = (): UserEventAccessContext => useStateWrapper(useState(state))


const getUserAccessStatusThrottledRequest = throttle((eventDate: EventDate, profileId: string | undefined) => {

    return getUserRestrictedAreaAccess(profileId!, eventDate.id, RestrictedAreaTypes.PrivateEvent)
        .then((data) => {
            let userAccessTemp: any = null
            const errorStatus = (data as BackendServiceError).httpStatus
            if (errorStatus) {
                if (errorStatus !== 404)
                    userAccessTemp = undefined
            } else {
                const userAccess = (data as UserRestrictedAreaAccess)
                userAccessTemp = userAccess
            }
            return userAccessTemp
        })
        .catch(err => {
            return undefined
        })

}, THROTTLE_MS)


const getUserAccessStatusListThrottledRequest = throttle(() => {
    return getAccessStatusForAllEvents()
        .then(resp => {
            if ((resp as BackendServiceError).httpStatus) {
                return []
            } else {
                return resp as UserRestrictedAreaAccess[]
            }
        })
        .catch((err) => {
            return []
        })
}, THROTTLE_MS)
