import React, { Fragment, useState, useEffect } from 'react'
import TileRow from '../../ui/TileRow'
import { useLanguageState } from '../../globalStates/LanguageState'
import styled from 'styled-components'
import branding from '../../branding/branding'
import { IconSchedule, IconMeetingSchedule, IconSpeakers, IconOrganization, vgCustomIconSponsor, IconLinkRightArrow } from '../../ui/Icons'
import { mySchedulePageRoute, programPageRoute, bookmarkedSpeakersPageRoute, speakersPageRoute, bookmarkedshowfloorPageRoute, showfloorPageRoute, productsPageRoute, trademarksPageRoute } from '../../navigationArea/RoutePaths'
import EventDateEntry from '../program/EventDateEntry'
import { EventDate, Person, ShareTargetType, Exhibitor, Product, Trademark } from '../../backendServices/Types'
import { CalendarEntryModalViewMode } from '../../ui/CalendarEntryModal'
import { CalendarEntry, getCalendarEntries, loadEventDateList, loadExhibitorsData, loadProductsData, loadTrademarksData, loadEventSpeakersData } from '../../backendServices/BackendServices'
import { CalendarEntryParticipationStatus } from "../../API";
import { useLoggedInState } from '../../globalStates/LoggedInUser'
import moment from 'moment'
import { useFavoriteState } from '../../globalStates/Favorites'
import Schedule from '../program/Schedule'
import EmptyTile from './EmptyTile'
import PersonComponent from '../../ui/PersonComponent'
import { buildDetailLink } from '../detailPages/DetailNavLink'
import BookmarkWithToggle from '../../ui/BookmarkWithToggle'
import { useNavigationSource } from '../../tracking/NavigationSource'
import { isToday, momentWithoutTimezoneFromTimezonedMoment } from '../../utils/DateUtils'
import { NavLink } from 'react-router-dom'
import { TileWrapperSpeakers, MobileTileWrapperSpeakers } from './ReceptionPageContent'
import { Showfloor } from './LobbyPageContent'
import { BasisPremiumType } from '../../branding/BasisPremiumBranding'
import { useAppState } from '../../globalStates/AppState'
import { orderBy } from 'lodash'
import VizSensor from 'react-visibility-sensor'
import { ActionType } from '../entitiesActions/actionsUtils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { SimpleOrganization } from '../ExhibitorsPageContent'


const UpNextWrapper = styled.div`
  font-family: ${branding.font1};
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .eventDateRoot{
    width: 100%;
  }

  .eventDate-entry-root{
      width: 49%;
  }

  .eventDate-entry-root .detailNavLink{
      width: 100%;
  }
`

const MyScheduleContainer = styled.div`
    .verticalBarRoot{
      height: 80%;
      top: 120px;
    }
`

const MyScheduleRoot = styled.div`
    width: 99%;
    position: relative;

    .schedule-root{
        height: 50vh;
        overflow: hidden;
    }
    .my-schedule-root{
      margin-left: 0 !important;
    }
`

const ExhibitorTile = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 250px;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding: 20px;
    margin: 0px 0 1.25rem 0px;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
      background-color: white;
    }

    &:hover .show-on-hover{
      display: inline-block;
    }

    & .show-on-hover{ 
      display: none;
      color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    }

    & .exhibitor-bookmark{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  
`

const ProductTile = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 500px;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 280px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding: 20px;
    margin: 0px 0 1.25rem 0px;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
      background-color: white;
    }

    &:hover .show-on-hover{
      display: inline-block;
    }

    & .show-on-hover{ 
      display: none;
      color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    }

    & .exhibitor-bookmark{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  
`
const ExhibitorTileSmall = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 250px;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding: 20px;
    margin: 0px 0 1.25rem 0px;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
      background-color: white;
    }

    &:hover .show-on-hover{
      display: inline-block;
    }

    & .show-on-hover{ 
      display: none;
      color: ${branding.crsTabs.defaultActionItemColor};
    }

    & .exhibitor-bookmark{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  
`

const MyFairPageTileMobile = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 95px;
    justify-content: center;
    align-items: center;
    width: 95px !important;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding: 10px;
    margin: 0px 0 1.25rem 0px;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
      background-color: white;
    }

    &:hover .show-on-hover{
      display: inline-block;
    }

    & .show-on-hover{ 
      display: none;
      color: ${branding.crsTabs.defaultActionItemColor};
    }

    & .exhibitor-bookmark{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  
`

const ExhibitorTileLogo = styled.div`
  width: 70%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  & img { 
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const VisitExhibitorLink = styled.span`
  font-family: ${branding.font1};
  display: inline-block; 
  text-decoration: none; 
  position: absolute;
  width: 80%;
  z-index: 1;
  bottom: 10px; 
  left: 50%;
  transform: translateX(-50%);
  color: ${branding.receptionPage.myFairBookmarkTileTextColor};
  font-size: 16px;
  text-align: center;
`

export enum CalendarEntrySortType {
    ALL,
    PAST,
    FUTURE
}

interface MyPageContentProps {
    eventKey: string
    guestBannerHeight: number
    showBookmarkEventsModal: boolean
    isMobile: boolean
    onViewModeSet: ((value: CalendarEntryModalViewMode) => void)
    onMeetingModalRequestSet: React.Dispatch<React.SetStateAction<boolean>>
    onSetShowOrganizationModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetShowBookmarkEventsModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetTarget: React.Dispatch<React.SetStateAction<SimpleOrganization | undefined>> // for organization
    onSetTargetId: React.Dispatch<React.SetStateAction<string>> // for other entity types
    onSetLink: React.Dispatch<React.SetStateAction<string>>
    onSetType: React.Dispatch<React.SetStateAction<ShareTargetType | undefined>>
    onSelectedMeetingSet: React.Dispatch<React.SetStateAction<CalendarEntry | undefined>>
    onSetAction: React.Dispatch<React.SetStateAction<ActionType | undefined>>
    onSetShowPrivacyPolicyModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetShowGuestModal: React.Dispatch<React.SetStateAction<boolean>>
}

const MyPageContent: React.FunctionComponent<MyPageContentProps> = (props) => {
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()
    let userState = useLoggedInState()
    const favorites = useFavoriteState();
    const nav = useNavigationSource()

    const [eventDateOpened, setEventDateOpened] = useState(false);
    const [eventDates, setEventDates] = useState<EventDate[]>([]);
    const [selectedMeetingId, setSelectedMeetingId] = useState("");
    const [meetings, setMeetings] = useState<CalendarEntry[]>([]);
    let [all, setAll]: Array<any> = useState([]); //meetings + eventdates
    const [personsBookmarks, setPersonsBookmarks] = useState<Person[]>([])
    const [showfloorsBookmarks, setShowfloorsBookmarks] = useState<Exhibitor[]>([])
    const [productsBookmarks, setProductsBookmarks] = useState<Product[]>([])
    const [trademarksBookmarks, setTrademarksBookmarks] = useState<Trademark[]>([])
    const [myPageExhibitorSponsors, setMyPageExhibitorSponsors] = useState<Exhibitor[]>()
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const timezone = useAppState().timezone

    const [refreshKey, setRefreshKey] = useState(0);

    const eventDays = branding.eventTiming.eventDays
    const [selectedDay, setSelectedDay] = useState(0);

    const componentOrder: string[] = branding.receptionPage.myPageComponentOrder ?? ["SPONSORS", "UP_NEXT", "MY_SCHEDULE", "SPEAKERS", "EXHIBITORS", "PRODUCTS", "TRADEMARKS"]

    const _onEventDateOpenedCallback = () => {
        setEventDateOpened(!eventDateOpened);
    };

    const removeUnbookmarkedItem = (list: Array<any>, listSetter: React.Dispatch<React.SetStateAction<any>>, idToRemove: string) => {
        let listCopy = [...list]
        let indexOfElementToRemove = listCopy.findIndex(x => x.id === idToRemove)
        if (indexOfElementToRemove !== -1) {
            listCopy.splice(indexOfElementToRemove, 1)
            listSetter(listCopy)
        }
    }

    const loadPremiumOrStandardExhibitors = (basisPremium: number, numResultRows: number) => {
        let premiumStandarsShowfloorParams: any = {
            'filterlist': 'entity_orga',
            'basispremium': basisPremium,
            'startresultrow': 0,
            'numresultrows': numResultRows,
            'order': 'random',
            'lang': lang
        }

        loadExhibitorsData(premiumStandarsShowfloorParams, abortSignal).then((resp) => {
            const ex = resp.entities as Exhibitor[]
            const respData = ex.filter(it => !branding.receptionPage.sponsoredExhibitorsMyPageList.some(e => e.id === it.id))
            setExhibitors(exhibitors.length === 0 ? respData : exhibitors.concat(respData))
            setIsLoaded(true)
        })
    }


    useEffect(() => {
        const indexToday = eventDays.findIndex(day => isToday(moment(day)))
        setSelectedDay(indexToday === -1 ? 0 : indexToday)
    }, [eventDays])

    useEffect(() => {
        let upNextFavoriteEventsParams: any = {
            'filterlist': [favorites.get("eventdate")],
            'startresultrow': 0,
            'numresultrows': 50,
            'order': 'chrono',
            'day': eventDays[selectedDay],
            'lang': lang,
            "future": "true"
        }
        let defaultPersonBookmarksParams: any = {
            'personIds': [favorites.get("person", true)],
            'personFunctions': branding.configuration.speakerPersonFunctions,
            'eventDateParticipation': branding.configuration.eventDateParticipation,
            'startresultrow': 0,
            'numresultrows': 15,
            'order': 'lexic',
            'lang': lang
        }

        let defaultShowfloorBookmarkParams: any = {
            'filterlist': ['entity_orga', favorites.get("organization")],
            'startresultrow': 0,
            'numresultrows': 15,
            'order': 'lexic',
            'lang': lang
        }

        let defaultProductsBookmarkParams: any = {
            'filterlist': ['entity_prod', favorites.get("product")],
            'startresultrow': 0,
            'numresultrows': 15,
            'order': 'lexic',
            'lang': lang
        }

        let defaultTrademarksBookmarkParams: any = {
            'filterlist': ['entity_trad', favorites.get("trademark")],
            'startresultrow': 0,
            'numresultrows': 15,
            'order': 'lexic',
            'lang': lang
        }


        if (favorites.get("eventdate").length > 0) {
            loadEventDateList(upNextFavoriteEventsParams, abortController.signal).then((response) => {
                setEventDates(response.eventDates);
            })
        }
        if (favorites.get("person").length > 0) {
            loadEventSpeakersData(defaultPersonBookmarksParams, abortSignal).then((response) => {
                setPersonsBookmarks(response.persons)
            })
        }
        if (favorites.get("organization").length > 0) {
            loadExhibitorsData(defaultShowfloorBookmarkParams, abortSignal).then((resp) => {
                setShowfloorsBookmarks(resp.entities as Exhibitor[])
            })
        }
        if (favorites.get("product").length > 0) {
            loadProductsData(defaultProductsBookmarkParams, abortSignal).then((resp) => {
                setProductsBookmarks(resp.products)
            })
        }
        if (favorites.get("trademark").length > 0) {
            loadTrademarksData(defaultTrademarksBookmarkParams, abortSignal).then((resp) => {
                setTrademarksBookmarks(resp.trademarks)
            })
        }

        getCalendarEntries(userState.user()?.profileId!, CalendarEntrySortType.ALL, CalendarEntryParticipationStatus.ACCEPTED, undefined).then((resp) => {
            let temp: CalendarEntry[] = []
            if (resp)
                resp.items.forEach(x => {
                    temp.push(x.calendarEntry);
                });
            setMeetings(temp);
        })

        const sponsoredExhibitorsMyPageList = branding.receptionPage.sponsoredExhibitorsMyPageList;
        let sponsoredExhibitorsFilterList: string[] = [];

        if (sponsoredExhibitorsMyPageList) {
            sponsoredExhibitorsMyPageList.forEach(orga => {
                sponsoredExhibitorsFilterList.push('id_orga_' + orga.id);
            });

            let sponsoredExhibitorsParams: any = {
                'filterlist': 'entity_orga, ' + sponsoredExhibitorsFilterList.join(', '),
                'startresultrow': 0,
                'order': 'lexic',
                'lang': lang
            }
            loadExhibitorsData(sponsoredExhibitorsParams, abortSignal).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                if (ex) {
                    ex.forEach(exhibitor => {
                        exhibitor.order = sponsoredExhibitorsMyPageList.find(x => x.id === exhibitor.id)?.order || -1
                    });

                    setMyPageExhibitorSponsors((e) => orderBy(ex, ['order'], ['asc']))
                }
            })

            //loading premium exhibitors for top sponsors section
            loadPremiumOrStandardExhibitors(2, branding.receptionPage.lobbyExhibitorsPremiumNumber)
        }


        setRefreshKey(0);

        return () => {
            abortController.abort();
        }
        // Load the stuff only if we are logged in …
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eventKey, lang, refreshKey, timezone])


    useEffect(() => {
        if (isLoaded && branding.receptionPage.lobbyExhibitorsBasisPremiumTypes.find((e: string) => e === BasisPremiumType.STANDARD)) {
            //loading standard exhibitors for top sponsors section
            loadPremiumOrStandardExhibitors(1, branding.receptionPage.lobbyExhibitorsStandardNumber)
        }

        //eslint-disable-next-line
    }, [isLoaded])

    useEffect(() => {
        let temp: Array<any> = [];
        let currentTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).format('HH:mm')
        let [currentHour, currentMinutes] = currentTime.split(':')
        let currentTimeStamp = parseInt(currentHour) * 60 + parseInt(currentMinutes)
        if (eventDates) {

            eventDates.forEach(x => {
                if ((x.endHour * 60 + x.endMinutes) > currentTimeStamp) {
                    temp.push({
                        type: "eventdate",
                        object: x
                    });
                }
            });
        }

        if (meetings) {
            meetings.forEach(x => {
                let date = momentWithoutTimezoneFromTimezonedMoment(moment(x.end), timezone).format('HH:mm')
                let [dateHours, dateMinutes] = date.split(':')
                let dateTimeStamp = parseInt(dateHours) * 60 + parseInt(dateMinutes)
                if (moment(x.start).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && dateTimeStamp > currentTimeStamp) {
                    temp.push({
                        type: "meeting",
                        object: x
                    })
                }
            })
        };

        setAll(temp);
    }, [eventDates, meetings, props.eventKey]) // eslint-disable-line

    useEffect(() => {
        if (selectedMeetingId)
            props.onSelectedMeetingSet(meetings.filter(x => x.id === selectedMeetingId)[0])
        // eslint-disable-next-line
    }, [selectedMeetingId])

    useEffect(() => {
        if (selectedMeetingId) {
            const calendarEntry = meetings.filter(x => x.id === selectedMeetingId)[0]
            const isFuture = (moment(calendarEntry.start).toDate().getFullYear() === moment().year() && moment(moment(calendarEntry.start).toDate()).dayOfYear() >= moment().dayOfYear())
            const isOwner = calendarEntry.userId === userState.user()?.profileId
            props.onViewModeSet(isFuture && isOwner ? CalendarEntryModalViewMode.EDIT : CalendarEntryModalViewMode.VIEW)
        }
    }, [selectedMeetingId]) //eslint-disable-line

    const showMySchedule = eventDates.length > 0 || meetings.length > 0

    return (
        <Fragment>
            {componentOrder.map((component, outerKey) => {
                switch (component) {
                    case "SPONSORS": return (
                        <>
                            {
                                myPageExhibitorSponsors && myPageExhibitorSponsors?.length! > 0 &&
                                <TileRow key={outerKey}
                                    icon={vgCustomIconSponsor({ fill: branding.sideIconBar.sideIconColorDark })}
                                    iconVisible={true}
                                    title={strings.receptionPage.sponsorsTitle}
                                    titleVisible={true}
                                    navLink={showfloorPageRoute}
                                    navLinkTextVisible={true}
                                    navLinkText={strings.receptionPage.sponsorsTitle}
                                    height="220px"
                                    scrollBar={myPageExhibitorSponsors?.length! < 3 && !branding.receptionPage.showPremiumExhibitorsWithSponsors ? false : true}
                                    childWidth={props.isMobile ? (205 + 4) : (500 + 4)} /* Basis + Grid Gap */
                                    childCount={branding.receptionPage.showPremiumExhibitorsWithSponsors ? (myPageExhibitorSponsors?.length ?? 0) + (exhibitors?.length ?? 0) : myPageExhibitorSponsors?.length ?? 0}
                                >
                                    {myPageExhibitorSponsors.map((sponsor, index) =>
                                        <Showfloor
                                            key={sponsor.id}
                                            backgroundUrl={sponsor.backgroundImageURL}
                                            exhibitor={sponsor}
                                            src="MYPAGESPONSOR"
                                            isMobile={props.isMobile}
                                            setViewMode={props.onViewModeSet}
                                            onSetAction={props.onSetAction}
                                            onSetShowPrivacyPolicyModal={props.onSetShowPrivacyPolicyModal}
                                            onSetShowGuestModal={props.onSetShowGuestModal}
                                            onSetTarget={props.onSetTarget}
                                            onSetLink={props.onSetLink}
                                            onSetShowOrganizationModal={props.onSetShowOrganizationModal}
                                            onSetType={props.onSetType}
                                        />
                                    )}
                                    {branding.receptionPage.showPremiumExhibitorsWithSponsors &&
                                        exhibitors && exhibitors.map((showfloor, index) =>
                                            <VizSensor key={index} partialVisibility={true}>
                                                {({ isVisible }) => isVisible ?
                                                    <Showfloor
                                                        key={showfloor.id}
                                                        backgroundUrl={showfloor.backgroundImageURL}
                                                        exhibitor={showfloor}
                                                        src="MYPAGE"
                                                        isMobile={props.isMobile}
                                                        setViewMode={props.onViewModeSet}
                                                        onSetAction={props.onSetAction}
                                                        onSetShowPrivacyPolicyModal={props.onSetShowPrivacyPolicyModal}
                                                        onSetShowGuestModal={props.onSetShowGuestModal}
                                                        onSetTarget={props.onSetTarget}
                                                        onSetLink={props.onSetLink}
                                                        onSetShowOrganizationModal={props.onSetShowOrganizationModal}
                                                        onSetType={props.onSetType}
                                                    />
                                                    : <div style={{ width: 500, height: 280 }}></div>
                                                }
                                            </VizSensor>
                                        )}
                                </TileRow>
                            }
                        </>
                    )
                    case "UP_NEXT": return (all.length > 0 &&
                        /* Up next section */
                        <TileRow key={outerKey}
                            icon={IconSchedule({ fill: branding.sideIconBar.sideIconColorDark })}
                            iconVisible={true}
                            title={strings.receptionPage.upNextTitle}
                            titleVisible={true}
                            navLink={mySchedulePageRoute}
                            navLinkTextVisible={true}
                            navLinkText={strings.receptionPage.viewMySchedule}
                            height={props.isMobile ? "95px" : "220px"}
                            scrollBar={false}
                            childCount={props.isMobile ? all.length : 1}
                            childWidth={props.isMobile ? (205 + 4) : 0}>
                            <UpNextWrapper>
                                {
                                    all.slice(0, (props.isMobile ? all.length : 2)).map((eventDate: any) => {
                                        return <EventDateEntry
                                            key={eventDate.id}
                                            eventDate={eventDate.object}
                                            myScheduleObject={eventDate.object}
                                            marginTop={10}
                                            height={280}
                                            eventDateOpenedCallback={_onEventDateOpenedCallback}
                                            onBookmarkClick={(id: string) => { removeUnbookmarkedItem(eventDates, setEventDates, id) }}
                                            mySchedule={eventDate.object.hasOwnProperty("title")}
                                            upcoming={true}
                                            setViewMode={props.onViewModeSet}
                                            setMeetingId={setSelectedMeetingId}
                                            setShowRequestMeetingModal={props.onMeetingModalRequestSet}
                                            isMobile={props.isMobile} />
                                    }
                                    )
                                }
                            </UpNextWrapper>
                        </TileRow>)
                    case "MY_SCHEDULE": return (
                        /* My schedule */
                        <>
                            {props.isMobile ? null :
                                <MyScheduleContainer key={outerKey}>
                                    <TileRow
                                        icon={IconMeetingSchedule({ stroke: branding.sideIconBar.sideIconColorDark, fill: branding.sideIconBar.sideIconColorDark })}
                                        iconVisible={true}
                                        title={strings.receptionPage.mySchedule}
                                        titleVisible={true}
                                        navLink={showMySchedule ? mySchedulePageRoute : programPageRoute}
                                        navLinkText={showMySchedule ? strings.receptionPage.viewMySchedule : strings.receptionPage.liveSessionsNavLinkText}
                                        navLinkTextVisible={true}
                                        scrollBar={false}
                                        childCount={1}
                                        childWidth={0}>
                                        {showMySchedule ?
                                            <MyScheduleRoot>
                                                <Schedule
                                                    roundScroller={true} onlyFavorites={true} dateColumns={true} dateColumnsDays={[]} onScroll={() => { }}
                                                    isInMyFairPage={true}
                                                    listViewMode={false}
                                                    mySchedule={true} myScheduleResponsive={true} day={selectedDay}
                                                    guestBannerHeight={props.guestBannerHeight} />
                                            </MyScheduleRoot> :
                                            <EmptyTile header={strings.receptionPage.noEventsScheduled} buttonNavLink={programPageRoute} buttonMessage={strings.receptionPage.liveSessionsNavLinkText} hideButton={true} bgColor="#F2F2F2" />}
                                    </TileRow>
                                </MyScheduleContainer>
                            }
                        </>
                    )
                    case "SPEAKERS": return (
                        /* Bookmarked speakers */
                        <TileRow key={outerKey}
                            icon={IconSpeakers({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                            iconVisible={true}
                            title={strings.receptionPage.speakers}
                            titleVisible={true}
                            navLink={personsBookmarks.length > 0 ? bookmarkedSpeakersPageRoute : speakersPageRoute}
                            navLinkText={personsBookmarks.length > 0 ? strings.receptionPage.speakersBookmarkedNavLinkText : strings.receptionPage.speakersNavLinkText}
                            navLinkTextVisible={true}
                            scrollBar={personsBookmarks && personsBookmarks.length > 0}
                            isMyFairPage
                            childWidth={280 + 20}
                            childCount={personsBookmarks?.length ?? 0}
                        >
                            {personsBookmarks.length > 0 ?
                                <>
                                    {props.isMobile ?
                                        <MobileTileWrapperSpeakers $count={personsBookmarks.length}>
                                            {personsBookmarks.map((person, index) =>
                                                <VizSensor key={person.id} partialVisibility={true}>
                                                    {({ isVisible }) => isVisible ?
                                                        <div style={{ width: 95, height: 95 }}>
                                                            <PersonComponent key={person.id} {...person}
                                                                setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                setTargetId={props.onSetTargetId}
                                                                setLink={props.onSetLink}
                                                                setType={props.onSetType}
                                                                position={person.position}
                                                                company={person.organization}
                                                                modalOpen={props.showBookmarkEventsModal}
                                                                onToggle={(id: string) => { removeUnbookmarkedItem(personsBookmarks, setPersonsBookmarks, id) }}
                                                                customOnBookmarkedFunction={() => {
                                                                    props.onSetTargetId(person.id)
                                                                    props.onSetShowBookmarkEventsModal(true)
                                                                }}
                                                                componentType={"person"}></PersonComponent>
                                                        </div>
                                                        : <div style={{ width: 95, height: 95 }}></div>
                                                    }
                                                </VizSensor>
                                            )}
                                        </MobileTileWrapperSpeakers> :
                                        <TileWrapperSpeakers $count={personsBookmarks.length}>
                                            {personsBookmarks.map((person, index) =>
                                                <VizSensor key={index} partialVisibility={true}>
                                                    {({ isVisible }) => isVisible ?
                                                        <div style={{ width: 280, height: 280 }}>
                                                            <PersonComponent key={person.id} {...person}
                                                                setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                setTargetId={props.onSetTargetId}
                                                                setLink={props.onSetLink}
                                                                setType={props.onSetType}
                                                                position={person.position}
                                                                company={person.organization}
                                                                modalOpen={props.showBookmarkEventsModal}
                                                                onToggle={(id: string) => { removeUnbookmarkedItem(personsBookmarks, setPersonsBookmarks, id) }}
                                                                customOnBookmarkedFunction={() => {
                                                                    props.onSetTargetId(person.id)
                                                                    props.onSetShowBookmarkEventsModal(true)
                                                                }}
                                                                componentType={"person"}></PersonComponent>
                                                        </div>
                                                        : <div style={{ width: 280, height: 280 }}></div>
                                                    }
                                                </VizSensor>
                                            )}
                                        </TileWrapperSpeakers>
                                    }
                                </> :
                                <EmptyTile isMobile={props.isMobile} header={strings.receptionPage.noBookmarkedSpeakers} buttonNavLink={speakersPageRoute} buttonMessage={strings.receptionPage.speakersNavLinkText} hideButton={true} bgColor="#F2F2F2" />}
                        </TileRow>
                    )
                    case "EXHIBITORS": return (
                        /* Bookmarked exhibitors */
                        <TileRow key={outerKey}
                            icon={IconOrganization({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                            iconVisible={true}
                            title={strings.receptionPage.exhibitors}
                            titleVisible={true}
                            navLink={showfloorsBookmarks.length > 0 ? bookmarkedshowfloorPageRoute : showfloorPageRoute}
                            navLinkText={showfloorsBookmarks.length > 0 ? strings.receptionPage.showFloorBookmarkedNavLinkText : strings.receptionPage.showFloorNavLinkText}
                            navLinkTextVisible={true}
                            height={props.isMobile ? "95px" : "250px"}
                            scrollBar={showfloorsBookmarks && showfloorsBookmarks.length > 0}
                            isMyFairPage
                            childWidth={props.isMobile ? 95 : 250}
                            childCount={showfloorsBookmarks?.length ?? 0}
                        >
                            {showfloorsBookmarks.length > 0 ?
                                showfloorsBookmarks.map((exhibitor, index) =>
                                    <>
                                        {props.isMobile ?
                                            <VizSensor key={index} partialVisibility={true}>
                                                {({ isVisible }) => isVisible ?
                                                    <MyFairPageTileMobile onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(exhibitor.id, exhibitor.name, "organization")}>
                                                        {<OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                                    {branding.organizationDetailPageContent.bookmarkPerson}
                                                                </Tooltip>}>
                                                            <div className={"exhibitor-bookmark"}><BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(showfloorsBookmarks, setShowfloorsBookmarks, id) }} className={'show-on-hover'} newBookmarkItem={true} type={"organization"} id={exhibitor.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle></div></OverlayTrigger>}
                                                        <ExhibitorTileLogo>
                                                            {
                                                                exhibitor.pictureURL ?
                                                                    <img src={exhibitor.pictureURL} alt={exhibitor.name + ' logo'} /> :
                                                                    <p>{(exhibitor.name).replace(/(.{80})..+/, "$1…")}</p>
                                                            }
                                                        </ExhibitorTileLogo>
                                                        <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitExhibitorText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "80%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                    </MyFairPageTileMobile>
                                                    : <div style={{ width: 95, height: 95 }}></div>
                                                }
                                            </VizSensor> :
                                            <VizSensor key={index} partialVisibility={true}>
                                                {({ isVisible }) => isVisible ?
                                                    <ExhibitorTile onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(exhibitor.id, exhibitor.name, "organization")}>
                                                        {<OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                                    {branding.organizationDetailPageContent.bookmarkPerson}
                                                                </Tooltip>}>
                                                            <div className={"exhibitor-bookmark"}><BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(showfloorsBookmarks, setShowfloorsBookmarks, id) }} className={'show-on-hover'} newBookmarkItem={true} type={"organization"} id={exhibitor.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle></div></OverlayTrigger>}
                                                        <ExhibitorTileLogo>
                                                            {
                                                                exhibitor.pictureURL ?
                                                                    <img src={exhibitor.pictureURL} alt={exhibitor.name + ' logo'} /> :
                                                                    <p>{(exhibitor.name).replace(/(.{80})..+/, "$1…")}</p>
                                                            }
                                                        </ExhibitorTileLogo>
                                                        <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitExhibitorText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "80%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                    </ExhibitorTile>
                                                    : <div style={{ width: 250, height: 250 }}></div>
                                                }
                                            </VizSensor>
                                        }
                                    </>
                                )
                                :
                                <EmptyTile isMobile={props.isMobile} header={strings.receptionPage.noBookmarkedExhibitors} buttonNavLink={showfloorPageRoute} buttonMessage={strings.receptionPage.showFloorNavLinkText} hideButton={true} bgColor="#F2F2F2" />
                            }
                        </TileRow>
                    )
                    case "PRODUCTS": return (
                        /* Bookmarked products */
                        <>
                            {
                                productsBookmarks.length > 0 &&
                                <TileRow key={outerKey}
                                    icon={IconOrganization({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.navigationArea.productsItemTitle}
                                    titleVisible={true}
                                    navLink={productsPageRoute}
                                    navLinkText={strings.receptionPage.productsNavLinkText}
                                    navLinkTextVisible={true}
                                    height={props.isMobile ? "95px" : "280px"}
                                    scrollBar={productsBookmarks && productsBookmarks.length > 0}
                                    isMyFairPage
                                    childWidth={props.isMobile ? 95 : 500}
                                    childCount={productsBookmarks?.length ?? 0}
                                >
                                    {productsBookmarks.map((product, index) =>
                                        <>
                                            {
                                                props.isMobile ?
                                                    <VizSensor key={index} partialVisibility={true}>
                                                        {({ isVisible }) => isVisible ?
                                                            <MyFairPageTileMobile onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(product.id, product.name, "product")}>
                                                                <BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(productsBookmarks, setProductsBookmarks, id) }} className={'show-on-hover exhibitor-bookmark'} newBookmarkItem={true} type={"product"} id={product.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle>
                                                                <ExhibitorTileLogo>
                                                                    {
                                                                        product.pictureURL ?
                                                                            <img src={product.pictureURL} alt={product.name + ' logo'} /> :
                                                                            <p>{(product.name).replace(/(.{80})..+/, "$1…")}</p>
                                                                    }
                                                                </ExhibitorTileLogo>
                                                                <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitProductText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "80%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                            </MyFairPageTileMobile>
                                                            : <div style={{ width: 95, height: 95 }}></div>
                                                        }
                                                    </VizSensor> :

                                                    <VizSensor key={index} partialVisibility={true}>
                                                        {({ isVisible }) => isVisible ?
                                                            <ProductTile onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(product.id, product.name, "product")}>
                                                                <BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(productsBookmarks, setProductsBookmarks, id) }} className={'show-on-hover exhibitor-bookmark'} newBookmarkItem={true} type={"product"} id={product.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle>
                                                                <ExhibitorTileLogo>
                                                                    {
                                                                        product.pictureURL ?
                                                                            <img src={product.pictureURL} alt={product.name + ' logo'} /> :
                                                                            <p>{(product.name).replace(/(.{80})..+/, "$1…")}</p>
                                                                    }
                                                                </ExhibitorTileLogo>
                                                                <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitProductText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "63%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                            </ProductTile>
                                                            : <div style={{ width: 500, height: 280 }}></div>
                                                        }
                                                    </VizSensor>
                                            }
                                        </>
                                    )}
                                </TileRow>
                            }
                        </>
                    )
                    case "TRADEMARKS": return (
                        /* Bookmarked trademarks */
                        <>
                            {
                                trademarksBookmarks.length > 0 &&
                                <TileRow key={outerKey}
                                    icon={IconOrganization({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.navigationArea.trademarksItemTitle}
                                    titleVisible={true}
                                    navLink={trademarksPageRoute}
                                    navLinkText={strings.receptionPage.trademarksNavLinkText}
                                    navLinkTextVisible={true}
                                    height={props.isMobile ? "95px" : "220px"}
                                    scrollBar={trademarksBookmarks && trademarksBookmarks.length > 0}
                                    isMyFairPage
                                    childWidth={props.isMobile ? 95 : 250}
                                    childCount={trademarksBookmarks.length}
                                >
                                    {trademarksBookmarks.map((trademark, index) =>
                                        <>
                                            {
                                                props.isMobile ?
                                                    <VizSensor key={index} partialVisibility={true}>
                                                        {({ isVisible }) => isVisible ?
                                                            <MyFairPageTileMobile onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(trademark.id, trademark.name, "trademark")}>
                                                                <BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(trademarksBookmarks, setTrademarksBookmarks, id) }} className={'show-on-hover exhibitor-bookmark'} newBookmarkItem={true} type={"trademark"} id={trademark.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle>
                                                                <ExhibitorTileLogo>
                                                                    {
                                                                        trademark.pictureURL ?
                                                                            <img src={trademark.pictureURL} alt={trademark.name + ' logo'} /> :
                                                                            <p>{(trademark.name).replace(/(.{80})..+/, "$1…")}</p>
                                                                    }
                                                                </ExhibitorTileLogo>
                                                                <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitTrademarkText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "80%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                            </MyFairPageTileMobile>
                                                            : <div style={{ width: 95, height: 95 }}></div>
                                                        }
                                                    </VizSensor> :

                                                    <VizSensor key={index} partialVisibility={true}>
                                                        {({ isVisible }) => isVisible ?
                                                            <ExhibitorTileSmall onClick={() => nav.set('LOBBY')} key={index} to={buildDetailLink(trademark.id, trademark.name, "trademark")}>
                                                                <BookmarkWithToggle onToggle={(id: string) => { removeUnbookmarkedItem(trademarksBookmarks, setTrademarksBookmarks, id) }} className={'show-on-hover exhibitor-bookmark'} newBookmarkItem={true} type={"trademark"} id={trademark.id} color={branding.receptionPage.myFairBookmarkTileIconColor} stroke={branding.receptionPage.myFairBookmarkTileIconColor}></BookmarkWithToggle>
                                                                <ExhibitorTileLogo>
                                                                    {
                                                                        trademark.pictureURL ?
                                                                            <img src={trademark.pictureURL} alt={trademark.name + ' logo'} /> :
                                                                            <p>{(trademark.name).replace(/(.{80})..+/, "$1…")}</p>
                                                                    }
                                                                </ExhibitorTileLogo>
                                                                <VisitExhibitorLink className={'show-on-hover'}>{strings.receptionPage.visitTrademarkText}</VisitExhibitorLink><VisitExhibitorLink className={'show-on-hover'} style={{ left: "80%" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.myFairBookmarkTileTextColor })}</VisitExhibitorLink>
                                                            </ExhibitorTileSmall>
                                                            : <div style={{ width: 200, height: 200 }}></div>
                                                        }
                                                    </VizSensor>
                                            }
                                        </>
                                    )}
                                </TileRow>
                            }
                        </>

                    )
                    default: return null
                }
            })}
        </Fragment >
    );
}

export default MyPageContent;