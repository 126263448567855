import React, { useState } from 'react'
import styled from 'styled-components';
import branding from '../branding/branding';
import { IconLock, VGIcon } from './Icons';
import { DetailNavLink, buildDetailLink } from '../contentArea/detailPages/DetailNavLink';
import { NavLink } from 'react-router-dom';
import { useUserEventAccess } from '../globalStates/UserEventAccess';
import { useLoggedInState, User } from '../globalStates/LoggedInUser';
import { RestrictedAreaTypes, UserRestrictedAreaAccess } from '../backendServices/BackendServices';
import { useLanguageState } from '../globalStates/LanguageState';
import { EventDate } from '../backendServices/Types';
import { hasAccessToOrganization } from '../utils/Authorization';
import CenteredLoader from './CenteredLoader';
import RequestAccessModal from './RequestAccessModal';



const LiveTileRoot = styled(NavLink) <{ unlocked?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 270px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-radius: 5px;
    overflow: hidden;
    ${props => props.unlocked ? "cursor: pointer;" : "pointer-events: none; cursor: default;"};
    ${props => props.unlocked ? "" : "background-color: grey"};


    &:after {
      border-radius: 5px;
    }

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
    }

    & h2 {
      font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 10px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`

const MobileLiveTileRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 270px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-radius: 5px;
    overflow: hidden;

    &:after {
      border-radius: 5px;
    }

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
    }

    & h2 {
      font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 5px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`

const LiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  padding: 15px;
  width: 100%;
  color: white;
  z-index: 2;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;
`

const MobileLiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-left: 5px;
  padding-top: 5px;
  overflow-y: hidden;
  width: 100%;
  color: white;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
`

const LiveTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 480px;
  font-family: ${branding.font1};
`

const MobileLiveTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-bottom: -20px;
`

const LockedEvent = styled.div`
    width: "480px"; 
    min-width: "480px";
    height: "270px";
    cursor: "pointer"; 
    border-radius: "5px";
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    
`

const LockedText = styled.div`
  color: #FFF !important;
  margin-top: 20px;
  text-align: center;

  &:active, &:link, &:focus {
    color: #FFF !important;
  }
`

const ActionButtonDiv = styled.div<{ disabled?: boolean }>`

    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    ${props => props.disabled ? "pointer-events: none; cursor: default;" : "cursor: pointer;"}
    line-height: 17px;
    font-family: ${branding.font1};


        width: 140px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 50px;
        border: 1px solid white;
        color: black;
        background-color: white;
        z-index: 1000;
        font-size: ${branding.receptionPage.virtualCafeAccessButtonFontSize ?? "12px"};

        :hover {
            color: ${props => props.disabled ? "#000" : "#FFF"};
            background-color: ${props => props.disabled ? "none" : "rgba(0, 0, 0, .2)"};
            text-decoration: none;
        }

`
const MoreContactsText = styled.p`
    margin-bottom: 0rem;
`;

const CustomCenteredLoader = styled(CenteredLoader)`
  position: absolute;
  left: 150px;
  bottom: 120px;
  z-index: 1;
`

interface LiveEventDateProps {
  id: string
  title: string
  channelId: string
  channelIcon: VGIcon
  thumbnailUrl: string
  isMobile?: boolean
  eventDate?: EventDate
  setShowGuestModal?: (value: boolean) => void
}

function checkUserSessionAccess(eventDate?: EventDate, logedUser?: User, userAccesList?: UserRestrictedAreaAccess[]) {
  if (eventDate === undefined)
    return true

  return (!eventDate?.isPrivate)
    || logedUser?.organizations?.find(o => o.id === eventDate?.organizationId) !== undefined
    || logedUser?.eventDates?.find(o => o.id === eventDate?.id) !== undefined
    || userAccesList?.find(access => access.restrictedAreaId === eventDate?.id)?.status === "GRANTED"
}

const LiveEvent: React.FunctionComponent<LiveEventDateProps> = (props) => {

  const userAccesList = useUserEventAccess().userEventDateAccessList
  const strings = useLanguageState().getStrings()
  const logedUser = useLoggedInState().user()
  const unlocked = checkUserSessionAccess(props.eventDate, logedUser, userAccesList)
  const [userRequestAccessLoaded, setUserRequestAccessLoaded] = useState<boolean>(true)
  const [userEventAccess, setUserEventAccess] = useState<UserRestrictedAreaAccess>()
  const [showRequestAccessModal, setShowRequestAccessModal] = useState<boolean>(false)



  const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.setShowGuestModal && (!logedUser || (props.eventDate?.organizationId && !hasAccessToOrganization(logedUser, props.eventDate.organizationId)))) {
      props.setShowGuestModal(true)
      return
    }

    setShowRequestAccessModal(true)

  }

  if (props.isMobile) {
    return <MobileLiveTileWrapper>
      <MobileLiveTileRoot to={buildDetailLink(props.channelId, props.title, "channel")} style={{ height: "95px" }}>
        <DetailNavLink type="channel" id={props.channelId} name={props.title}>
          <MobileLiveTileHeader style={{ fontSize: "10px", lineHeight: "17px" }}>
            <div>{props.channelIcon}</div>
            <h2>{props.title}</h2>
          </MobileLiveTileHeader>
          <img src={props.thumbnailUrl} style={{ width: "135px", minWidth: "135px", height: "95px", cursor: "pointer", borderRadius: "5px" }} alt="" />
        </DetailNavLink>
      </MobileLiveTileRoot>
    </MobileLiveTileWrapper>
  }

  let content = null
  let actionButton = null

  if (userRequestAccessLoaded) {
    if (userEventAccess?.status === "REQUESTED" || userAccesList?.find(access => access.restrictedAreaId === props.eventDate?.id)?.status === "REQUESTED") {
      actionButton = (<ActionButtonDiv disabled={true}>
        <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestSent}</MoreContactsText></ActionButtonDiv>)
    } else {
      actionButton = (<ActionButtonDiv onClick={onRequestAccessClick} disabled={false}>
        <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestAccess}</MoreContactsText></ActionButtonDiv>)
    }
  } else {
    actionButton = <CustomCenteredLoader size={"xs"} />
  }

  if (unlocked || props.setShowGuestModal === undefined) {
    content = <img src={props.thumbnailUrl} style={{ width: "480px", minWidth: "480px", height: "270px", cursor: "pointer", borderRadius: "5px" }} alt="" />
  } else {
    content = <LockedEvent>
      {IconLock({ width: "50", height: "50", fill: "#fff" })}
      <LockedText >{strings.globalTexts.noAccessMessage + " " + props.eventDate?.name}</LockedText>
    </LockedEvent>
  }

  return <LiveTileWrapper>
    {!unlocked && actionButton}
    <LiveTileRoot to={"#"} unlocked={unlocked}>
      <DetailNavLink type="channel" id={props.channelId} name={props.title}>
        <LiveTileHeader>
          <div>{props.channelIcon}</div>
          <h2>{props.title}</h2>
        </LiveTileHeader>
        {content}
      </DetailNavLink>
    </LiveTileRoot>
    <div style={{ width: "95%" }}>
      {props.eventDate?.name || <div style={{ height: '30px' }}></div>}
    </div>
    {showRequestAccessModal && props.eventDate &&
      <RequestAccessModal
        onUserRequestLoadedSet={setUserRequestAccessLoaded}
        onUserRestrictedAreaAccessSet={setUserEventAccess}
        restrictedAreaId={props.eventDate.id}
        restrictedAreaType={RestrictedAreaTypes.PrivateEvent}
        onHide={() => { setShowRequestAccessModal(false) }}>
      </RequestAccessModal>}
  </LiveTileWrapper>
}

export default LiveEvent;