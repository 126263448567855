import React from "react"
import Draggable from "react-draggable"
import styled from "styled-components"
import { IconClose, IconLock } from "../../ui/Icons"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useHistory } from "react-router-dom"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import { PipPlayerPosition, PipPlayerSize } from "./PictureInPictureVideoPlayer"

export interface PictureInPictureDummyPlayerRootProps {
    initialWidth: string,
    initialHeight: string
}

const PictureInPictureDummyPlayerRoot = styled.div<PictureInPictureDummyPlayerRootProps>`
    ${props => `width: ${props.initialWidth}`};
    ${props => `height: ${props.initialHeight}`};
    position: absolute;
    z-index: 100;
    background: #727272;
    cursor: grab;
`

const CloseIconRoot = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 101;
    cursor: pointer;
`
const LockIconAndTextRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    font-size: 20px;
    text-align: center;
    font-family: ${branding.font1};
`
const LockIconRoot = styled.div`
    width: 3vw;
    height: 3vw;
    // this is necessary for Draggable.onStop() to make sure player position is saved even if lock icon is clicked for dragging
    pointer-events: none;
`

export interface PictureInPictureVideoPlayerDummyProps {
    setPipPlayerPosition(pipPlayerPosition: PipPlayerPosition): void
    pipPlayerPosition: PipPlayerPosition
    setPipPlayerSize(pipPlayerSize: PipPlayerSize): void
    pipPlayerSize: PipPlayerSize
}

const PictureInPictureVideoPlayerDummy: React.FC<PictureInPictureVideoPlayerDummyProps> = (props) => {
    const appState = useAppState()
    const history = useHistory()
    const strings = useLanguageState().getStrings()

    return (
        <Draggable
            // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
            defaultPosition={{ x: props.pipPlayerPosition.x, y: props.pipPlayerPosition.y }}
            onStop={(e, data) => {
                // make sure calling props.setPipPlayerPosition() doesn't prevent close button from working
                if (e instanceof MouseEvent) {
                    const me = e as MouseEvent
                    if (me.target) {
                        const target = me.target as any
                        if (target.tagName.toLowerCase() !== "path") {
                            props.setPipPlayerPosition({ x: data.x, y: data.y })
                        }
                    }
                }
            }}
        >
            <PictureInPictureDummyPlayerRoot
                className={"picture-in-picture-player"}
                title={strings.videoPlayerBranding.pipDoubleClickHint}
                initialHeight={props.pipPlayerSize.height + "px"}
                initialWidth={props.pipPlayerSize.width + "px"}
                onDoubleClick={() => {
                    const channelId = appState.liveStreamChannel?.id
                    if (channelId)
                        history.push(buildDetailLink(channelId, "", 'channel'))
                }}>
                <CloseIconRoot title={strings.videoPlayerBranding.closeText} onClick={() => appState.setLiveStreamChannel(null)}>
                    <IconClose fill="#FFF" stroke="#FFF" />
                </CloseIconRoot>

                <LockIconAndTextRoot>
                    <LockIconRoot>
                        {IconLock({ width: "auto", height: "auto", fill: "#fff" })}
                    </LockIconRoot>
                    <div style={{ marginTop: "10px" }}>{strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessage}</div>
                </LockIconAndTextRoot>
            </PictureInPictureDummyPlayerRoot>
        </Draggable >
    )
}

export default PictureInPictureVideoPlayerDummy