import { ColorString, LocalizedString, PluralString } from "../../branding/branding";
import { SocialMediaType } from "../myprofile/MyProfilePageContentBranding";

export enum DetailSection {
  "DETAIL_INFO" = "DETAIL_INFO",
  "TEAM" = "TEAM",
  "LIVE_SESSIONS" = "LIVE_SESSIONS",
  "UPCOMING_SESSIONS" = "UPCOMING_SESSIONS",
  "PREVIOUS_SESSIONS" = "PREVIOUS_SESSIONS",
  "VIRTUAL_CAFES" = "VIRTUAL_CAFES",
  "PRODUCTS" = "PRODUCTS",
  "TRADEMARKS" = "TRADEMARKS",
  "NEWS" = "NEWS",
  "ORGANIZATIONS" = "ORGANIZATIONS",
  "COUPONS" = "COUPONS",
  "LINKS" = "LINKS",
  "MEDIA" = "MEDIA",
  "ADDRESS_PHONE_EMAIL" = "ADDRESS_PHONE_EMAIL",
  "COMPANY_DETAILS" = "COMPANY_DETAILS",
  "COMPANY_INFORMATION_TITLE" = "COMPANY_INFORMATION_TITLE",
  "CATEGORIES" = "CATEGORIES",
  "CO_EXHIBITORS" = "CO_EXHIBITORS",
  "PARENT" = "PARENT",
  "SHOWCASE" = "SHOWCASE",
  "REMOTE_LIVESTREAMS" = "REMOTE_LIVESTREAMS"
}

export enum InfoContentOrderType {
  "DESCRIPTION" = "DESCRIPTION",
  "SOCIAL_MEDIA" = "SOCIAL_MEDIA",
  "ADDRESS_PHONE_EMAIL" = "ADDRESS_PHONE_EMAIL",
  "TALK_WITH_TEAM" = "TALK_WITH_TEAM",
  "COMPANY_DETAILS" = "COMPANY_DETAILS",
  "CONFERENCE_ROOMS" = "CONFERENCE_ROOMS"
}

export interface OrganizationDetailPageContentBranding {
  /**
  * @title Show join showroom icon
  * @description Enable / disable visibility of join showroom icon
  * @title_de Show join showroom icon
  * @description_de Enable / disable visibility of join showroom icon
  */
  showJoinShowRoomIcon: boolean
  /**
  * @title Show schedule a meeting icon
  * @description Enable / disable visibility of schedule a meeting icon
  * @title_de Show schedule a meeting icon
  * @description_de Enable / disable visibility of schedule a meeting icon
  */
  showScheduleMeetingIcon: boolean
  /**
  * @title Show register interest icon
  * @description Enable / disable visibility of register interest icon
  * @title_de Show register interest icon
  * @description_de Enable / disable visibility of register interest icon
  */
  showRegisterInterestIcon: boolean
  /**
  * @title Show recommend icon
  * @description Enable / disable visibility of recommend icon
  * @title_de Show recommend icon
  * @description_de Enable / disable visibility of recommend icon
  */
  showRecommendIcon: boolean
  /**
  * @title Show bookmark icon
  * @description Enable / disable visibility of bookmark icon
  * @title_de Show bookmark icon
  * @description_de Enable / disable visibility of bookmark icon
  */
  showBookmarkIcon: boolean
  /**
  * @title Show download v card icon
  * @description Enable / disable visibility of download v card icon
  * @title_de Show download v card icon
  * @description_de Enable / disable visibility of download v card icon
  */
  showDownloadIcon: boolean
  /**
  * @title Show backoffice icon
  * @description Enable / disable visibility of backoffice icon
  * @title_de Show backoffice icon
  * @description_de Enable / disable visibility of backoffice icon
  */
  showBackofficeIcon: boolean

  /**
  * @title Navbar title
  * @description Text for section line contact person label
  * @title_de Navbar title
  * @description_de Text for company detail page title
  */
  navbarTitle: LocalizedString
  /**
  * @title Section line contact person
  * @description Text for section line contact person label
  * @title_de Section line contact person
  * @description_de Text for section line contact person label
  */
  sectionLineContactPerson: LocalizedString
  /**
  * @title Section line categories
  * @description Text for section line categories label
  * @title_de Section line categories
  * @description_de Text for section line categories label
  */
  sectionLineCategories: LocalizedString
  /**
  * @title Section line products description
  * @description Text for section line products description label
  * @title_de Section line products description
  * @description_de Text for section line products description label
  */
  sectionLineProductDescription: LocalizedString
  /**
  * @title Button show more
  * @description Text for show more button's label
  * @title_de Button show more
  * @description_de Text for show more button's label
  */
  buttonShowMore: LocalizedString
  /**
  * @title Button collapse
  * @description Text for collapse button
  * @title_de Button collapse
  * @description_de Text for collapse button
  */
  buttonCollapse: LocalizedString
  /**
  * @title Join showroom
  * @description Text for join showroom option title
  * @title_de Join showroom
  * @description_de Text for join showroom option title
  */
  joinShowroom: LocalizedString
  /**
  * @title Request meeting
  * @description Text for request meeting option title
  * @title_de Request meeting
  * @description_de Text for request meeting option title
  */
  requestMeeting: LocalizedString
  /**
  * @title Register interest
  * @description Text for register interest option title
  * @title_de Register interest
  * @description_de Text for register interest option title
  */
  registerInterest: LocalizedString
  /**
  * @title Recommemd
  * @description Text for recommemd option title
  * @title_de Recommemd
  * @description_de Text for recommemd option title
  */
  recommend: LocalizedString
  /**
  * @title Bookmark
  * @description Text for Bookmark option title
  * @title_de Bookmark
  * @description_de Text for Bookmark option title
  */
  bookmark: LocalizedString
  /**
  * @title Backoffice
  * @description Text for backoffice option title
  * @title_de Backoffice
  * @description_de Text for backoffice option title
  */
  backoffice: LocalizedString
  /**
  * @title Connect with us
  * @description Text for Connect with us option title
  * @title_de Connect with us
  * @description_de Text for Connect with us option title
  */
  connectWithUs: LocalizedString
  /**
  * @title Team section title visible
  * @description Show team section title instead of company name
  * @title_de Team section title visible
  * @description_de Show team section title instead of company name
  */
  teamSectionTitleVisible: boolean
  /**
  * @title Team section title
  * @description Title of team section
  * @title_de Team section title
  * @description_de Title of team section
  */
  teamSectionTitle: LocalizedString
  /**
  * @title Talk with team before string
  * @description Text for the first part of the talk with team label
  * @title_de Talk with team before string
  * @description_de Text for the first part of the talk with team label
  */
  talkWithTeamBefString: LocalizedString
  /**
  * @title Talk with team after string
  * @description Text for the second part of the talk with team label
  * @title_de Talk with team after string
  * @description_de Text for the second part of the talk with team label
  */
  talkWithTeamAftString: LocalizedString
  /**
  * @title Join button
  * @description Text for join button label
  * @title_de Join button
  * @description_de Text for join button label
  */
  joinButton: LocalizedString
  /**
  * @title Live sessions title
  * @description Text for live sessions title
  * @title_de Live sessions title
  * @description_de Text for live sessions title
  */
  liveSessionsTitle: LocalizedString
  /**
  * @title Upcoming sessions title
  * @description Text for upcoming sessions title
  * @title_de Upcoming sessions title
  * @description_de Text for upcoming sessions title
  */
  upcomingSessionsTitle: LocalizedString
  /**
  * @title Past sessions title
  * @description Text for past sessions title
  * @title_de Past sessions title
  * @description_de Text for past sessions title
  */
  pastSessionsTitle: LocalizedString
  /**
  * @title Virtual cafe title
  * @description Text for virtual cafe section title
  * @title_de Virtual cafe title
  * @description_de Text for virtual cafe section title
  */
  virtualCafesTitle: LocalizedString
  /**
  * @title Media title
  * @description Text for media section title
  * @title_de Media title
  * @description_de Text for media section title
  */
  mediaTitle: LocalizedString
  /**
  * @title Video download enabled
  * @description Set downloadability of videos in media section
  * @title_de Video download enabled
  * @description_de Set downloadability of videos in media section
  */
  videoDownloadEnabled: boolean
  /**
  * @title Links title
  * @description Text for links section title
  * @title_de Links title
  * @description_de Text for links section title
  */
  linksTitle: LocalizedString
  /**
  * @title Join now button
  * @description Text for Join now button
  * @title_de Join now button
  * @description_de Text for Join now button
  */
  joinNowButton: LocalizedString
  /**
  * @title Show full screen
  * @description Text for Show full screen option
  * @title_de Show full screen
  * @description_de Text for Show full screen option
  */
  showFullScreen: LocalizedString
  /**
  * @title Bookmark
  * @description Text for bookmark option
  * @title_de Bookmark
  * @description_de Text for bookmark option
  */
  bookmarkPerson: LocalizedString
  /**
  * @title Founding year title
  * @description Text for founding year label
  * @title_de Founding year title
  * @description_de Text for founding year label
  */
  foundingYearTitle: LocalizedString
  /**
  * @title Company size title
  * @description Text for company size label
  * @title_de Company size title
  * @description_de Text for company size label
  */
  companySizeTitle: LocalizedString
  /**
  * @title Industry title
  * @description Text for industry label
  * @title_de Industry title
  * @description_de Text for industry label
  */
  industryTitle: LocalizedString
  /**
  * @title Exploration phase placeholder
  * @description Text for exploration phase placeholder
  * @title_de Exploration phase placeholder
  * @description_de Text for exploration phase placeholder
  */
  explorationPhasePlaceholder: LocalizedString
  /**
  * @title Post phase placeholder
  * @description Text for post phase placeholder
  * @title_de Post phase placeholder
  * @description_de Text for post phase placeholder
  */
  postPhasePlaceholder: LocalizedString
  /**
  * @title Media slider primary color
  * @description Text color for media slider
  * @title_de Media slider primary color
  * @description_de Text color for media slider
  */
  mediaSliderPrimaryColor: ColorString
  /**
  * @title Icon color
  * @description Custom icon color
  * @title_de Icon color
  * @description_de Custom icon color
  */
  iconColor: ColorString
  /**
  * @title Company information title
  * @description Text for company information title
  * @title_de Company information title
  * @description_de Text for company information title
  */
  companyInformationTitle: LocalizedString
  /**
  * @title Company contact to title
  * @description Text for company contact to title
  * @title_de Company contact to title
  * @description_de Text for company contact to title
  */
  companyContactToTitle: LocalizedString
  /**
  * @title Organization detail page main content order
  * @description Main order of sections inside of the organization detail page - Possible options (DETAIL_INFO, TEAM, LIVE_SESSIONS, UPCOMING_SESSIONS, PREVIOUS_SESSIONS, CATEGORIES, VIRTUAL_CAFES, PARENT, CO_EXHIBITORS, PRODUCTS, TRADEMARKS, COUPONS, SHOWCASE, LINKS, MEDIA, COMPANY_INFORMATION_TITLE, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS)
  * @title_de Organization detail page main content order
  * @description_de Main order of sections inside of the organization detail page - Possible options (DETAIL_INFO, TEAM, LIVE_SESSIONS, UPCOMING_SESSIONS, PREVIOUS_SESSIONS, CATEGORIES, VIRTUAL_CAFES, PARENT, CO_EXHIBITORS, PRODUCTS, TRADEMARKS, COUPONS, SHOWCASE, LINKS, MEDIA, COMPANY_INFORMATION_TITLE, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS)
  */
  orgaDetailPageMainContentOrder: Array<DetailSection>
  /**
  * @title Organization detail page info content order
  * @description Info order of sections inside of the organization detail page - Possible options (SOCIAL_MEDIA, DESCRIPTION, TALK_WITH_TEAM, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS, CONFERENCE_ROOMS)
  * @title_de Organization detail page info content order
  * @description_de Info order of sections inside of the organization detail page - Possible options (SOCIAL_MEDIA, DESCRIPTION, TALK_WITH_TEAM, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS, CONFERENCE_ROOMS)
  */
  orgaDetailPageInfoSectionOrder: Array<InfoContentOrderType>
  /**
  * @title Default header basis premium types
  * @description Visibility of header image by basis premium type
  * @title_de Default header basis premium types
  * @description_de Visibility of header image by basis premium type
  */
  defaultHeaderBasisPremiumTypes: Array<string>
  /**
  * @title Company links font weight
  * @description Font weight for company links
  * @title_de Company links font weight
  * @description_de Font weight for company links
  */
  companyLinksFontWeight: string
  /**
  * @title Categories section title
  * @description Text for categories section title
  * @title_de Categories section title
  * @description_de Text for categories section title
  */
  categoriesSectionTitle: LocalizedString
  /**
  * @title Co exhibitors section title
  * @description Text for Co exhibitors section title
  * @title_de Co exhibitors section title
  * @description_de Text for Co exhibitors section title
  */
  coExhibitorsSectionTitle: LocalizedString
  /**
  * @title Parent section title
  * @description Text for parent section title
  * @title_de Parent section title
  * @description_de Text for parent section title
  */
  parentSectionTitle: LocalizedString
  /**
  * @title Remove expo showroom during post event
  * @description Enable / disable expo showroom option during post event
  * @title_de Remove expo showroom during post event
  * @description_de Enable / disable expo showroom option during post event
  */
  removeExpoShowroomDuringPostEvent: boolean
  /**
  * @title Blocked staff icon color
  * @description Icon color for blocked staff icon
  * @title_de Blocked staff icon color
  * @description_de Icon color for blocked staff icon
  */
  blockedStaffIconColor: string
  /**
  * @title Number of products none
  * @description Number of products shown for organization which has none as basis premium type package
  * @title_de Number of products none
  * @description_de Number of products shown for organization which has none as basis premium type package
  */
  numberOfProductsNone: number
  /**
  * @title Number of products basic
  * @description Number of products shown for organization which has basic as basis premium type package
  * @title_de Number of products basic
  * @description_de Number of products shown for organization which has basic as basis premium type package
  */
  numberOfProductsBasic: number
  /**
  * @title Number of products standard
  * @description Number of products shown for organization which has standard as basis premium type package
  * @title_de Number of products standard
  * @description_de Number of products shown for organization which has standard as basis premium type package
  */
  numberOfProductsStandard: number
  /**
  * @title Number of products premium
  * @description Number of products shown for organization which has premium as basis premium type package
  * @title_de Number of products premium
  * @description_de Number of products shown for organization which has premium as basis premium type package
  */
  numberOfProductsPremium: number
  /**
  * @title Number of products ultimate
  * @description Number of products shown for organization which has ultimate as basis premium type package
  * @title_de Number of products ultimate
  * @description_de Number of products shown for organization which has ultimate as basis premium type package
  */
  numberOfProductsUltimate: number
  /**
  * @title Number of trademarks none
  * @description Number of trademarks shown for organization which has none as basis premium type package
  * @title_de Number of trademarks none
  * @description_de Number of trademarks shown for organization which has none as basis premium type package
  */
  numberOfTrademarksNone: number
  /**
  * @title Number of trademarks basic
  * @description Number of trademarks shown for organization which has basic as basis premium type package
  * @title_de Number of trademarks basic
  * @description_de Number of trademarks shown for organization which has basic as basis premium type package
  */
  numberOfTrademarksBasic: number
  /**
  * @title Number of trademarks standard
  * @description Number of trademarks shown for organization which has standard as basis premium type package
  * @title_de Number of trademarks standard
  * @description_de Number of trademarks shown for organization which has standard as basis premium type package
  */
  numberOfTrademarksStandard: number
  /**
  * @title Number of trademarks premium
  * @description Number of trademarks shown for organization which has premium as basis premium type package
  * @title_de Number of trademarks premium
  * @description_de Number of trademarks shown for organization which has premium as basis premium type package
  */
  numberOfTrademarksPremium: number
  /**
  * @title Number of trademarks ultimate
  * @description Number of trademarks shown for organization which has ultimate as basis premium type package
  * @title_de Number of trademarks ultimate
  * @description_de Number of trademarks shown for organization which has ultimate as basis premium type package
  */
  numberOfTrademarksUltimate: number
  /**
  * @title Number of news none
  * @description Number of news shown for organization which has none as basis premium type package
  * @title_de Number of news none
  * @description_de Number of news shown for organization which has none as basis premium type package
  */
  numberOfNewsNone: number
  /**
  * @title Number of news basic
  * @description Number of news shown for organization which has basic as basis premium type package
  * @title_de Number of news basic
  * @description_de Number of news shown for organization which has basic as basis premium type package
  */
  numberOfNewsBasic: number
  /**
  * @title Number of news standard
  * @description Number of news shown for organization which has standard as basis premium type package
  * @title_de Number of news standard
  * @description_de Number of news shown for organization which has standard as basis premium type package
  */
  numberOfNewsStandard: number
  /**
  * @title Number of news premium
  * @description Number of news shown for organization which has premium as basis premium type package
  * @title_de Number of news premium
  * @description_de Number of news shown for organization which has premium as basis premium type package
  */
  numberOfNewsPremium: number
  /**
  * @title Number of news ultimate
  * @description Number of news shown for organization which has ultimate as basis premium type package
  * @title_de Number of news ultimate
  * @description_de Number of news shown for organization which has ultimate as basis premium type package
  */
  numberOfNewsUltimate: number
  /**
  * @title vCard note template
  * @description Text for vCard note template
  * @title_de vCard note template
  * @description_de Text for vCard note template
  */
  vCardNoteTemplate: LocalizedString
  /**
  * @title vCard Download available
  * @description Enable / disable vCard download option
  * @title_de vCard Download available
  * @description_de Enable / disable vCard download option
  */
  vCardDownloadAvailable: boolean
  /**
  * @title Available social medias
  * @description Array of available social medias - Possible options (FACEBOOK, TWITTER, LINKEDIN, XING, INSTAGRAM, YOUTUBE)
  * @title_de Available social medias
  * @description_de Array of available social medias - Possible options (FACEBOOK, TWITTER, LINKEDIN, XING, INSTAGRAM, YOUTUBE)
  */
  availableSocialMedias: SocialMediaType[]
  /**
  * @title Company teaser font size
  * @description Font size for company teaser on organization detail page
  * @title_de Company teaser font size
  * @description_de Font size for company teaser on organization detail page
  */
  companyTeaserFontSize: string
  /**
  * @title Showcase
  * @description Text for showcase label
  * @title_de Showcase
  * @description_de Text for showcase label
  */
  showcase: LocalizedString
  /**
  * @title Fullscreen button
  * @description Text for fullscreen button label
  * @title_de Fullscreen button
  * @description_de Text for fullscreen button label
  */
  fullscreenButton: LocalizedString
  /**
  * @title New tab button
  * @description Text for new tab button label
  * @title_de New tab button
  * @description_de Text for new tab button label
  */
  newTabButton: LocalizedString
  /**
  * @title Root categories
  * @description Root categories for organization categories
  * @title_de Root categories
  * @description_de Root categories for organization categories
  */
  rootCategories: Array<string>
  /**
   * @title Hierarchy for categories 
   * @description Set to true if you want to get hierarchy for the categories on the organization detail page 
   * @title_de Hierarchy for categories
   * @description_de Set to true if you want to get hierarchy for the categories on the organization detail page
   */
  showCategoryHierarchy: boolean
  /**
   * @title Category fill color  
   * @description Unactive category fill color 
   * @title_de Category fill color 
   * @description_de Unactive category fill color   
   */
  categoryFillColor: string
  /**
  * @title Category text color  
  * @description Unactive category text color 
  * @title_de Category text color 
  * @description_de Unactive category text color   
  */
  categoryTextColor: string
  /**
   * @title Category fill color  
   * @description Active category fill color 
   * @title_de Category fill color 
   * @description_de Active category fill color   
   */
  categoryActiveFillColor: string
  /**
  * @title Category text color  
  * @description Active category text color 
  * @title_de Category text color 
  * @description_de Active category text color   
  */
  categoryActiveTextColor: string

  /**
  * @title Action icons color in list view  
  * @description defines the colors of the action items in the list view
  * @title_de Action icons color in list view  
  * @description_de Action icons color in list view    
  */
  listViewActionIconsColor: ColorString

  /**
  * @title List view divider color
  * @description Color of the list view divider
  * @title_de List view divider color  
  * @description_de Color of the list view divider    
  */
  listViewDividerColor: ColorString

  /**
  * @title Iframe border color
  * @description CSS for the border of organization's iframe element
  * @title_de Iframe border color  
  * @description_de CSS for the border of organization's iframe element    
  */
  iframeBorder: string

  /**
  * @title Hide email address
  * @description Set to true if you want to hide email address on company detail page.
  * @title_de Hide email address  
  * @description_de Set to true if you want to hide email address on company detail page.   
  */
  hideEmailAddress: boolean

  /**
   * @title Hide telephone address
   * @description Set to true if you want to hide telephone on company detail page.
   * @title_de Hide telephone address  
   * @description_de Set to true if you want to hide telephone on company detail page.   
   */
  hideTelephone: boolean
  /**
  * @title RemoteLivestreams title
  * @description Title which is shown above the remote livestreams section on company detail page.
  * @title_de RemoteLivestreams title  
  * @description_de Title which is shown above the remote livestreams section on company detail page. 
  */
  remoteLivestreamsTitle: LocalizedString


    /**
     * @title List layout title color
     * @description Color of the title in list layout
     * @title_de List layout title color
     * @description_de Color of the title in list layout
     */
     staffListViewTitleColor: string

     /**
      * @title List layout title font
      * @description font of the title in list layout
      * @title_de List layout title font
      * @description_de font of the title in list layout
      */
      staffListViewTitleFont: string


       /**
     * @title List layout title color
     * @description Color of the title in list layout
     * @title_de List layout title color
     * @description_de Color of the title in list layout
     */
        listViewEntitiesTitleColor: string

     /**
      * @title List layout title font
      * @description font of the title in list layout
      * @title_de List layout title font
      * @description_de font of the title in list layout
      */
      listViewEntitiesTitleFont: string

}

export interface PersonDetailPageContentBranding {
  /**
  * @title Navbar text  
  * @description Text for person detail page title   
  * @title_de Navbar text 
  * @description_de Text for person detail page title   
  */
  navbarText: LocalizedString
  /**
  * @title Cancel request message  
  * @description Text for cancel request message   
  * @title_de Cancel request message 
  * @description_de Text for cancel request message   
  */
  cancelRequestMessage: LocalizedString
  /**
  * @title Cancel connection message  
  * @description Text for cancel connection message   
  * @title_de Cancel connection message 
  * @description_de Text for cancel connection message  
  */
  cancelConnectionMessage: LocalizedString
  /**
  * @title Ignored message  
  * @description Text for ignored request message   
  * @title_de Ignored message 
  * @description_de Text for ignored request message  
  */
  ignoredMessage: LocalizedString
  /**
  * @title Open request message  
  * @description Text for open request message   
  * @title_de Open request message 
  * @description_de Text for open request message  
  */
  openRequestMessage: LocalizedString
  /**
  * @title Send request message  
  * @description Text for send request message   
  * @title_de Send request message 
  * @description_de Text for send request message  
  */
  sendRequestMessage: LocalizedString
  /**
  * @title Chat text  
  * @description Text for Chat label   
  * @title_de Chat text 
  * @description_de Text for Chat label  
  */
  chatText: LocalizedString
  /**
  * @title Video call text  
  * @description Text for Video call label   
  * @title_de Video call text 
  * @description_de Text for Video call label  
  */
  videoCallText: LocalizedString
  /**
  * @title Voice call text  
  * @description Text for Voice call label   
  * @title_de Voice call text 
  * @description_de Text for Voice call label  
  */
  voiceCallText: LocalizedString
  /**
  * @title Voice calling text  
  * @description Text for calling label   
  * @title_de Voice calling text 
  * @description_de Text for calling label  
  */
  voiceCallingText: LocalizedString
  /**
  * @title Meeting text  
  * @description Text for meeting label   
  * @title_de Meeting text 
  * @description_de Text for meeting label  
  */
  meetingText: LocalizedString
  /**
  * @title Live sessions title  
  * @description Text for Live sessions label   
  * @title_de Live sessions title 
  * @description_de Text for Live sessions label  
  */
  liveSessionsTitle: LocalizedString
  /**
  * @title Upcoming sessions title  
  * @description Text for Upcoming sessions label   
  * @title_de Upcoming sessions title 
  * @description_de Text for Upcoming sessions label  
  */
  upcomingSessionsTitle: LocalizedString
  /**
  * @title Interests title  
  * @description Text for Interests label   
  * @title_de Interests title 
  * @description_de Text for Interests label  
  */
  interestsTitle: LocalizedString
  /**
  * @title Offers title  
  * @description Text for Offers label   
  * @title_de Offers title 
  * @description_de Text for Offers label  
  */
  offersTitle: LocalizedString
  /**
  * @title Needs title  
  * @description Text for Needs label   
  * @title_de Needs title 
  * @description_de Text for Needs label  
  */
  needsTitle: LocalizedString
  /**
  * @title View more categories button text
  * @description Text for view more categories button 
  * @title_de View more categories button text
  * @description_de Text for view more categories button 
  */
  viewMoreCategoriesButtonText: LocalizedString
  /**
 * @title Categories limit number
 * @description Number of categories initially shown in categories lists in mobile version
 * @title_de Categories limit number
 * @description_de Number of categories initially shown in categories lists in mobile version
 */
  categoriesLimitNumber: number
  /**
  * @title No interests selected message  
  * @description Text for no selected interests message  
  * @title_de No interests selected message 
  * @description_de Text for no selected interests message  
  */
  noInterestsSelectedMessage: LocalizedString
  /**
  * @title Person detail page speaker title name  
  * @description Text color for speaker title inside of the person detail page
  * @title_de Person detail page speaker title name 
  * @description_de Text color for speaker title inside of the person detail page
  */
  personDetailPageSpeakerTitleName: ColorString
  /**
  * @title Person detail page speaker title position  
  * @description Text color for speaker position inside of the person detail page
  * @title_de Person detail page speaker title position 
  * @description_de Text color for speaker position inside of the person detail page
  */
  personDetailPageSpeakerTitlePosition: ColorString
  /**
  * @title Person contact details container color  
  * @description Text color for person contact details container
  * @title_de Person contact details container color 
  * @description_de Text color for person contact details container
  */
  personContactDetailsContainerTextColor: ColorString
  /**
  * @title Person contact details container color on hover
  * @description Text color on hover for person contact details container
  * @title_de Person contact details container color on hover 
  * @description_de Text color on hover for person contact details container
  */
  personContactDetailsContainerTextColorOnHover: ColorString
  /**
  * @title Organization logo div
  * @description Border details for organization logo div - Example: 1px solid #000
  * @title_de Organization logo div 
  * @description_de Border details for organization logo div - Example: 1px solid #000
  */
  organizationLogoDiv: string
  /**
  * @title Company logo div
  * @description Border details for Company logo div - Example: 1px solid #000
  * @title_de Company logo div 
  * @description_de Border details for Company logo div - Example: 1px solid #000
  */
  companyLogoDiv: string
  /**
  * @title Upcoming sessions title color
  * @description Text color for Upcoming sessions title color
  * @title_de Upcoming sessions title color 
  * @description_de Text color for Upcoming sesstions title color
  */
  upcomingSessionsTitleTextColor: ColorString
  /**
  * @title Formatted date div
  * @description Border details for Formatted date div - Example: 1px solid #000
  * @title_de Formatted date div 
  * @description_de Border details for Formatted date div - Example: 1px solid #000
  */
  formattedDateDiv: string
  /**
  * @title Interests item border color
  * @description Border color and details for interests item - Example: 1px solid #000
  * @title_de Interests item border color 
  * @description_de Border color and details for interests item - Example: 1px solid #000
  */
  interestItemBorderColor: string
  /**
  * @title Interests item text color
  * @description Text color interests item
  * @title_de Interests item text color 
  * @description_de Text color interests item
  */
  interestItemTextColor: ColorString
  /**
  * @title Upcoming session share icon color
  * @description Fill color for upcoming session share icon
  * @title_de Upcoming session share icon color 
  * @description_de Fill color for upcoming session share icon
  */
  upcomingSessionShareIconColor: ColorString
  /**
  * @title Live tile header color
  * @description Text color for live tile header label
  * @title_de Live tile header color 
  * @description_de Text color for live tile header label
  */
  liveTileHeaderTextColor: ColorString

  /**
  * @title Bookmark events popup visibility
  * @description When a user bookmarks a speaker, show a pop-up asking the user whether they want to bookmark all the eventDates the speaker is participating in
  * @title_de Bookmark events popup visibility
  * @description_de When a user bookmarks a speaker, show a pop-up asking the user whether they want to bookmark all the eventDates the speaker is participating in
  */
  bookmarkEventsPopupVisible: boolean

  /**
   * @title Bookmark events popup text
   * @description Text in a popup optionally shown when a user bookmarks a speaker
   * @title_de Bookmark events popup text
   * @description_de Text in a popup optionally shown when  auser bookmarks a speaker
   */
  bookmarkEventsPopupText: LocalizedString

  /**
* @title Bookmark events confirm button text
* @description Text of the confirm button in a popup optionally shown when a user bookmarks a speaker
* @title_de Bookmark events confirm button text
* @description_de Text of the confirm button in a popup optionally shown when a user bookmarks a speaker
*/
  bookmarkEventsPopupConfirmButtonText: LocalizedString

  /**
 * @title Bookmark events cancel button text
 * @description Text of the cancel button in a popup optionally shown when a user bookmarks a speaker
 * @title_de Bookmark events cancel button text
 * @description_de Text of the cancel button in a popup optionally shown when  auser bookmarks a speaker
 */
  bookmarkEventsPopupCancelButtonText: LocalizedString


  /**
  * @title Share speaker button text
  * @description Text in button for sharing the speaker in speaker's profile page
  * @title_de Share speaker button text
  * @description_de Text in button for sharing the speaker in speaker's profile page
  */
  shareSpeakerButtonText: LocalizedString

  /**
 * @title Bookmark all events button text
 * @description Text in button for bookmarking all of the speaker's events in speaker's profile page
 * @title_de Bookmark all events button text
 * @description_de Text in button for bookmarking all of the speaker's events in speaker's profile page
 */
  bookmarkEventsButtonText: LocalizedString

}

export interface ProductDetailPageContentBranding {
  /**
  * @title Navbar text  
  * @description Text for product detail page title   
  * @title_de Navbar text 
  * @description_de Text for product detail page title   
  */
  navbarText: LocalizedString
  /**
  * @title Sections  
  * @description Available sections and order of them for the product detail page  
  * @title_de Sections 
  * @description_de Available sections and order of them for the product detail page  
  */
  sections: Array<DetailSection>
  /**
  * @title Info content order  
  * @description Order for the Info content part of the page inside of the product detail page
  * @title_de Info content order 
  * @description_de Order for the Info content part of the page inside of the product detail pag  
  */
  infoContentOrder: Array<InfoContentOrderType>
  /**
  * @title Root categories  
  * @description Root categories for product categories  
  * @title_de Root categories 
  * @description_de Root categories for product categories  
  */
  rootCategories: Array<string>
  /**
 * @title Hierarchy for categories 
 * @description Set to true if you want to get hierarchy for the categories on the product detail page 
 * @title_de Hierarchy for categories
 * @description_de Set to true if you want to get hierarchy for the categories on the product detail page
 */
  showCategoryHierarchy: boolean
  /**
  * @title Media full screen text
  * @description Text that will indicate that the user can click to opet the gallery in full screen
  * @title_de Media full screen text
  * @description_de Text that will indicate that the user can click to opet the gallery in full screen
  */
  mediaFullScreenText: LocalizedString
  /**
  * @title Show more text
  * @description Text that will be showed on the product detail page description if its length goes over 900 caracters
  * @title_de Show more text
  * @description_de Text that will be showed on the product detail page description if its length goes over 900 caracters
  */
  showMoreText: LocalizedString
  /**
 * @title Show less text
 * @description Text that will be showed if the show more text is clicked. Product detail page description.
 * @title_de Show less text
 * @description_de Text that will be showed if the show more text is clicked. Product detail page description.
 */
  showLessText: LocalizedString
}

export interface TrademarkDetailPageContentBranding {
  /**
  * @title Navbar text  
  * @description Text for trademarks detail page title   
  * @title_de Navbar text 
  * @description_de Text for trademarks detail page title   
  */
  navbarText: LocalizedString
  /**
  * @title Sections  
  * @description Available sections and order of them for the trademarks detail page  
  * @title_de Sections 
  * @description_de Available sections and order of them for the trademarks detail page  
  */
  sections: Array<DetailSection>
  /**
  * @title Info content order  
  * @description Order for the Info content part of the page inside of the trademarks detail page
  * @title_de Info content order 
  * @description_de Order for the Info content part of the page inside of the trademarks detail pag  
  */
  infoContentOrder: Array<InfoContentOrderType>
  /**
  * @title Root categories  
  * @description Root categories for trademark categories  
  * @title_de Root categories 
  * @description_de Root categories for trademark categories  
  */
  rootCategories: Array<string>
  /**
 * @title Hierarchy for categories 
 * @description Set to true if you want to get hierarchy for the categories on the trademark detail page 
 * @title_de Hierarchy for categories
 * @description_de Set to true if you want to get hierarchy for the categories on the trademark detail page
 */
  showCategoryHierarchy: boolean
}

export interface NewsDetailPageContentBranding {
  /**
  * @title Sections  
  * @description Available sections and order of them for the news detail page  
  * @title_de Sections 
  * @description_de Available sections and order of them for the news detail page  
  */
  sections: Array<DetailSection>
  /**
  * @title Info content order  
  * @description Order for the Info content part of the page inside of the news detail page
  * @title_de Info content order 
  * @description_de Order for the Info content part of the page inside of the news detail pag  
  */
  infoContentOrder: Array<InfoContentOrderType>
  /**
  * @title Root categories  
  * @description Root categories for news categories  
  * @title_de Root categories 
  * @description_de Root categories for news categories  
  */
  rootCategories: Array<string>
  /**
   * @title Hierarchy for categories 
   * @description Set to true if you want to get hierarchy for the categories on the news detail page 
   * @title_de Hierarchy for categories
   * @description_de Set to true if you want to get hierarchy for the categories on the news detail page
   */
  showCategoryHierarchy: boolean
}

export interface ProductsPageContentBranding {
  /**
  * @title Navigation search placeholder products  
  * @description Text for search products placeholder
  * @title_de Navigation search placeholder products 
  * @description_de Text for search products placeholder  
  */
  navigationSearchPlaceholderProducts: LocalizedString
  /**
  * @title Loading message
  * @description Text for loading label 
  * @title_de Loading message
  * @description_de Text for loading label 
  */
  loadingMessage: LocalizedString
  /**
  * @title No bookmarks message
  * @description Text for empty search results for bookmarked products  
  * @title_de No bookmarks message
  * @description_de Text for empty search results for bookmarked products  
  */
  noBookmarksMessage: LocalizedString
}

export interface EventDateDetailPageContentBranding {
  /**
  * @title Event layout title
  * @description Title for event layout part of the Event date detail page  
  * @title_de Event layout title
  * @description_de Title for event layout part of the Event date detail page  
  */
   eventLayoutTitle: LocalizedString
  /**
  * @title Description text for event date
  * @description Text for description of the event date on the Event detail page  
  * @title_de Description text for event date
  * @description_de Text for description of the event date on the Event detail page  
  */
  descriptionTextForEventDate: LocalizedString
   /**
  * @title Description text for event dates binded to event
  * @description Title for description of the event dates binded to the Events
  * @title_de Description text for event dates binded to event
  * @description_de Title for description of the event dates binded to the Events  
  */
    descriptionForEventDatesBindedToEvent: LocalizedString
  /**
  * @title Event layout visible on eventDateDetail page
  * @description Enable / disable visibility of event layout on event date detail page  
  * @title_de Event layout visible on eventDateDetail page
  * @description_de Enable / disable visibility of event layout on event date detail page  
  */
   eventLayoutVisibleOnEventDateDetailPage: boolean
  /**
  * @title Persons title
  * @description Text for persons title on event date detail page  
  * @title_de Persons title
  * @description_de Text for persons title on event date detail page  
  */
  personsTitle: LocalizedString
  /**
  * @title No persons message
  * @description Text for empty search of persons message 
  * @title_de No persons message
  * @description_de Text for empty search of persons message 
  */
  noPersonsMessage: LocalizedString
  /**
  * @title Gallery title
  * @description Text for gallery title 
  * @title_de Gallery title
  * @description_de Text for gallery title 
  */
  galleryTitle: LocalizedString
  /**
  * @title No section title
  * @description Text for no section title 
  * @title_de No section title
  * @description_de Text for no section title 
  */
  noSectionTitle: LocalizedString
  /**
  * @title Event date round table no access text
  * @description Text for no access message of the event date round table
  * @title_de Event date round table no access text
  * @description_de Text for no access message of the event date round table
  */
  eventDateRoundTableNoAccessText: LocalizedString
  /**
  * @title Event date round granted access text
  * @description Text for granted access message of the event date round table
  * @title_de Event date round granted access text
  * @description_de Text for granted access message of the event date round table
  */
  eventDateRoundTableGrantedAccessText: LocalizedString
  /**
  * @title Private event date no access text
  * @description Text for no access message of the private event date
  * @title_de Private event date no access text
  * @description_de Text for no access message of the private event date
  */
  privateEventDateNoAccessText: LocalizedString
  /**
  * @title Private event date granted access text
  * @description Text for granted access message of the private event date
  * @title_de Private event date granted access text
  * @description_de Text for granted access message of the private event date
  */
  privateEventDateGrantedAccessText: LocalizedString
  /**
  * @title Event date round table post event text
  * @description Text for post event usage of the round table 
  * @title_de Event date round table post event text
  * @description_de Text for post event usage of the round table 
  */
  eventDateRoundTablePostEventText: LocalizedString
  /**
  * @title Event date round table button access granted live
  * @description Text for access granted live button 
  * @title_de Event date round table button access granted live
  * @description_de Text for access granted live button 
  */
  eventDateRoundTableButtonAccessGrantedLive: LocalizedString
  /**
  * @title Event date round table button post event
  * @description Text for expired round table in post event phase 
  * @title_de Event date round table button post event
  * @description_de Text for expired round table in post event phase 
  */
  eventDateRoundTableButtonPostEvent: LocalizedString
  /**
 * @title Event date round table button countdown 
 * @description Countdown button text in singular and plural for the round table 
 * @title_de Event date round table button countdown
 * @description_de Countdown button text in singular and plural for the round table
 */
  eventDateRoundTableButtonCountdown: PluralString
  /**
  * @title Chat visible 
  * @description Enable / disable visibility of the chat item in the event detail page switcher 
  * @title_de Chat visible
  * @description_de Enable / disable visibility of the chat item in the event detail page switcher
  */
  chatVisible: boolean
  /**
  * @title Polls visible 
  * @description Enable / disable visibility of the polls item in the event detail page switcher 
  * @title_de Polls visible
  * @description_de Enable / disable visibility of the polls item in the event detail page switcher
  */
  pollsVisible: boolean
  /**
  * @title Participants visible 
  * @description Enable / disable visibility of the Participants item in the event detail page switcher 
  * @title_de Participants visible
  * @description_de Enable / disable visibility of the Participants item in the event detail page switcher
  */
  participantsVisible: boolean
  /**
  * @title Multi switch items  
  * @description Array of multi switch items for event date detail page  
  * @title_de Multi switch items 
  * @description_de Array of multi switch items for event date detail page    
  */
  multiSwitchItems: Array<string>
  /**
  * @title Link section visible 
  * @description Show/hide link section
  * @title_de Theme color
  * @description_de Linkabschnitt anzeigen
  */
  linksVisible: boolean
  /**
  * @title Categories section toggle
  * @description Set true if you want to show categories section on the event date detail page
  * @title_de Categories section toggle
  * @description_de Set true if you want to show categories section on the event date detail page
  */
  showCategories: boolean
  /**
  * @title Root categories  
  * @description Root categories for event date categories  
  * @title_de Root categories 
  * @description_de Root categories for event date categories  
  */
  rootCategories: Array<string>
  /**
   * @title Hierarchy for categories 
   * @description Set to true if you want to get hierarchy for the categories on the event date detail page 
   * @title_de Hierarchy for categories
   * @description_de Set to true if you want to get hierarchy for the categories on the event date detail page
   */
  showCategoryHierarchy: boolean
  /**
  * @title Channel participants tab title 
  * @description Title for participants tab on channel page
  * @title_de Channel participants tab title
  * @description_de Title for participants tab on channel page
  */
  channelParticipantsTabTitle: LocalizedString
  /**
  * @title No participants message 
  * @description Message when there are no participants on the channel
  * @title_de No participants message
  * @description_de Message when there are no participants on the channel
  */
  noParticipantsMessage: LocalizedString
}
