import { createState, State, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { ChatConversationParam } from '../communicationArea/ChatPage';
import { ScheduleListType } from '../communicationArea/ScheduleTab';
import { NetworkingListType } from '../communicationArea/CommunicationArea';
import { NotificationListType } from '../communicationArea/NotificationsTab';
import { getEnvironment, getEnvironmentShort } from "../environments";

import winston from 'winston';
import { EventDate } from '../backendServices/Types';
import "moment-timezone";
import moment from "moment";

interface StateValues {
    networkingOpen: boolean
    communicationCenterDisplayMode: CommunicationCenterDisplayMode
    communicationCenterDisplayParam?: any
    communicationCenterDisplayParams: { [mode: string]: any }
    forceLoadListFunction?: () => void

    showTourNotification: boolean

    showMissedCallNotification: boolean
    missedCallNotificationName: string
    missedCallNotificationId: string

    showMeetingReminder: boolean
    meetingReminderName: string
    meetingTimeBefore: string

    isMyHandRaised: boolean

    liveStreamingChannel: { id: string, url: string, eventDate?: EventDate } | null
    videoPlayerStatus: { volume?: number, isMuted?: boolean, isPaused?: boolean, audioTrack?: string } | null

    isMeetingChatShown: boolean | undefined

    refreshLobbyNetworking: boolean
    timezone: string

    currentItem: string
    lastVisitedTab: string | null
    isRosterOpen: boolean | null

    currentBreadcrumb: string
}

export enum CommunicationCenterDisplayMode {
    NETWORKING, CHATS, SCHEDULE, NOTIFICATIONS, SETTINGS,
}

const defaultValues: () => StateValues = () => {
    return {
        networkingOpen: true,
        communicationCenterDisplayMode: CommunicationCenterDisplayMode.NETWORKING,
        communicationCenterDisplayParams: {},
        showTourNotification: true,
        showMissedCallNotification: false,
        missedCallNotificationName: "",
        missedCallNotificationId: "",
        showMeetingReminder: true,
        meetingReminderName: "",
        meetingTimeBefore: "",
        isMyHandRaised: false,
        liveStreamingChannel: null,
        videoPlayerStatus: null,
        liveStreamingEventDate: null,
        isMeetingChatShown: undefined,
        refreshLobbyNetworking: false,
        timezone: moment.tz.guess(),
        suggestSearchVisible: false,
        currentItem: "home",
        lastVisitedTab: null,
        isRosterOpen: null,
        currentBreadcrumb: ""
    }
}

const customLogFormat = winston.format.printf(({ level, message, errorMessage, errorStack }) => {
    return `{environment: ${getEnvironment()}} ${level}: ${message} ${errorMessage} ${errorStack}`;
});


// Log only to remote on int/stage/live
// Log only error log to remote
const remoteLogger = getEnvironmentShort() !== "dev" ? new winston.transports.Http({ ssl: true, host: `logging${getEnvironmentShort() === "live" ? "" : "-" + getEnvironmentShort()}.event-cloud.com`, port: 443, path: "/", level: 'error', handleExceptions: true }) : undefined;

let logTargets: winston.transport[] = [];
if (remoteLogger !== undefined) {
    logTargets = logTargets.concat(remoteLogger);
}
// Log info and up to console
logTargets = logTargets.concat(new winston.transports.Console({ level: 'info', handleExceptions: true }));

export const defaultLogger = winston.createLogger({
    level: 'info',
    format: winston.format.combine(
        customLogFormat,
        winston.format.prettyPrint()),
    transports: logTargets,
    handleExceptions: true,
    exitOnError: false,
});



const useWrapState = ((state: State<StateValues>) => {
    state.attach(Persistence('virtualGuide-app'))

    const setDisplay = (mode: CommunicationCenterDisplayMode, param?: any | null, forceUpdate?: boolean) => {
        state.set(prevState => {
            prevState.networkingOpen = true
            const communicationCenterDisplayDidChange = prevState.communicationCenterDisplayMode !== mode || prevState.communicationCenterDisplayParam !== param
            prevState.communicationCenterDisplayMode = mode
            if (param === undefined) { // use param previously used for this mode
                prevState.communicationCenterDisplayParam = prevState.communicationCenterDisplayParams ? prevState.communicationCenterDisplayParams[mode] : undefined
            }
            else if (param === null) { // don't use any param
                prevState.communicationCenterDisplayParam = undefined
                if (prevState.communicationCenterDisplayParams)
                    prevState.communicationCenterDisplayParams[mode] = undefined
            } else {
                prevState.communicationCenterDisplayParam = param
                if (!prevState.communicationCenterDisplayParams) // for existing local storage values not yet containing this field
                    prevState.communicationCenterDisplayParams = {}
                prevState.communicationCenterDisplayParams[mode] = param
            }
            if (forceUpdate && !communicationCenterDisplayDidChange && prevState.forceLoadListFunction !== undefined) {
                prevState.forceLoadListFunction()
            }

            return prevState
        })

    }

    return ({
        setLiveStreamChannel: (newLiveStreamingChannel: { id: string, url: string, eventDate?: EventDate } | null) => {
            return state.set(prevState => {
                prevState.liveStreamingChannel = newLiveStreamingChannel
                return prevState
            })
        },
        liveStreamChannel: state.get().liveStreamingChannel,
        setVideoPlayerStatus: (newVideoPlayerStatus: { volume?: number, isMuted?: boolean, isPaused?: boolean, audioTrack?: string } | null) => {
            return state.set(prevState => {
                prevState.videoPlayerStatus = newVideoPlayerStatus
                return prevState
            })
        },
        videoPlayerStatus: state.get().videoPlayerStatus,
        isNetworkingOpen: () => {
            // const element = document.getElementById("hubspot-messages-iframe-container")  // WARNING! Saved code. Behaviour still not 100% defined
            // if (element) {
            //     if (window.location.pathname !== "/help") {
            //         element.style.cssText += ';display: none !important;';
            //     }
            // }
            // WHY IS AN ELEMENT STYLED IN THIS FUNCTION?
            const element = document.getElementById("hubspot-messages-iframe-container")
            if (element)
                element.style.cssText += state.get().networkingOpen ? ';right: 340px !important;bottom: 60px !important;' : ';right: 80px !important;bottom: 60px !important;'
            return state.get().networkingOpen
        },
        toggleNetworking: () => {
            return state.set(prevState => {
                prevState.networkingOpen = !prevState.networkingOpen
                return prevState
            })
        },
        communicationCenterDisplayMode: state.get().communicationCenterDisplayMode,
        communicationCenterDisplayParam: state.get().communicationCenterDisplayParam,
        setCommunicationCenterDisplayMode: (mode: CommunicationCenterDisplayMode) => {
            setDisplay(mode)
        },
        setShowPeopleTab: (param?: NetworkingListType) => {
            setDisplay(CommunicationCenterDisplayMode.NETWORKING, param)
        },
        setShowChatsTab: (param?: ChatConversationParam | null) => {
            setDisplay(CommunicationCenterDisplayMode.CHATS, param)
        },
        setShowScheduleTab: (param: ScheduleListType) => {
            setDisplay(CommunicationCenterDisplayMode.SCHEDULE, param, true)
        },
        setShowNotificationTab: (param: NotificationListType) => {
            setDisplay(CommunicationCenterDisplayMode.NOTIFICATIONS, param)
        },
        setShowSettingsTab: () => {
            setDisplay(CommunicationCenterDisplayMode.SETTINGS)
        },
        setCommunicationCenterForceLoadListFunction: (forceLoadListFunction: () => void) => {
            return state.set(prevState => {
                prevState.forceLoadListFunction = forceLoadListFunction
                return prevState
            })
        },
        showTourNotification: state.get().showTourNotification,
        setShowTourNotification: (show: boolean) => {
            return state.set(prevState => {
                prevState.showTourNotification = show
                return prevState
            })
        },
        showMissedCallNotification: state.get().showMissedCallNotification,
        missedCallNotificationName: state.get().missedCallNotificationName,
        missedCallNotificationId: state.get().missedCallNotificationId,

        setMissedCallNotification: (show: boolean, id: string, name: string) => {
            return state.set(prevState => {
                prevState.missedCallNotificationId = id
                prevState.missedCallNotificationName = name
                prevState.showMissedCallNotification = show
                return prevState
            })
        },

        showMeetingReminder: state.get().showMeetingReminder,
        meetingReminderName: state.get().meetingReminderName,
        meetingTimeBefore: state.get().meetingTimeBefore,

        setMeetingReminder: (show: boolean, name: string, timeBefore: string) => {
            return state.set(prevState => {
                prevState.meetingReminderName = name
                prevState.showMeetingReminder = show
                prevState.meetingTimeBefore = timeBefore
                return prevState
            })
        },

        isMyHandRaised: state.get().isMyHandRaised,

        setIsMyHandRaised: (raise: boolean) => {
            return state.set(prevState => {
                prevState.isMyHandRaised = raise
                return prevState
            })
        },

        isMeetingChatShown: state.get().isMeetingChatShown,

        setIsMeetingChatShown: (show: boolean) => {
            return state.set(prevState => {
                prevState.isMeetingChatShown = show
                return prevState
            })
        },

        timezone: state.get().timezone,

        setTimeZone: (tz: string) => {
            return state.set(prevState => {
                prevState.timezone = tz
                return prevState
            })
        },

        currentItem: state.get().currentItem,

        setCurrentItem: (currentItem: string) => {
            return state.set(prevState => {
                prevState.currentItem = currentItem
                return prevState
            })
        },

        currentBreadcrumb: state.get().currentBreadcrumb,

        setCurrentBreadcrumb: (currentBreadcrumb: string) => {
            return state.set(prevState => {
                prevState.currentBreadcrumb = currentBreadcrumb
                return prevState
            })
        },

        lastVisitedTab: state.get().lastVisitedTab,

        setLastVisitedTab: (lastVisitedTab: string | null) => {
            return state.set(prevState => {
                prevState.lastVisitedTab = lastVisitedTab
                return prevState
            })
        },

        isRosterOpen: state.get().isRosterOpen,

        setIsRosterOpen: (isRosterOpen: boolean) => {
            return state.set(prevState => {
                prevState.isRosterOpen = isRosterOpen
                return prevState
            })
        },
        reset: () => {
            return state.set(prevState => {
                prevState = defaultValues()
                return prevState
            })
        },

        refreshToggleLobbyNetworking: () => {
            return state.set(prevState => {
                prevState.refreshLobbyNetworking = !prevState.refreshLobbyNetworking
                return prevState
            })
        },

        lobbyNetworking: state.get().refreshLobbyNetworking
    })
})
const state = createState(defaultValues())
export const useAppState = () => useWrapState(useState(state))
