import React, { useState, useEffect } from "react"
import { EditProfileProps, ProfileRoot, ProfileSectionRoot, UserImageDiv, EditPhotoButton, EditProfileLabel, EditProfileInformationField, SaveChangesButton, ErrorMessageContainer, SaveChangesButtonContainer, InterestItem, EditProfileCategoryListLabel } from "./MobileMyProfilePageContent"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { useLanguageState } from "../../globalStates/LanguageState"
import { ProfileImageProps, ProfileCategoriesListProps, ProfileCategoriesProps, getCategorySectionTitle } from "./EditMyProfileLayout"
import branding from "../../branding/branding"
import { TextField, MultiLineTextField } from "../../ui/TextField"
import { getCountries, findCountryName } from "./CountrySwitcherHelper"
import Select, { ValueType, ActionMeta, OptionTypeBase } from "react-select"
import styled from "styled-components"
import { findLanguageName, getLanguages } from "./LanguageSwitcherHelper"
import { findTimezoneName, getTimezones } from "../../utils/DateUtils"
import { useAppState } from "../../globalStates/AppState"
import moment from "moment"
import { Field, SocialMediaType, CategoryType } from "./MyProfilePageContentBranding"
import TextLimit from "../../ui/TextLimit"
import { IconFacebook, IconTwitter, IconInstagram, IconLinkedIn, IconXing, IconYouTube, IconPinterest, IconSearchSmall } from "../../ui/Icons"
import { changeProfileImage, updateMyProfileData, BackendServiceError, updateUserValues, deleteProfileImage, getInterest } from "../../backendServices/BackendServices"
import { defaultLogger as logger } from "../../globalStates/AppState"
import CenteredLoader from "../../ui/CenteredLoader"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { CategoryData } from "./ViewMyProfileLayout"
import { Category } from "../../backendServices/Types"
import { Button, Row, Col } from "react-bootstrap"
import { EditProfileSection } from "./MyProfilePageContent"

const MobileEditMyProfileLayout: React.FunctionComponent<EditProfileProps> = (props: EditProfileProps) => {
    const loggedInUser = useLoggedInState().user()

    const lang = useLanguageState().getLanguage()

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    function getSection() {
        if (props.section) {
            switch (props.section) {
                case EditProfileSection.CONTACT:
                    return <ContactSection setMyProfileMode={props.setMyProfileMode} />

                case EditProfileSection.SOCIALS:
                    return <SocialsSection setMyProfileMode={props.setMyProfileMode} />

                case EditProfileSection.INTERESTS:
                    return <InterestsSection setMyProfileMode={props.setMyProfileMode} categoryData={categoryData} />

                case EditProfileSection.LOOKINGFORANDOFFERING:
                    return <OffersNeedsSection setMyProfileMode={props.setMyProfileMode} categoryData={categoryData} />

                default: return <div />
            }
        }

        return <div />
    }

    function getData() {
        if (props.section === EditProfileSection.INTERESTS || props.section === EditProfileSection.LOOKINGFORANDOFFERING) {
            getInterest().then(res => {
                if (res.content.interests) {
                    const interests: Category[] = res.content.interests ?? []
                    const offerings: Category[] = res.content.lookingforandoffering ?? []
                    const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                    const selectedInterests = interests.filter(i => (loggedInUser?.interests ?? []).includes(i.id))
                    const selectedOfferings = offerings.filter(i => (loggedInUser?.offering ?? []).includes(i.id))
                    const selectedLookingfor = lookingfor.filter(i => (loggedInUser?.lookingfor ?? []).includes(i.id))
                    setCategoryData(
                        {
                            interests: interests,
                            offerings: offerings,
                            lookingfor: lookingfor,
                            selectedInterests: selectedInterests,
                            selectedOfferings: selectedOfferings,
                            selectedLookingfor: selectedLookingfor
                        }
                    )
                }
            })
        }
    }

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    return <ProfileRoot background="#fff" style={{ height: "calc(100vh - 130px)" }}>
        {getSection()}
    </ProfileRoot>

}

const SaveLoader = styled(CenteredLoader)`
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
`

const ProfileImage: React.FunctionComponent<ProfileImageProps> = React.memo((props: ProfileImageProps) => {
    let imageInput: HTMLInputElement | null
    const strings = useLanguageState().getStrings().myProfilePageContent

    function triggerProfileImageInputClick() {
        if (imageInput) {
            imageInput.click()
        }
    }

    function handleProfileImageChange() {
        if (imageInput && imageInput !== null && imageInput.files) {
            props.setIsProfileImageDeleted(false)
            props.setIsProfileImageChanged(true)

            var binaryData = []
            binaryData.push(imageInput.files[0])

            const objectURL = URL.createObjectURL(new Blob(binaryData, { type: "image" }))
            props.setImageSize(imageInput.files[0].size)
            props.setLogoUrl(objectURL)
            props.setImageFile(imageInput.files[0])

            var reader = new FileReader();
            reader.onloadend = function () {

                var image = new Image()
                image.src = objectURL
                image.onload = function () {
                    props.setImageDimensions({ height: image.naturalHeight, width: image.naturalWidth })
                }
            }

            reader.readAsDataURL(imageInput.files[0])

        }
    }

    return <>
        <UserImageDiv>
            <AvatarWithDefault size={80} src={props.logoUrl} alt={[props.firstName, props.lastName].join(" ") ?? "#"} backgroundSize="cover" />
        </UserImageDiv>
        <>
            <input className="d-none" ref={input => imageInput = input} type="file" value="" onChange={handleProfileImageChange} accept=".jpg,.jpeg,.png" />
            <EditPhotoButton onClick={triggerProfileImageInputClick}>
                {strings.editPhotoMobileButtonTitle}
            </EditPhotoButton>
        </>
    </>
})

interface ProfileInformationProps {
    required?: boolean
    labelText: string
    value: string
    setValue: (value: string) => void
    fieldMarker: Field
    placeholder: string
    columnXs?: number
    columnMd?: number
}



const ProfileInformation: React.FunctionComponent<ProfileInformationProps> = React.memo((props: ProfileInformationProps) => {

    const required = props.required || branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)
    const disabled = branding.myProfilePageContent.editDisabledFields.includes(props.fieldMarker)

    return <EditProfileInformationField>
        <EditProfileLabel>
            {props.labelText} {(required && !disabled) && <span>*</span>}
        </EditProfileLabel>

        <TextField
            disabled={branding.myProfilePageContent.editDisabledFields.includes(props.fieldMarker)}
            width="100%"
            height="40px"
            fontSize="14px"
            paddingLeft="8px"
            textColor={branding.mainInfoColor}
            fontFamily={branding.font1}
            value={props.value}
            setValue={props.setValue}
            placeholder={props.placeholder}
            backgroundColor={branding.myProfilePageContent.textFieldsBackgroundColor}
            borderAround={"1px solid " + branding.myProfilePageContent.textFieldsBorderColor}
            outline="1px solid"
            borderRadius={"5px"}
        />
    </EditProfileInformationField>
})

export interface SocialMediaProps {
    labelText: string
    type: SocialMediaType
    link: string
    setLink: (value: string) => void
    icon: any
}

const SocialMediaItem: React.FunctionComponent<SocialMediaProps> = React.memo((props: SocialMediaProps) => {

    return <EditProfileInformationField>
        <div style={{ display: "inline-block", width: "20px", marginRight: "20px" }}>
            {props.icon}
        </div>
        <div style={{ display: "inline-block", width: "calc(100% - 45px)" }}>
            <TextField
                disabled={branding.myProfilePageContent.editDisabledSocialMedia.includes(props.type)}
                width="100%"
                height="30px"
                fontSize="14px"
                fontFamily={branding.mainInfoColor}
                value={props.link}
                borderColor="gray"
                setValue={props.setLink}
                placeholder=""
                isSearchBar={false}
                isEditProfile={true}
            />
        </div>
    </EditProfileInformationField>
})

interface ProfileBiographyProps {
    labelText: string
    biography: string
    setBiography: (value: string) => void
    placeholder: string
    fieldMarker: Field
}

const ProfileBiography: React.FunctionComponent<ProfileBiographyProps> = React.memo((props: ProfileBiographyProps) => {
    const maxTextLength: number = 1000
    const required = branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)

    return <EditProfileInformationField>
        <EditProfileLabel>
            {props.labelText} {required && <span>*</span>}
        </EditProfileLabel>
        <MultiLineTextField
            width="100%"
            height="129px"
            fontSize="14px"
            textColor={branding.mainInfoColor}
            fontFamily={branding.font1}
            value={props.biography}
            setValue={props.setBiography}
            placeholder={props.placeholder}
            rows={6}
            backgroundColor="#F6F6F6"
            borderAround="1px solid gray"
            outline={"1px solid " + branding.mainInfoColor}
            maxLength={maxTextLength}
            zIndex={0} />

        <TextLimit textLength={props.biography.length || 0} maxTextLength={maxTextLength} />
    </EditProfileInformationField>
})

export const SelectThemeCustom = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 14px;
    margin-top: 13px;
    height: 40px;
    /* z-index: 100; // causing problem with dropdowns overlay */
`

interface CountrySwitcherProps {
    country: string
    setCountry: (value: string) => void
}

const CountrySwitcher: React.FunctionComponent<CountrySwitcherProps> = React.memo((props: CountrySwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const countries = getCountries(lang)
    const countriesFiltered = countries.map(c => { return { label: lang === "de" ? c.de : c.en, value: c.code } })

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.COUNTRY)

    return <EditProfileInformationField>
        <EditProfileLabel>
            {strings.myProfilePageContent.countryLabel} {required && <span>*</span>}
        </EditProfileLabel>
        <StyledSelect
            placeholder={strings.myProfilePageContent.countrySwitcherText}
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            options={countriesFiltered}
            value={props.country !== "" ? { label: findCountryName(props.country, lang), value: props.country } : null}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (props.country !== option.value)
                        props.setCountry(option.value)
                } else
                    props.setCountry("")
            }}
            theme={SelectThemeCustom}
        />
    </EditProfileInformationField>
})

interface LanguageSwitcherProps {
    languages: any[]
    setLanguages: (value: any[]) => void
}

const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = React.memo((props: LanguageSwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const languages = getLanguages(lang)
    const languagesFiltered = languages.map(lng => { return { label: lang === "de" ? lng.de : lng.en, value: lng.code } })
    const [selectedLanguages, setSelectedLanguages] = useState<(OptionTypeBase | null)[]>(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.LANGUAGE)

    useEffect(() => {
        setSelectedLanguages(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))
    }, [lang]) // eslint-disable-line 


    return <EditProfileInformationField>
        <EditProfileLabel>
            {strings.myProfilePageContent.languagesLabel} {required && <span>*</span>}
        </EditProfileLabel>

        <StyledSelect
            placeholder={strings.myProfilePageContent.languagesSwitcherText}
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            options={languagesFiltered}
            value={selectedLanguages}
            onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                if (value !== null) {
                    const newLanguages = value.map(x => { return (x as OptionTypeBase).value })
                    setSelectedLanguages(value)
                    props.setLanguages(newLanguages)
                }
            }}
            theme={SelectThemeCustom}
        />
    </EditProfileInformationField>
})

const TimeZoneSwitcher: React.FunctionComponent = React.memo(() => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    const timezones = getTimezones()
    const timezonesCustom = timezones.map(t => { return { label: t.name.slice(0, t.name.indexOf(" - ") || 150), value: t.timezones[0] } })
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone ?? moment.tz.guess())
    const timezoneNameFull = findTimezoneName(selectedTimezone)
    const timezoneName = timezoneNameFull.slice(0, timezoneNameFull.indexOf(" - ") || 150)
    const selectedTimezoneCustom = { label: timezoneName, value: selectedTimezone }

    function onTimezoneSelect(timezone: string) {
        setSelectedTimezone(timezone)
        appState.setTimeZone(timezone)
    }

    return <EditProfileInformationField>
        <EditProfileLabel>
            {strings.myProfilePageContent.timeZoneLabel}
        </EditProfileLabel>

        <StyledSelect
            placeholder={timezoneName}
            isMulti={false}
            isSearchable={true}
            options={timezonesCustom}
            value={selectedTimezoneCustom}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (selectedTimezone !== option.value)
                        onTimezoneSelect(option.value)
                }
            }}
            theme={SelectThemeCustom}
        />
    </EditProfileInformationField>
})

interface ContactSocialsSectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
}

const DisplayProfileLangLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-right: 30px;
    display: inline-block;
`

const DisplayProfileLangSelect = styled(Select)`
    width: 190px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 12px;
    height: 35px;
    display: inline-block;
`

const ContactSection: React.FunctionComponent<ContactSocialsSectionProps> = (props: ContactSocialsSectionProps) => {
    let userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const [firstName, setFirstName] = useState<string>(userState.user()?.firstName || "")
    const [lastName, setLastName] = useState<string>(userState.user()?.lastName || "")
    const [logoUrl, setLogoUrl] = useState<string>(userState.user()?.logoUrl || "")
    const [company, setCompany] = useState<string>(userState.user()?.company || "")
    const [position, setPosition] = useState<string>(userState.user()?.position || "")
    const [positionDe, setPositionDe] = useState<string>(userState.user()?.positionDe || "")
    const [biography, setBiography] = useState<string>(userState.user()?.infotext || "")
    const [biographyDe, setBiographyDe] = useState<string>(userState.user()?.infotextDe || "")
    const [industry, setIndustry] = useState<string>(userState.user()?.industry || "")
    const [industryDe, setIndustryDe] = useState<string>(userState.user()?.industryDe || "")
    const [country, setCountry] = useState<string>(userState.user()?.countrycode || "")
    const [languages, setLanguages] = useState<any[]>(userState.user()?.languages || [])

    const [mobile, setMobile] = useState<string>(userState.user()?.mobile || "")
    const [phone, setPhone] = useState<string>(userState.user()?.phone || "")
    const [adress1, setAdress1] = useState<string>(userState.user()?.adress1 || "")
    const [adress2, setAdress2] = useState<string>(userState.user()?.adress2 || "")
    const [adress3, setAdress3] = useState<string>(userState.user()?.adress3 || "")
    const [city, setCity] = useState<string>(userState.user()?.city || "")
    const [postalCode, setPostalCode] = useState<string>(userState.user()?.postalCode || "")

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)
    const [imageFile, setImageFile] = useState<any>(null)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [isProfileImageChanged, setIsProfileImageChanged] = useState<boolean>(false)
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false)

    const [basicInfoLang, setBasicInfoLang] = useState<string>(lang)


    const [alertMessage, setAlertMessage] = useState<string>("")

    function isImageSizeValid(): boolean {
        const minHeight = branding.myProfilePageContent.minPictureHeight ?? 500
        const minWidth = branding.myProfilePageContent.minPictureWidth ?? 500

        if (imageSize > 1000000 || imageDimensions.height < minHeight || imageDimensions.width < minWidth) {
            return false
        }
        return true
    }

    function trimValues() {
        setFirstName(firstName.trim())
        setLastName(lastName.trim())
        setCompany(company.trim())
        setPosition(position.trim())
        setPositionDe(positionDe.trim())
        setBiography(biography.trim())
        setBiographyDe(biographyDe.trim())
        setIndustry(industry.trim())
        setIndustryDe(industryDe.trim())
        setMobile(mobile.trim())
        setPhone(phone.trim())
        setAdress1(adress1.trim())
        setAdress2(adress2.trim())
        setAdress3(adress3.trim())
        setCity(city.trim())
        setPostalCode(postalCode.trim())
    }

    function areRequiredFieldsEmpty(): boolean {

        if (firstName.length === 0 || lastName.length === 0) {
            return true
        }

        const list = branding.myProfilePageContent.editRequiredFields

        if (list.includes(Field.POSITION) && position.length === 0) {
            return true
        }

        if (list.includes(Field.POSITION_DE) && positionDe.length === 0) {
            return true
        }

        if (list.includes(Field.INDUSTRY) && industry.length === 0) {
            return true
        }

        if (list.includes(Field.INDUSTRY_DE) && industryDe.length === 0) {
            return true
        }

        if (list.includes(Field.COMPANY) && company.length === 0) {
            return true
        }

        if (list.includes(Field.COUNTRY) && country.length === 0) {
            return true
        }

        if (list.includes(Field.LANGUAGE) && languages.length === 0) {
            return true
        }

        if (list.includes(Field.PHONE) && phone.length === 0) {
            return true
        }

        if (list.includes(Field.MOBILE) && mobile.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS1) && adress1.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS2) && adress2.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS3) && adress3.length === 0) {
            return true
        }

        if (list.includes(Field.CITY) && city.length === 0) {
            return true
        }

        if (list.includes(Field.BIOGRAPHY) && biography.length === 0) {
            return true
        }

        if (list.includes(Field.BIOGRAPHY_DE) && biographyDe.length === 0) {
            return true
        }

        return false
    }

    function updateValues() {
        const payload: any = {
            ...userState.user(),
            id: userState.user()?.profileId,
            firstName: firstName,
            lastName: lastName,
            logoUrl: logoUrl,
            company: company,
            position: position,
            positionDe: positionDe,
            languages: languages,
            industry: industry,
            industryDe: industryDe,
            countrycode: country,
            infotext: biography,
            infotextDe: biographyDe,
            mobile: mobile,
            phone: phone,
            adress1: adress1,
            adress2: adress2,
            adress3: adress3,
            city: city,
            postalCode: postalCode
        }



        updateMyProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: user.presence, lastConnected: new Date().toISOString() })

            setIsLoaded(true)
            setSuccess(true)
            setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setAlertMessage("")
        setIsLoaded(false)

        if (areRequiredFieldsEmpty()) {
            setSuccess(false)
            setIsLoaded(true)


            setAlertMessage(strings.myProfilePageContent.requiredFieldsEmptyWarning)

            return
        }

        if (isProfileImageChanged) {
            if (!isImageSizeValid()) {
                setSuccess(false)
                setIsLoaded(true)


                setAlertMessage(strings.myProfilePageContent.pictureSizeWarning)
                return
            }
        }


        if (isProfileImageDeleted) {
            userState.setProfileImage("")
            setLogoUrl("")

            deleteProfileImage(userState.user()?.profileId || "").then(res => {
                userState.setProfileImage("")
                setLogoUrl("")
                updateValues()
            }).catch(error => {
                logger.error({ message: "EditMyProfileLayout Error", errorMessage: error.message, errorStack: error.stack });
            })

            return
        }

        else if (isProfileImageChanged) {
            if (imageFile && imageFile !== null) {
                let imageData = new FormData()
                imageData.append("image", imageFile)

                changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData }).then(res => {
                    if (res.logoUrl) {
                        userState.setProfileImage(res.logoUrl || '')
                        setLogoUrl(res.logoUrl)

                        updateValues()
                    }
                }).catch(error => {
                    logger.error({ message: "EditMyProfileLayout Profile Logo Upload failed", errorMessage: error.message, errorStack: error.stack });
                })
            }

            return
        }

        else {
            updateValues()
        }
    }

    return <ProfileSectionRoot style={{ paddingBottom: "60px" }}>
        <ProfileImage
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            setIsProfileImageDeleted={setIsProfileImageDeleted}
            setIsProfileImageChanged={setIsProfileImageChanged}
            setImageDimensions={setImageDimensions}
            setImageSize={setImageSize}
            setImageFile={setImageFile}
        />

        <Row style={{ marginLeft: "18px", marginTop: "25px" }}>
            <DisplayProfileLangLabel>
                {strings.myProfilePageContent.displayProfileLangLabel}
            </DisplayProfileLangLabel>

            <DisplayProfileLangSelect
                options={[
                    { label: "English", value: "en" },
                    { label: "Deutsch", value: "de" }
                ]}
                value={{ label: basicInfoLang === "de" ? "Deutsch" : "English", value: basicInfoLang }}
                onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                    if (value !== null) {
                        const option = value as OptionTypeBase

                        setBasicInfoLang(option.value)
                    }
                }}
                theme={SelectThemeCustom}
            />
        </Row>
        {
            branding.myProfilePageContent.editProfileFieldsList.map((item, index) => {
                switch (item) {
                    case Field.FIRSTNAME: {
                        return <ProfileInformation
                            required={true}
                            labelText={strings.myProfilePageContent.nameLabel}
                            placeholder={strings.myProfilePageContent.namePlaceholder}
                            value={firstName}
                            setValue={setFirstName}
                            fieldMarker={Field.FIRSTNAME}
                        />
                    }

                    case Field.LASTNAME: {
                        return <ProfileInformation
                            required={true}

                            labelText={strings.myProfilePageContent.surnameLabel}
                            placeholder={strings.myProfilePageContent.surnamePlaceholder}
                            value={lastName}
                            setValue={setLastName}
                            fieldMarker={Field.LASTNAME}
                        />
                    }

                    case Field.POSITION: {
                        return <>
                            {
                                basicInfoLang === "en" ?
                                    <ProfileInformation
                                        labelText={strings.myProfilePageContent.jobTitleLabel}
                                        placeholder={strings.myProfilePageContent.jobTitlePlaceholder}
                                        value={position}
                                        setValue={setPosition}
                                        fieldMarker={Field.POSITION}
                                    /> :
                                    <ProfileInformation
                                        labelText={strings.myProfilePageContent.jobTitleDeLabel}
                                        placeholder={strings.myProfilePageContent.jobTitleDePlaceholder}
                                        value={positionDe}
                                        setValue={setPositionDe}
                                        fieldMarker={Field.POSITION}
                                    />
                            }
                        </>
                    }

                    case Field.INDUSTRY: {
                        return <>
                            {
                                basicInfoLang === "en" ?
                                    <ProfileInformation
                                        labelText={strings.myProfilePageContent.industryLabel}
                                        placeholder={strings.myProfilePageContent.industryPlaceholder}
                                        value={industry}
                                        setValue={setIndustry}
                                        fieldMarker={Field.INDUSTRY}
                                    /> :
                                    <ProfileInformation
                                        labelText={strings.myProfilePageContent.industryDeLabel}
                                        placeholder={strings.myProfilePageContent.industryDePlaceholder}
                                        value={industryDe}
                                        setValue={setIndustryDe}
                                        fieldMarker={Field.INDUSTRY_DE}
                                    />
                            }
                        </>

                    }

                    case Field.COMPANY: {
                        return <ProfileInformation
                            required={true}
                            labelText={strings.myProfilePageContent.companyLabel}
                            placeholder={strings.myProfilePageContent.companyPlaceholder}
                            value={company}
                            setValue={setCompany}
                            fieldMarker={Field.COMPANY}
                        />
                    }

                    case Field.COUNTRY: {
                        return <CountrySwitcher country={country} setCountry={setCountry} />
                    }

                    case Field.LANGUAGE: {
                        return <LanguageSwitcher languages={languages} setLanguages={setLanguages} />
                    }

                    case Field.TIMEZONE: {
                        return <TimeZoneSwitcher />
                    }

                    case Field.PHONE: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.phoneLabel}
                            placeholder={strings.myProfilePageContent.phonePlaceholder}
                            value={phone}
                            setValue={setPhone}
                            fieldMarker={Field.PHONE}
                        />
                    }

                    case Field.MOBILE: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.mobileLabel}
                            placeholder={strings.myProfilePageContent.mobilePlaceholder}
                            value={mobile}
                            setValue={setMobile}
                            fieldMarker={Field.PHONE}
                        />
                    }

                    case Field.ADRESS1: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.adress1Label}
                            placeholder={strings.myProfilePageContent.adress1Placeholder}
                            value={adress1}
                            setValue={setAdress1}
                            fieldMarker={Field.ADRESS1}
                        />
                    }

                    case Field.ADRESS2: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.adress2Label}
                            placeholder={strings.myProfilePageContent.adress2Placeholder}
                            value={adress2}
                            setValue={setAdress2}
                            fieldMarker={Field.ADRESS2}
                        />
                    }

                    case Field.ADRESS3: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.adress3Label}
                            placeholder={strings.myProfilePageContent.adress3Placeholder}
                            value={adress3}
                            setValue={setAdress3}
                            fieldMarker={Field.ADRESS3}
                        />
                    }

                    case Field.CITY: {
                        return <ProfileInformation
                            labelText={strings.myProfilePageContent.cityLabel}
                            placeholder={strings.myProfilePageContent.cityPlaceholder}
                            value={city}
                            setValue={setCity}
                            fieldMarker={Field.CITY}
                        />
                    }

                    case Field.BIOGRAPHY: {
                        return <>
                            {
                                basicInfoLang === "en" ?
                                    <ProfileBiography
                                        labelText={strings.myProfilePageContent.biographyLabel}
                                        placeholder={strings.myProfilePageContent.biographyPlaceholder}
                                        biography={biography}
                                        setBiography={setBiography}
                                        fieldMarker={Field.BIOGRAPHY}
                                    /> :
                                    <ProfileBiography
                                        labelText={strings.myProfilePageContent.biographyDeLabel}
                                        placeholder={strings.myProfilePageContent.biographyDePlaceholder}
                                        biography={biographyDe}
                                        setBiography={setBiographyDe}
                                        fieldMarker={Field.BIOGRAPHY_DE}
                                    />
                            }
                        </>
                    }

                    default: return null
                }
            })
        }

        <SaveChangesButtonContainer>
            <div style={{ height: "40px", marginTop: "20px", marginBottom: "10px" }}>
                <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                    <SaveLoader />
                </div>
            </div>
            <ErrorMessageContainer success={success}>
                {alertMessage}
            </ErrorMessageContainer>
            <SaveChangesButton onClick={() => {
                trimValues()
                handleDataChange()
            }}>
                {strings.myProfilePageContent.saveChangesMobileTitle}
            </SaveChangesButton>
        </SaveChangesButtonContainer>

    </ProfileSectionRoot>
}


const SocialsSection: React.FunctionComponent<ContactSocialsSectionProps> = (props: ContactSocialsSectionProps) => {
    let userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    const [facebook, setFacebook] = useState<string>(userState.user()?.facebook || "")
    const [twitter, setTwitter] = useState<string>(userState.user()?.twitter || "")
    const [linkedIn, setLinkedIn] = useState<string>(userState.user()?.linkedIn || "")
    const [xing, setXing] = useState<string>(userState.user()?.xing || "")
    const [instagram, setInstagram] = useState<string>(userState.user()?.instagram || "")
    const [youTube, setYouTube] = useState<string>(userState.user()?.youTube || "")
    const [pinterest, setPinterest] = useState<string>(userState.user()?.pinterest || "")

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    function updateValues() {
        const payload: any = {
            ...userState.user(),
            facebook: facebook,
            twitter: twitter,
            linkedIn: linkedIn,
            xing: xing,
            instagram: instagram,
            youTube: youTube,
            pinterest: pinterest
        }

        updateMyProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: user.presence, lastConnected: new Date().toISOString() })

            setIsLoaded(true)
            setSuccess(true)
            setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setAlertMessage("")
        setIsLoaded(false)

        updateValues()
    }

    return <ProfileSectionRoot>
        <EditProfileInformationField >
            <EditProfileLabel>
                {strings.myProfilePageContent.socialMediaSectionTitle}
            </EditProfileLabel>
            {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {

                if (item === SocialMediaType.FACEBOOK) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.facebookLinkLabel}
                        type={SocialMediaType.FACEBOOK}
                        link={facebook}
                        setLink={setFacebook}
                        icon={IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.TWITTER) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.twitterLinkLabel}
                        type={SocialMediaType.TWITTER}
                        link={twitter}
                        setLink={setTwitter}
                        icon={IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.LINKEDIN) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.linkedInLinkLabel}
                        type={SocialMediaType.LINKEDIN}
                        link={linkedIn}
                        setLink={setLinkedIn}
                        icon={IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.XING) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.xingLinkLabel}
                        type={SocialMediaType.XING}
                        link={xing}
                        setLink={setXing}
                        icon={IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.INSTAGRAM) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.instagramLinkLabel}
                        type={SocialMediaType.INSTAGRAM}
                        link={instagram}
                        setLink={setInstagram}
                        icon={IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.YOUTUBE) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.youTubeLinkLabel}
                        type={SocialMediaType.YOUTUBE}
                        link={youTube}
                        setLink={setYouTube}
                        icon={IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                if (item === SocialMediaType.PINTEREST) {
                    return <SocialMediaItem
                        labelText={strings.myProfilePageContent.pinterestLinkLabel}
                        type={SocialMediaType.PINTEREST}
                        link={pinterest}
                        setLink={setPinterest}
                        icon={IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                }

                return null
            })
            }
        </EditProfileInformationField>

        <SaveChangesButtonContainer>
            <div style={{ height: "40px", marginTop: "20px", marginBottom: "10px" }}>
                <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                    <SaveLoader />
                </div>
            </div>
            <ErrorMessageContainer success={success}>
                {alertMessage}
            </ErrorMessageContainer>
            <SaveChangesButton onClick={() => {
                handleDataChange()
            }}>
                {strings.myProfilePageContent.saveChangesMobileTitle}
            </SaveChangesButton>
        </SaveChangesButtonContainer>
    </ProfileSectionRoot>
}
interface SpecialSearchBarProps {
    title: string
    searchParam: string
    setSearchParam: (value: string) => void
    dropdownSearch?: boolean
    dropdownOnClick?: () => void
}

const SpecialSearchBar: React.FunctionComponent<SpecialSearchBarProps> = React.memo((props: SpecialSearchBarProps) => {
    const [iconColor, setIconColor] = useState<string>("gray")

    return <div onMouseEnter={() => setIconColor(branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor)}
        onMouseLeave={() => {
            if (props.searchParam.length <= 0)
                setIconColor("gray")
        }}>
        <TextField
            placeholder={props.title}
            value={props.searchParam}
            width="100%"
            fontSize="14px"
            height="40px"
            paddingLeft="8px"
            setValue={props.setSearchParam}
            backgroundColor={branding.myProfilePageContent.textFieldsBackgroundColor}
            borderAround={"1px solid " + branding.myProfilePageContent.textFieldsBorderColor}
            outline="1px solid"

            startAdornment={<div style={{ marginBottom: '5px' }}>
                {!props.dropdownSearch && IconSearchSmall({ width: "20", height: "20", fill: iconColor })}
            </div>}
            borderRadius={"5px"}
        />
    </div>
})

const WhiteButton = styled(Button)`
    background-color: #fff !important;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'} !important;
    box-sizing: border-box;
    padding: 0.55rem 1.5rem;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: ${branding.primaryColor} !important;
    user-select: none;
    border-radius: 1.25rem;
    margin-left: 10px;
    text-align: center;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;

    &:active, &:focus, &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: ${branding.primaryColor} !important;
        background-color: #fff;
        border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    }
`

const InterestsViewRow = styled(Row)`
    display: flex;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const ProfileCategoriesList: React.FunctionComponent<ProfileCategoriesListProps> = React.memo((props: ProfileCategoriesListProps) => {
    const [showMore, setShowMore] = useState<boolean>(false)

    return <Col xs={24} md={12}>
        {((showMore || props.searchParam.length > 0) ? props.items : props.items.slice(0, branding.myProfilePageContent.numberOfCategories)).map((category, key) => {
            return (
                <InterestItem
                    borderColor="#727272"
                    textColor="#727272"
                    key={key}
                    onClick={() => props.addToMyCategories(category)}
                    style={{
                        pointerEvents: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "none" : "auto",
                        cursor: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "not-allowed" : "pointer"
                    }}>
                    {category.name}
                </InterestItem>
            );
        })
        }
        {(!showMore && props.searchParam.length === 0 && props.items.length > branding.myProfilePageContent.numberOfCategories) &&
            <WhiteButton onClick={() => setShowMore(true)}>
                {props.strings.showMoreButtonText}
            </WhiteButton>
        }
        {(showMore && props.searchParam.length === 0) &&
            <WhiteButton onClick={() => setShowMore(false)}>
                {props.strings.showLessButtonText}
            </WhiteButton>
        }
    </Col>
})

const LabelText = styled.p`
    margin-top: 30px;
    font-size: 13px;
    font-weight: bold;
    line-height: 14px;
    margin-left: 7px;
    font-family: ${branding.font1};
`

const ProfileCategories: React.FunctionComponent<ProfileCategoriesProps> = React.memo((props: ProfileCategoriesProps) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent

    const [searchParam, setSearchParam] = useState<string>("")

    let availableCategoriesToShow: Category[];
    let workingDataSet: Category[] = [];
    let selectedDataSet: Category[] = [];

    useEffect(() => {
        if (props.setSectionTitle) {
            props.setSectionTitle(getCategorySectionTitle(strings, props.type) +
                (props.limitNumberOfSelectedCategories ? " (" + (selectedDataSet.length || 0) + "/" + getLimitNumber() + ")"
                    : ""))
        }
        // eslint-disable-next-line
    }, [selectedDataSet])

    function addToMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet.push(category)
        props.setCategoryBindings(selectedDataSet)
    }

    function removeFromMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet = selectedDataSet.filter(i => i !== category)
        props.setCategoryBindings(selectedDataSet)
    }

    function getLimitNumber(): number {
        if (props.type === CategoryType.OFFERS) {
            return branding.myProfilePageContent.numberOfOffers
        }
        else if (props.type === CategoryType.NEEDS) {
            return branding.myProfilePageContent.numberOfNeeds
        }
        else {
            return 5
        }
    }



    switch (props.type) {
        case CategoryType.INTERESTS:
            workingDataSet = props.data.interests
            selectedDataSet = props.data.selectedInterests
            break
        case CategoryType.OFFERS:
            workingDataSet = props.data.offerings
            selectedDataSet = props.data.selectedOfferings
            break
        case CategoryType.NEEDS:
            workingDataSet = props.data.lookingfor
            selectedDataSet = props.data.selectedLookingfor
            break
    }
    workingDataSet = workingDataSet.filter(i => !selectedDataSet.includes(i));

    availableCategoriesToShow = workingDataSet.filter((i: any) => i.name.toLowerCase().startsWith(searchParam.toLowerCase()))

    return <Row className="ml-20">
        <Col xs={24} md={12}>
            {(workingDataSet.length > 0 || selectedDataSet.length > 0) &&
                <>
                    <div>
                        <InterestsViewRow key="selected-categories">
                            <Col xs={24} md={12}>
                                {selectedDataSet.map((category, key) => {
                                    return (<>
                                        <InterestItem
                                            key={key}
                                            onClick={() => removeFromMyCategories(selectedDataSet, category)}>
                                            {category.name}
                                        </InterestItem>

                                    </>
                                    );
                                })
                                }
                            </Col>
                        </InterestsViewRow>

                        <Row key="search">
                            <Col xs={24} md={12}>
                                <div style={{ marginLeft: "5px", marginBottom: "30px" }}>
                                    <SpecialSearchBar searchParam={searchParam} setSearchParam={setSearchParam} title={strings.searchPlaceholder} />

                                </div>
                            </Col>
                        </Row>

                        {
                            props.type === CategoryType.INTERESTS &&
                            <InterestsViewRow key="available-categories">
                                <ProfileCategoriesList
                                    items={availableCategoriesToShow}
                                    searchParam={searchParam}
                                    isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                    limitNumber={getLimitNumber()}
                                    selectedCategoriesNumber={selectedDataSet.length}
                                    addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                    strings={strings}
                                />
                            </InterestsViewRow>
                        }

                        {
                            (props.type === CategoryType.OFFERS || props.type === CategoryType.NEEDS) &&
                            <InterestsViewRow key="available-categories">
                                {branding.myProfilePageContent.offersNeedsCategories.length > 0 &&
                                    branding.myProfilePageContent.offersNeedsCategories.filter(x => x.id !== "default").map((parentCategory, key) => {
                                        let parentCategoryItems = availableCategoriesToShow.filter((i: any) => i.parent === parentCategory.id)
                                        return <div key={key}>
                                            {parentCategoryItems.length > 0 &&
                                                <>
                                                    <LabelText style={{ marginLeft: "20px", fontWeight: "normal" }}>
                                                        {parentCategory.name}
                                                    </LabelText>

                                                    <ProfileCategoriesList
                                                        items={parentCategoryItems}
                                                        searchParam={searchParam}
                                                        isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                                        limitNumber={getLimitNumber()}
                                                        selectedCategoriesNumber={selectedDataSet.length}
                                                        addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                                        strings={strings}
                                                    />
                                                </>
                                            }
                                        </div>

                                    })
                                }
                                {branding.myProfilePageContent.offersNeedsCategories.length === 0 &&
                                    <InterestsViewRow key="available-categories">
                                        <ProfileCategoriesList
                                            items={availableCategoriesToShow}
                                            searchParam={searchParam}
                                            isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                            limitNumber={getLimitNumber()}
                                            selectedCategoriesNumber={selectedDataSet.length}
                                            addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                            strings={strings}
                                        />
                                    </InterestsViewRow>
                                }
                            </InterestsViewRow>
                        }


                    </div>
                </>
            }
        </Col>
    </Row >
})

interface CategoriesSectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    categoryData: CategoryData
}

const InterestsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    const strings = useLanguageState().getStrings()
    const [categoryData, setCategoryData] = useState<CategoryData>(props.categoryData)
    const userState = useLoggedInState()

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    useEffect(() => {
        setCategoryData(props.categoryData)
    }, [props.categoryData])

    function updateValues() {
        const payload: any = {
            ...userState.user(),
            interests: categoryData.selectedInterests.map(i => i.id)
        }

        updateMyProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: user.presence, lastConnected: new Date().toISOString() })

            setIsLoaded(true)
            setSuccess(true)
            setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setAlertMessage("")
        setIsLoaded(false)

        updateValues()
    }

    return <ProfileSectionRoot>
        <EditProfileInformationField>
            <EditProfileCategoryListLabel>
                {strings.myProfilePageContent.interestsLabel}
            </EditProfileCategoryListLabel>
            <ProfileCategories type={CategoryType.INTERESTS} setCategoryBindings={(newInterests) => setCategoryData({ ...categoryData, selectedInterests: newInterests })} data={categoryData} required={branding.myProfilePageContent.interestsRequired} />
        </EditProfileInformationField>

        <SaveChangesButtonContainer>
            <div style={{ height: "40px", marginTop: "20px", marginBottom: "10px" }}>
                <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                    <SaveLoader />
                </div>
            </div>
            <ErrorMessageContainer success={success}>
                {alertMessage}
            </ErrorMessageContainer>
            <SaveChangesButton onClick={() => {
                handleDataChange()
            }}>
                {strings.myProfilePageContent.saveChangesMobileTitle}
            </SaveChangesButton>
        </SaveChangesButtonContainer>

    </ProfileSectionRoot>
}

const OffersNeedsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()

    const [offersSectionTitle, setOffersSectionTitle] = useState<string>(strings.myProfilePageContent.imOfferingLabel)

    const [needsSectionTitle, setNeedsSectionTitle] = useState<string>(strings.myProfilePageContent.imLookingToBuyLabel)

    const [categoryData, setCategoryData] = useState<CategoryData>(props.categoryData)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    useEffect(() => {
        setCategoryData(props.categoryData)
    }, [props.categoryData])

    function updateValues() {
        const payload: any = {
            ...userState.user(),
            lookingfor: categoryData.selectedLookingfor.map(i => i.id),
            offering: categoryData.selectedOfferings.map(i => i.id)
        }

        updateMyProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: user.presence, lastConnected: new Date().toISOString() })

            setIsLoaded(true)
            setSuccess(true)
            setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setAlertMessage("")
        setIsLoaded(false)

        updateValues()
    }

    useEffect(() => {
        setCategoryData(props.categoryData)
    }, [props.categoryData])

    return <ProfileSectionRoot>
        <EditProfileInformationField style={{ borderBottom: branding.sideIconBar.mobileNavigationBorder }}>
            <EditProfileCategoryListLabel>
                {offersSectionTitle}
            </EditProfileCategoryListLabel>
            <ProfileCategories type={CategoryType.OFFERS} setCategoryBindings={(newOffers) => setCategoryData({ ...categoryData, selectedOfferings: newOffers })} data={categoryData} limitNumberOfSelectedCategories={true} setSectionTitle={setOffersSectionTitle} />
        </EditProfileInformationField>

        <EditProfileInformationField >
            <EditProfileCategoryListLabel>
                {needsSectionTitle}
            </EditProfileCategoryListLabel>
            <ProfileCategories type={CategoryType.NEEDS} setCategoryBindings={(newLookingfor) => setCategoryData({ ...categoryData, selectedLookingfor: newLookingfor })} data={categoryData} limitNumberOfSelectedCategories={true} setSectionTitle={setNeedsSectionTitle} />
        </EditProfileInformationField>

        <SaveChangesButtonContainer>
            <div style={{ height: "40px", marginTop: "20px", marginBottom: "10px" }}>
                <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                    <SaveLoader />
                </div>
            </div>
            <ErrorMessageContainer success={success}>
                {alertMessage}
            </ErrorMessageContainer>
            <SaveChangesButton onClick={() => {
                handleDataChange()
            }}>
                {strings.myProfilePageContent.saveChangesMobileTitle}
            </SaveChangesButton>
        </SaveChangesButtonContainer>
    </ProfileSectionRoot>
}


export default MobileEditMyProfileLayout