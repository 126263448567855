import React, { useState, useEffect } from "react"
import { EditProfileProps } from "./MobileMyProfilePageContent"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { PersonImageAndLogo, UserBasicInfoContainer, UserImageDiv, UserFullName, ScrollContainerRoot, ProfileDetailRowHeader, ProfileSocialsTitle, ProfilePageSectionTitle, ProfileDetailNameTeaserCol, CategoriesItem, CategoriesViewRow, DummyDiv } from "../../ui/ProfilePageStyledComponents"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { Row, Col, Button } from "react-bootstrap"
import { useAppState } from "../../globalStates/AppState"
import { useHistory } from "react-router-dom"
import branding from "../../branding/branding"
import { Category } from "../../backendServices/Types"
import { getInterest, updateMyProfileData, changeProfileImage, deleteProfileImage, updateUserValues, BackendServiceError } from "../../backendServices/BackendServices"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { CategoryType, Field, SocialMediaType, EditTab } from "./MyProfilePageContentBranding"
import { myProfilePageRoute } from "../../navigationArea/RoutePaths"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import NetworkingToggle from "../../communicationArea/components/NetworkingToggle"
import { DoNotDisturbToggle } from "../../communicationArea/components/DoNotDisturbToggle"
import styled, { css } from "styled-components"
import CrsTabs from "../../ui/CrsTabs"
import { TextField, MultiLineTextField } from "../../ui/TextField"
import { IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconInstagram, IconYouTube, IconPinterest, IconSearchSmall, IconIndicatorSuccessBlack, IconIndicatorErrorBlack, IconFacebookOriginal, IconTwitterOriginal, IconLinkedInOriginal, IconXingOriginal, IconInstagramOriginal, IconYouTubeOriginal, IconPinterestOrginal } from "../../ui/Icons"
import TextLimit from "../../ui/TextLimit"
import { getCountries, findCountryName } from "./CountrySwitcherHelper"
import Select, { ValueType, OptionTypeBase, ActionMeta } from "react-select"
import { getLanguages, findLanguageName } from "./LanguageSwitcherHelper"
import { getTimezones, findTimezoneName } from "../../utils/DateUtils"
import moment from "moment"
import DataUpdateAlert from "../../ui/DataUpdateAlert"
import CenteredLoader from "../../ui/CenteredLoader"

interface CategoryData {
    interests: Category[]
    offerings: Category[]
    lookingfor: Category[]

    selectedInterests: Category[]
    selectedOfferings: Category[]
    selectedLookingfor: Category[]
}

const TabContentContainer = styled.div`
    margin-left: 25px;
    width: calc(100% - 25px);
`

const DisplayProfileLangLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-right: 30px;
    display: inline-block;
`

const DisplayProfileLangSelect = styled(Select)`
    width: 190px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 12px;
    height: 35px;
    display: inline-block;
`

const SaveLoader = styled(CenteredLoader)`
    height: 35px;
    margin-right: 350px;
`

const SaveCancelContainer = styled.div<{ ccOpen: boolean }>`
top: 80px;
position: fixed;
right: ${props => props.ccOpen ? "350px" : "80px"};
display: flex;
gap: 10px;
z-index: 10001;
`

const EditMyProfileLayout: React.FunctionComponent<EditProfileProps> = (props: EditProfileProps) => {
    let userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const appState = useAppState()

    const [basicInfoLang, setBasicInfoLang] = useState<string>(lang)
    const [firstName, setFirstName] = useState<string>(userState.user()?.firstName || "")
    const [lastName, setLastName] = useState<string>(userState.user()?.lastName || "")
    const [logoUrl, setLogoUrl] = useState<string>(userState.user()?.logoUrl || "")
    const [company, setCompany] = useState<string>(userState.user()?.company || "")
    const [position, setPosition] = useState<string>(userState.user()?.position || "")
    const [positionDe, setPositionDe] = useState<string>(userState.user()?.positionDe || "")
    const [biography, setBiography] = useState<string>(userState.user()?.infotext || "")
    const [biographyDe, setBiographyDe] = useState<string>(userState.user()?.infotextDe || "")
    const [industry, setIndustry] = useState<string>(userState.user()?.industry || "")
    const [industryDe, setIndustryDe] = useState<string>(userState.user()?.industryDe || "")
    const [country, setCountry] = useState<string>(userState.user()?.countrycode || "")
    const [languages, setLanguages] = useState<any[]>(userState.user()?.languages || [])

    const [mobile, setMobile] = useState<string>(userState.user()?.mobile || "")
    const [phone, setPhone] = useState<string>(userState.user()?.phone || "")
    const [adress1, setAdress1] = useState<string>(userState.user()?.adress1 || "")
    const [adress2, setAdress2] = useState<string>(userState.user()?.adress2 || "")
    const [adress3, setAdress3] = useState<string>(userState.user()?.adress3 || "")
    const [city, setCity] = useState<string>(userState.user()?.city || "")
    const [postalCode, setPostalCode] = useState<string>(userState.user()?.postalCode || "")

    const [facebook, setFacebook] = useState<string>(userState.user()?.facebook || "")
    const [twitter, setTwitter] = useState<string>(userState.user()?.twitter || "")
    const [linkedIn, setLinkedIn] = useState<string>(userState.user()?.linkedIn || "")
    const [xing, setXing] = useState<string>(userState.user()?.xing || "")
    const [instagram, setInstagram] = useState<string>(userState.user()?.instagram || "")
    const [youTube, setYouTube] = useState<string>(userState.user()?.youTube || "")
    const [pinterest, setPinterest] = useState<string>(userState.user()?.pinterest || "")

    const [offersSectionTitle, setOffersSectionTitle] = useState<string>(strings.myProfilePageContent.imOfferingLabel)

    const [needsSectionTitle, setNeedsSectionTitle] = useState<string>(strings.myProfilePageContent.imLookingToBuyLabel)

    const [activeTab, setActiveTab] = useState<EditTab>(EditTab.BASIC_INFO)

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [isProfileImageChanged, setIsProfileImageChanged] = useState<boolean>(false)
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    const [imageFile, setImageFile] = useState<any>(null)

    const history = useHistory()

    const windowSize = useWindowDimensions()

    let entityFilterItems: any = []

    const itemsOrder = branding.myProfilePageContent.editProfileTabs

    itemsOrder.forEach((item) => {
        let newItem = {}

        switch (item) {
            case EditTab.BASIC_INFO:
                newItem = { label: strings.myProfilePageContent.basicInfoTabTitle, value: EditTab.BASIC_INFO }
                break

            case EditTab.SOCIAL_MEDIA:
                newItem = { label: strings.myProfilePageContent.socialMediaSectionTitle, value: EditTab.SOCIAL_MEDIA }
                break

            case EditTab.INTERESTS:
                newItem = { label: strings.myProfilePageContent.interestsLabel, value: EditTab.INTERESTS }
                break

            case EditTab.BUY_SELL:
                newItem = { label: strings.myProfilePageContent.buySellTabTitle, value: EditTab.BUY_SELL }
                break
        }

        entityFilterItems.push(newItem)
    });

    function getData() {
        getInterest().then(res => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter(i => (userState.user()?.interests ?? []).includes(i.id))
                const selecteOfferings = offerings.filter(i => (userState.user()?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter(i => (userState.user()?.lookingfor ?? []).includes(i.id))
                setCategoryData(
                    {
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selecteOfferings,
                        selectedLookingfor: selectedLookingfor
                    }
                )
            }
        })
    }

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    function areSocialMediaLinksCorrect(): boolean {
        if (facebook.length > 0 && !isSocialMediaLinkCorrect(facebook, SocialMediaType.FACEBOOK)) {
            return false
        }

        if (twitter.length > 0 && !isSocialMediaLinkCorrect(twitter, SocialMediaType.TWITTER)) {
            return false
        }

        if (linkedIn.length > 0 && !isSocialMediaLinkCorrect(linkedIn, SocialMediaType.LINKEDIN)) {
            return false
        }

        if (xing.length > 0 && !isSocialMediaLinkCorrect(xing, SocialMediaType.XING)) {
            return false
        }

        if (instagram.length > 0 && !isSocialMediaLinkCorrect(instagram, SocialMediaType.INSTAGRAM)) {
            return false
        }

        if (youTube.length > 0 && !isSocialMediaLinkCorrect(youTube, SocialMediaType.YOUTUBE)) {
            return false
        }

        if (pinterest.length > 0 && !isSocialMediaLinkCorrect(pinterest, SocialMediaType.PINTEREST)) {
            return false
        }
        return true
    }
    function isCompanyNameCorrect(companyName: string): boolean {
        return true
        /* TODO
        const companyRegex: RegExp = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/g
        return companyRegex.test(companyName.trim())
        */
    }

    function isNameCorrect(name: string): boolean {
        return true
        /* TODO
        const nameRegex: RegExp = /\d/
        return !(nameRegex.test(name.trim()))
        */
        //checks if name string contains numbers, hence why the opposite value is returned - if string contains numbers, regex return "true", but the function returns "false", meaning that the name is not in the correct format
    }


    function isPhoneNumberCorrect(phoneNumber: string): boolean {
        /* TODO
        if (phoneNumber.length > 0) {
            // eslint-disable-next-line
            const phoneNumberRegex: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
            return phoneNumberRegex.test(phoneNumber.trim())
        }
        */
        return true
    }

    function isPostalCodeCorrect(postalCode: string): boolean {
        /* TODO
        if (postalCode.length > 0) {
            const postalCodeRegex: RegExp = /^\d+$/;
            return (postalCodeRegex.test(postalCode.trim()) && postalCode.trim().length >= 5 && postalCode.trim().length <= 7)
        }
        */
        return true
    }

    // function isAdressCorrect(adress: string): boolean {
    //     /* TODO
    //     if (adress.length > 0) {
    //         return true
    //     }
    //     */
    //     return true
    // }

    // function isCityCorrect(city: string): boolean {
    //     /* TODO
    //     if (city.length > 0) {
    //         return true
    //     }
    //     return false
    //     */
    //     return true
    // }

    function isImageSizeValid(): boolean {
        const minHeight = branding.myProfilePageContent.minPictureHeight ?? 500
        const minWidth = branding.myProfilePageContent.minPictureWidth ?? 500

        if (imageSize > 1000000 || imageDimensions.height < minHeight || imageDimensions.width < minWidth) {
            return false
        }
        return true
    }

    function checkIfInterestsRequiredAndEntered() {
        if (branding.myProfilePageContent.interestsRequired && categoryData.selectedInterests.length > 0)
            return true

        else if (!(branding.myProfilePageContent.interestsRequired))
            return true

        return false
    }

    function areRequiredFieldsEmpty(): boolean {

        if (firstName.length === 0 || lastName.length === 0 || !checkIfInterestsRequiredAndEntered()) {
            return true
        }

        const list = branding.myProfilePageContent.editRequiredFields

        if (list.includes(Field.POSITION) && position.length === 0) {
            return true
        }

        if (list.includes(Field.POSITION_DE) && positionDe.length === 0) {
            return true
        }

        if (list.includes(Field.INDUSTRY) && industry.length === 0) {
            return true
        }

        if (list.includes(Field.INDUSTRY_DE) && industryDe.length === 0) {
            return true
        }

        if (list.includes(Field.COMPANY) && company.length === 0) {
            return true
        }

        if (list.includes(Field.COUNTRY) && country.length === 0) {
            return true
        }

        if (list.includes(Field.LANGUAGE) && languages.length === 0) {
            return true
        }

        if (list.includes(Field.PHONE) && phone.length === 0) {
            return true
        }

        if (list.includes(Field.MOBILE) && mobile.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS1) && adress1.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS2) && adress2.length === 0) {
            return true
        }

        if (list.includes(Field.ADRESS3) && adress3.length === 0) {
            return true
        }

        if (list.includes(Field.CITY) && city.length === 0) {
            return true
        }

        if (list.includes(Field.POSTAL_CODE) && postalCode.length === 0) {
            return true
        }

        if (list.includes(Field.BIOGRAPHY) && biography.length === 0) {
            return true
        }

        if (list.includes(Field.BIOGRAPHY_DE) && biographyDe.length === 0) {
            return true
        }

        return false
    }

    function isValidationCorrect() {
        if (isNameCorrect(firstName) && isNameCorrect(lastName) && isCompanyNameCorrect(company) && isNameCorrect(position) && isNameCorrect(positionDe) && isNameCorrect(industry) && isPhoneNumberCorrect(phone) && isPhoneNumberCorrect(mobile) && areSocialMediaLinksCorrect() && isPostalCodeCorrect(postalCode)) {
            return true
        }

        return false
    }


    function trimValues() {
        setFirstName(firstName.trim())
        setLastName(lastName.trim())
        setCompany(company.trim())
        setPosition(position.trim())
        setPositionDe(positionDe.trim())
        setBiography(biography.trim())
        setBiographyDe(biographyDe.trim())
        setIndustry(industry.trim())
        setIndustryDe(industryDe.trim())
        setMobile(mobile.trim())
        setPhone(phone.trim())
        setAdress1(adress1.trim())
        setAdress2(adress2.trim())
        setAdress3(adress3.trim())
        setCity(city.trim())
        setPostalCode(postalCode.trim())
        setFacebook(facebook.trim())
        setTwitter(twitter.trim())
        setLinkedIn(linkedIn.trim())
        setXing(xing.trim())
        setInstagram(instagram.trim())
        setYouTube(youTube.trim())
        setPinterest(pinterest.trim())
    }

    function resetValues() {
        setFirstName(userState.user()?.firstName || "")
        setLastName(userState.user()?.lastName || "")
        setLogoUrl(userState.user()?.logoUrl || "")
        setCompany(userState.user()?.company || "")
        setPosition(userState.user()?.position || "")
        setPositionDe(userState.user()?.positionDe || "")
        setBiography(userState.user()?.infotext || "")
        setBiographyDe(userState.user()?.infotextDe || "")
        setIndustry(userState.user()?.industry || "")
        setIndustryDe(userState.user()?.industryDe || "")
        setMobile(userState.user()?.mobile || "")
        setPhone(userState.user()?.phone || "")
        setAdress1(userState.user()?.adress1 || "")
        setAdress2(userState.user()?.adress2 || "")
        setAdress3(userState.user()?.adress3 || "")
        setCity(userState.user()?.city || "")
        setPostalCode(userState.user()?.postalCode || "")
        setFacebook(userState.user()?.facebook || "")
        setTwitter(userState.user()?.twitter || "")
        setLinkedIn(userState.user()?.linkedIn || "")
        setXing(userState.user()?.xing || "")
        setInstagram(userState.user()?.instagram || "")
        setYouTube(userState.user()?.youTube || "")
        setPinterest(userState.user()?.pinterest || "")
    }

    function updateValues() {
        const payload: any = {
            id: userState.user()?.profileId,
            firstName: firstName,
            lastName: lastName,
            logoUrl: logoUrl,
            company: company,
            position: position,
            positionDe: positionDe,
            languages: languages,
            areaOfResponsibility: userState.user()?.areaOfResponsibility,
            industry: industry,
            industryDe: industryDe,
            countrycode: country,
            interests: categoryData.selectedInterests.map(i => i.id),
            lookingfor: categoryData.selectedLookingfor.map(i => i.id),
            offering: categoryData.selectedOfferings.map(i => i.id),
            infotext: biography,
            infotextDe: biographyDe,
            mobile: mobile,
            phone: phone,
            adress1: adress1,
            adress2: adress2,
            adress3: adress3,
            city: city,
            postalCode: postalCode,
            facebook: facebook,
            twitter: twitter,
            linkedIn: linkedIn,
            xing: xing,
            instagram: instagram,
            youTube: youTube,
            pinterest: pinterest,
            presence: userState.user()?.presence!,
            matchActive: userState.user()?.matchActive!,
            type: userState.user()?.type!,
            organizations: userState.user()?.organizations ? userState.user()?.organizations!.map(org => {
                return { "id": org.id, "name": org.name }
            }) : [],
            eventDates: userState.user()?.eventDates ? userState.user()?.eventDates!.map(evtd => {
                return { "id": evtd.id, "name": evtd.name }
            }) : [],
            events: userState.user()?.events ? userState.user()?.events!.map(event => {
                return { "id": event.id, "name": event.name }
            }) : [],
            invitingOrganization: userState.user()?.invitingOrganization ? { id: userState.user()?.invitingOrganization?.id, name: userState.user()?.invitingOrganization?.name } : null,
            visible: userState.user()?.visible ?? false,
            showroomStandby: userState.user()?.showroomStandby ?? false
        }

        setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

        updateMyProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)

                setShowAlert(true)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: user.presence, lastConnected: new Date().toISOString() })

            setIsLoaded(true)

            setSuccess(true)
            setShowAlert(true)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
                history.push(myProfilePageRoute)

            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setIsLoaded(false)

        if (areRequiredFieldsEmpty()) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.requiredFieldsEmptyWarning)

            setShowAlert(true)
            return
        }

        if (isProfileImageChanged) {
            if (!isImageSizeValid()) {
                setIsLoaded(true)

                setSuccess(false)
                setAlertMessage(strings.myProfilePageContent.pictureSizeWarning)

                setShowAlert(true)
                return
            }
        }

        if (!isValidationCorrect()) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.validationIncorrectWarning)

            setShowAlert(true)
            return
        }


        if (isProfileImageDeleted) {
            userState.setProfileImage("")
            setLogoUrl("")

            deleteProfileImage(userState.user()?.profileId || "").then(res => {
                userState.setProfileImage("")
                setLogoUrl("")
                updateValues()
            }).catch(error => {
                logger.error({ message: "EditMyProfileLayout Error", errorMessage: error.message, errorStack: error.stack });
            })

            return
        }

        else if (isProfileImageChanged) {
            if (imageFile && imageFile !== null) {
                let imageData = new FormData()
                imageData.append("image", imageFile)

                changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData }).then(res => {
                    if (res.logoUrl) {
                        userState.setProfileImage(res.logoUrl || '')
                        setLogoUrl(res.logoUrl)

                        updateValues()
                    }
                }).catch(error => {
                    logger.error({ message: "EditMyProfileLayout Profile Logo Upload failed", errorMessage: error.message, errorStack: error.stack });
                })
            }

            return
        }

        else {
            updateValues()
        }
    }


    function TabContent() {
        switch (activeTab) {
            case EditTab.BASIC_INFO: {
                return <TabContentContainer>
                    {BasicInfoTab()}
                </TabContentContainer>

            }

            case EditTab.SOCIAL_MEDIA: {
                return <TabContentContainer>
                    {SocialsTab()}
                </TabContentContainer>

            }

            case EditTab.INTERESTS: {
                return <TabContentContainer>
                    {InterestsTab()}
                </TabContentContainer>

            }

            case EditTab.BUY_SELL: {
                return <TabContentContainer>
                    {BuySellTab()}
                </TabContentContainer>

            }

            default: return <div />
        }
    }

    function BasicInfoTab() {
        return <EditProfileInformationField>
            <Row style={{ marginLeft: "0px" }} >

                <DisplayProfileLangLabel>
                    {strings.myProfilePageContent.displayProfileLangLabel}
                </DisplayProfileLangLabel>

                <DisplayProfileLangSelect
                    options={[
                        { label: "English", value: "en" },
                        { label: "Deutsch", value: "de" }
                    ]}
                    value={{ label: basicInfoLang === "de" ? "Deutsch" : "English", value: basicInfoLang }}
                    onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                        if (value !== null) {
                            const option = value as OptionTypeBase

                            setBasicInfoLang(option.value)
                        }
                    }}
                    theme={SelectThemeCustom}
                />
            </Row>
            <Row style={{ marginRight: "5px" }}>
                {
                    branding.myProfilePageContent.editProfileFieldsList.map((item, index) => {
                        switch (item) {
                            case Field.FIRSTNAME: {
                                return <ProfileInformation
                                    required={true}
                                    labelText={basicInfoLang === "de" ? strings.myProfilePageContent.nameLabel : strings.myProfilePageContent.nameLabel}
                                    placeholder={strings.myProfilePageContent.namePlaceholder}
                                    value={firstName}
                                    setValue={setFirstName}
                                    fieldMarker={Field.FIRSTNAME}
                                />
                            }

                            case Field.LASTNAME: {
                                return <ProfileInformation
                                    required={true}

                                    labelText={strings.myProfilePageContent.surnameLabel}
                                    placeholder={strings.myProfilePageContent.surnamePlaceholder}
                                    value={lastName}
                                    setValue={setLastName}
                                    fieldMarker={Field.LASTNAME}
                                />
                            }

                            case Field.POSITION: {
                                return <>
                                    {
                                        basicInfoLang === "en" ?
                                            <ProfileInformation
                                                labelText={strings.myProfilePageContent.jobTitleLabel}
                                                placeholder={strings.myProfilePageContent.jobTitlePlaceholder}
                                                value={position}
                                                setValue={setPosition}
                                                fieldMarker={Field.POSITION}
                                            /> :
                                            <ProfileInformation
                                                labelText={strings.myProfilePageContent.jobTitleDeLabel}
                                                placeholder={strings.myProfilePageContent.jobTitleDePlaceholder}
                                                value={positionDe}
                                                setValue={setPositionDe}
                                                fieldMarker={Field.POSITION}
                                            />
                                    }
                                </>
                            }

                            case Field.INDUSTRY: {
                                return <>
                                    {
                                        basicInfoLang === "en" ?
                                            <ProfileInformation
                                                labelText={strings.myProfilePageContent.industryLabel}
                                                placeholder={strings.myProfilePageContent.industryPlaceholder}
                                                value={industry}
                                                setValue={setIndustry}
                                                fieldMarker={Field.INDUSTRY}
                                            /> :
                                            <ProfileInformation
                                                labelText={strings.myProfilePageContent.industryDeLabel}
                                                placeholder={strings.myProfilePageContent.industryDePlaceholder}
                                                value={industryDe}
                                                setValue={setIndustryDe}
                                                fieldMarker={Field.INDUSTRY_DE}
                                            />
                                    }
                                </>
                            }

                            case Field.COMPANY: {
                                return <ProfileInformation
                                    required={true}
                                    labelText={strings.myProfilePageContent.companyLabel}
                                    placeholder={strings.myProfilePageContent.companyPlaceholder}
                                    value={company}
                                    setValue={setCompany}
                                    fieldMarker={Field.COMPANY}
                                />
                            }

                            case Field.EMAIL: {
                                return <ProfileInformation
                                    required={true}
                                    disabled={true}
                                    labelText={strings.myProfilePageContent.emailLabel}
                                    value={userState.user()?.email!}
                                    setValue={setPostalCode}
                                    placeholder={strings.myProfilePageContent.emailLabel}
                                    fieldMarker={Field.EMAIL}
                                />
                            }

                            case Field.POSTAL_CODE: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.postalCodePlaceholder}
                                    placeholder={strings.myProfilePageContent.postalCodePlaceholder}
                                    value={postalCode}
                                    setValue={setPostalCode}
                                    fieldMarker={Field.POSTAL_CODE}
                                />
                            }

                            case Field.COUNTRY: {
                                return <CountrySwitcher country={country} setCountry={setCountry} />
                            }

                            case Field.LANGUAGE: {
                                return <LanguageSwitcher languages={languages} setLanguages={setLanguages} />
                            }

                            case Field.TIMEZONE: {
                                return <TimeZoneSwitcher />
                            }

                            case Field.PHONE: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.phoneLabel}
                                    placeholder={strings.myProfilePageContent.phonePlaceholder}
                                    value={phone}
                                    setValue={setPhone}
                                    fieldMarker={Field.PHONE}
                                />
                            }

                            case Field.MOBILE: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.mobileLabel}
                                    placeholder={strings.myProfilePageContent.mobilePlaceholder}
                                    value={mobile}
                                    setValue={setMobile}
                                    fieldMarker={Field.PHONE}
                                />
                            }

                            case Field.ADRESS1: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.adress1Label}
                                    placeholder={strings.myProfilePageContent.adress1Placeholder}
                                    value={adress1}
                                    setValue={setAdress1}
                                    fieldMarker={Field.ADRESS1}
                                />
                            }

                            case Field.ADRESS2: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.adress2Label}
                                    placeholder={strings.myProfilePageContent.adress2Placeholder}
                                    value={adress2}
                                    setValue={setAdress2}
                                    fieldMarker={Field.ADRESS2}
                                />
                            }

                            case Field.ADRESS3: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.adress3Label}
                                    placeholder={strings.myProfilePageContent.adress3Placeholder}
                                    value={adress3}
                                    setValue={setAdress3}
                                    fieldMarker={Field.ADRESS3}
                                />
                            }

                            case Field.CITY: {
                                return <ProfileInformation
                                    labelText={strings.myProfilePageContent.cityLabel}
                                    placeholder={strings.myProfilePageContent.cityPlaceholder}
                                    value={city}
                                    setValue={setCity}
                                    fieldMarker={Field.CITY}
                                />
                            }

                            default: return null
                        }
                    })
                }
            </Row>
            <Row style={{ marginRight: "5px", marginTop: "25px" }}>
                {
                    branding.myProfilePageContent.editProfileFieldsList.includes(Field.BIOGRAPHY) &&
                    <>
                        {
                            basicInfoLang === "en" ?
                                <ProfileBiography
                                    labelText={strings.myProfilePageContent.biographyLabel}
                                    placeholder={strings.myProfilePageContent.biographyPlaceholder}
                                    biography={biography}
                                    setBiography={setBiography}
                                    fieldMarker={Field.BIOGRAPHY}
                                /> :
                                <ProfileBiography
                                    labelText={strings.myProfilePageContent.biographyDeLabel}
                                    placeholder={strings.myProfilePageContent.biographyDePlaceholder}
                                    biography={biographyDe}
                                    setBiography={setBiographyDe}
                                    fieldMarker={Field.BIOGRAPHY}
                                />
                        }
                    </>
                }
            </Row>
            <Row style={{ marginRight: "5px" }}>

                <Col md={6}></Col>

                <Col md={6}>
                    <EditProfileSectionFooter style={{ marginLeft: "170px" }}>
                        <UploadProfileImageButton onClick={() => setActiveTab(EditTab.SOCIAL_MEDIA)}>
                            {strings.myProfilePageContent.continueButtonTitle}
                        </UploadProfileImageButton>
                    </EditProfileSectionFooter>
                </Col>

            </Row>
        </EditProfileInformationField>
    }

    function SocialsTab() {
        return <EditProfileInformationField>
            <Row style={{ marginRight: "5px" }}>
                {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {

                    if (item === SocialMediaType.FACEBOOK) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.facebookLinkLabel}
                            type={SocialMediaType.FACEBOOK}
                            link={facebook}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            setLink={setFacebook} invalidLinkWarning={strings.myProfilePageContent.invalidFacebookLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconFacebookOriginal({ width: '20', height: '20' }) : IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.twitterLinkLabel}
                            type={SocialMediaType.TWITTER}
                            link={twitter}
                            setLink={setTwitter}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            invalidLinkWarning={strings.myProfilePageContent.invalidTwitterLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconTwitterOriginal({ width: '20', height: '20' }) : IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.linkedInLinkLabel}
                            type={SocialMediaType.LINKEDIN}
                            link={linkedIn}
                            setLink={setLinkedIn}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            invalidLinkWarning={strings.myProfilePageContent.invalidLinkedInLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconLinkedInOriginal({ width: '20', height: '20' }) : IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.XING) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.xingLinkLabel}
                            type={SocialMediaType.XING}
                            link={xing}
                            setLink={setXing}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            invalidLinkWarning={strings.myProfilePageContent.invalidXingLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconXingOriginal({ width: '20', height: '20' }) : IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.instagramLinkLabel}
                            type={SocialMediaType.INSTAGRAM}
                            link={instagram}
                            setLink={setInstagram}
                            invalidLinkWarning={strings.myProfilePageContent.invalidInstagramLinkWarning}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconInstagramOriginal({ width: '20', height: '20' }) : IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.youTubeLinkLabel}
                            type={SocialMediaType.YOUTUBE}
                            link={youTube}
                            setLink={setYouTube}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            invalidLinkWarning={strings.myProfilePageContent.invalidYouTubeLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconYouTubeOriginal({ width: '20', height: '20' }) : IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return <SocialMediaItem
                            labelText={strings.myProfilePageContent.pinterestLinkLabel}
                            type={SocialMediaType.PINTEREST}
                            link={pinterest}
                            setLink={setPinterest}
                            fieldMarker={Field.SOCIAL_MEDIA}
                            invalidLinkWarning={strings.myProfilePageContent.invalidYouTubeLinkWarning}
                            icon={branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconPinterestOrginal({ width: '20', height: '20' }) : IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })} />
                    }
                    return null
                })
                }
            </Row>
            <Row style={{ marginRight: "5px" }}>

                <Col md={6}></Col>

                <Col md={6}>
                    <EditProfileSectionFooter>
                        <DeleteProfileImageButton onClick={() => setActiveTab(EditTab.BASIC_INFO)}>
                            {strings.myProfilePageContent.backButtonTitle}
                        </DeleteProfileImageButton>

                        {itemsOrder.includes(EditTab.INTERESTS) &&

                            <UploadProfileImageButton onClick={() => setActiveTab(EditTab.INTERESTS)}>
                                {strings.myProfilePageContent.continueButtonTitle}
                            </UploadProfileImageButton>
                        }
                    </EditProfileSectionFooter>
                </Col>

            </Row>
        </EditProfileInformationField>
    }

    function InterestsTab() {
        return <EditProfileInformationField>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField style={{ marginTop: "-15px" }}>
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>
                        {strings.myProfilePageContent.yourInterestsSectionTitle}
                    </EditProfileLabel>
                    <ProfileCategories type={CategoryType.INTERESTS} setCategoryBindings={(newInterests) => setCategoryData({ ...categoryData, selectedInterests: newInterests })} data={categoryData} required={branding.myProfilePageContent.interestsRequired} />
                </EditProfileInformationField>
            </Row>
            <Row style={{ marginRight: "5px" }}>

                <Col md={6}>

                </Col>

                <Col md={6}>
                    <EditProfileSectionFooter>
                        <DeleteProfileImageButton onClick={() => setActiveTab(EditTab.SOCIAL_MEDIA)}>
                            {strings.myProfilePageContent.backButtonTitle}
                        </DeleteProfileImageButton>

                        {branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS) &&

                            <UploadProfileImageButton onClick={() => setActiveTab(EditTab.BUY_SELL)}>
                                {strings.myProfilePageContent.continueButtonTitle}
                            </UploadProfileImageButton>
                        }
                    </EditProfileSectionFooter>
                </Col>

            </Row>
        </EditProfileInformationField>
    }

    function BuySellTab() {
        return <EditProfileInformationField>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField style={{ marginTop: "-15px" }}>
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>
                        {offersSectionTitle}
                    </EditProfileLabel>
                    <ProfileCategories type={CategoryType.OFFERS} setCategoryBindings={(newOffers) => setCategoryData({ ...categoryData, selectedOfferings: newOffers })} data={categoryData} limitNumberOfSelectedCategories={true} setSectionTitle={setOffersSectionTitle} />
                </EditProfileInformationField>
            </Row>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <DummyDiv style={{ width: "100%", marginTop: "25px", marginBottom: "10px" }} />
            </Row>

            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField >
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>
                        {needsSectionTitle}
                    </EditProfileLabel>
                    <ProfileCategories type={CategoryType.NEEDS} setCategoryBindings={(newLookingfor) => setCategoryData({ ...categoryData, selectedLookingfor: newLookingfor })} data={categoryData} limitNumberOfSelectedCategories={true} setSectionTitle={setNeedsSectionTitle} />
                </EditProfileInformationField>
            </Row>
            <Row style={{ marginRight: "5px" }}>

                <Col md={6}></Col>

                <Col md={6}>
                    <EditProfileSectionFooter style={{ marginLeft: "170px" }}>
                        <DeleteProfileImageButton onClick={() => setActiveTab(EditTab.INTERESTS)}>
                            {strings.myProfilePageContent.backButtonTitle}
                        </DeleteProfileImageButton>
                    </EditProfileSectionFooter>
                </Col>

            </Row>
        </EditProfileInformationField>
    }

    return (<div style={{ fontFamily: branding.font1, color: branding.mainInfoColor }}>
        <div style={{ backgroundColor: "white" }}>

            <ScrollContainerRoot guestBannerHeight={props.guestBannerHeight}>
                <SaveCancelContainer ccOpen={appState.isNetworkingOpen()}>
                    <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                        <SaveLoader />
                    </div>
                    <DeleteProfileImageButton onClick={() => {
                        resetValues()
                        props.setMyProfileMode(MyProfilePageMode.VIEW)
                        history.push(myProfilePageRoute)
                    }}>
                        {strings.myProfilePageContent.cancelButtonText}
                    </DeleteProfileImageButton>

                    <UploadProfileImageButton onClick={() => {
                        trimValues()
                        handleDataChange()
                    }}>
                        {strings.myProfilePageContent.saveButtonText}
                    </UploadProfileImageButton>
                </SaveCancelContainer>
                <ContentScrollContainer>
                    <div style={{ position: "relative" }}>
                        <ProfileDetailRowHeader>
                            <ProfileImage
                                logoUrl={logoUrl}
                                setLogoUrl={setLogoUrl}
                                firstName={firstName}
                                setFirstName={setFirstName}
                                lastName={lastName}
                                setLastName={setLastName}
                                setIsProfileImageDeleted={setIsProfileImageDeleted}
                                setIsProfileImageChanged={setIsProfileImageChanged}
                                setImageDimensions={setImageDimensions}
                                setImageSize={setImageSize}
                                setImageFile={setImageFile}
                            />

                            <ProfileDetailNameTeaserCol xs={20} style={{ marginLeft: windowSize.width >= 1502 ? "60px" : "0px" }}>
                                <CrsTabs
                                    tabItems={entityFilterItems}
                                    activeItem={activeTab}
                                    onTabItemClicked={(value: any) => setActiveTab(value)}
                                    itemWidth={150}
                                />

                                {TabContent()}


                            </ProfileDetailNameTeaserCol>
                        </ProfileDetailRowHeader>
                    </div>
                </ContentScrollContainer>
            </ScrollContainerRoot>

            {showAlert && <DataUpdateAlert
                message={alertMessage}
                close={() => setShowAlert(false)}
                show={showAlert}
                type='edit-profile'
                titleImage={success ? IconIndicatorSuccessBlack({ fill: branding.sideIconBar.sideIconColorDark }) : IconIndicatorErrorBlack({ fill: branding.sideIconBar.sideIconColorDark })}
                position='top' />}
        </div>
    </div>
    );
}

export default EditMyProfileLayout


const EditProfileInformationField = styled.div`
    margin-top: 25px;
    text-align: left;
`

const EditProfileLabel = styled.div`
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    font-family: ${branding.font1};
    margin-bottom: 8px;
    font-weight: bold;
`

const EditProfileSectionFooter = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-left: 35px;

    bottom: 0;
    position: fixed;
`


const UploadProfileImageButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const DeleteProfileImageButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const LabelText = styled.p`
    margin-top: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    font-family: ${branding.font1};
`


export interface ProfileImageProps {

    logoUrl: string
    setLogoUrl: (value: string) => void
    firstName: string
    setFirstName: (value: string) => void
    lastName: string
    setLastName: (value: string) => void

    setIsProfileImageDeleted: (value: boolean) => void
    setIsProfileImageChanged: (value: boolean) => void

    setImageDimensions: (values: any) => void

    setImageSize: (value: number) => void
    setImageFile: (value: any) => void
}

const ProfileImage: React.FunctionComponent<ProfileImageProps> = React.memo((props: ProfileImageProps) => {
    const languageState = useLanguageState();
    const [localization, setLocalization] = useState(languageState.getStrings())
    const lang = languageState.getLanguage()

    const windowSize = useWindowDimensions()

    const userState = useLoggedInState()

    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")

    let imageInput: HTMLInputElement | null

    useEffect(() => {
        setLocalization(languageState.getStrings())
        // eslint-disable-next-line 
    }, [lang])

    function triggerProfileImageInputClick() {
        if (imageInput) {
            imageInput.click()
        }
    }

    function handleProfileImageChange() {
        if (imageInput && imageInput !== null && imageInput.files) {
            props.setIsProfileImageDeleted(false)
            props.setIsProfileImageChanged(true)

            var binaryData = []
            binaryData.push(imageInput.files[0])

            const objectURL = URL.createObjectURL(new Blob(binaryData, { type: "image" }))
            props.setImageSize(imageInput.files[0].size)
            props.setLogoUrl(objectURL)
            props.setImageFile(imageInput.files[0])

            var reader = new FileReader();
            reader.onloadend = function () {

                var image = new Image()
                image.src = objectURL
                image.onload = function () {
                    props.setImageDimensions({ height: image.naturalHeight, width: image.naturalWidth })
                }
            }

            reader.readAsDataURL(imageInput.files[0])

        }
    }

    function handleProfileImageDelete() {
        props.setIsProfileImageDeleted(true)
        props.setIsProfileImageChanged(false)
        props.setLogoUrl("")
    }


    return <>
        {windowSize.width >= 1502 ?
            <PersonImageAndLogo xs={3} style={{ height: "calc(100vh - 270px)" }} >
                <UserBasicInfoContainer>
                    <Row style={{ justifyContent: "center" }}>
                        <UserImageDiv>
                            <AvatarWithDefault size={100} src={props.logoUrl} alt={[props.firstName, props.lastName].join(" ") ?? "#"} backgroundSize="cover" />
                        </UserImageDiv>
                    </Row>

                    <Row style={{ justifyContent: "center", gap: "10px", marginTop: "20px" }}>
                        <div style={{ display: "inline-block" }}>
                            <input className="d-none" ref={input => imageInput = input} type="file" value="" onChange={handleProfileImageChange} accept=".jpg,.jpeg,.png" />

                            <UploadProfileImageButton onClick={triggerProfileImageInputClick}>
                                {localization.myProfilePageContent.uploadNewButtonText}
                            </UploadProfileImageButton>
                        </div>

                        <div style={{ display: "inline-block" }}>
                            <DeleteProfileImageButton onClick={() => handleProfileImageDelete()}>
                                {localization.myProfilePageContent.deleteCurrentPictureButtonText}
                            </DeleteProfileImageButton>
                        </div>
                    </Row>

                    <Row style={{ justifyContent: "center" }}>
                        <LabelText style={{ fontSize: "11.5px", fontWeight: "normal", color: "#B3B3B3" }}>
                            {localization.myProfilePageContent.formatsText}
                        </LabelText>
                    </Row>


                    <Row style={{ justifyContent: "center" }}>
                        <ProfileSocialsTitle style={{ width: "265px", marginLeft: "auto", marginRight: "auto" }}>
                            <Row>
                                <ProfilePageSectionTitle style={{ marginLeft: "15px", marginBottom: "15px" }}>
                                    {localization.communicationArea.settingsTitle}
                                </ProfilePageSectionTitle>
                            </Row>

                            <Row>
                                <div style={{ width: "265px" }}>
                                    <NetworkingToggle />
                                </div>
                            </Row>

                            <Row>
                                <div style={{ width: "265px" }}>
                                    <DoNotDisturbToggle />
                                </div>
                            </Row>
                        </ProfileSocialsTitle>
                    </Row>
                </UserBasicInfoContainer>
            </PersonImageAndLogo> :
            <PersonImageAndLogo md={12} style={{ borderRight: "none", marginLeft: "-70px" }}>
                <UserBasicInfoContainer style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                        <UserImageDiv>
                            <AvatarWithDefault size={100} src={props.logoUrl} alt={[props.firstName, props.lastName].join(" ") ?? "#"} backgroundSize="cover" />
                        </UserImageDiv>
                    </Row>

                    <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                        <UserFullName style={{ width: "673px" }}>{currentUserName}</UserFullName>
                    </Row>

                    <Row style={{ justifyContent: "center", gap: "10px", marginTop: "20px", marginLeft: "50px" }}>
                        <div style={{ display: "inline-block" }}>
                            <input className="d-none" ref={input => imageInput = input} type="file" value="" onChange={handleProfileImageChange} accept=".jpg,.jpeg,.png" />

                            <UploadProfileImageButton onClick={triggerProfileImageInputClick}>
                                {localization.myProfilePageContent.uploadNewButtonText}
                            </UploadProfileImageButton>
                        </div>

                        <div style={{ display: "inline-block" }}>
                            <DeleteProfileImageButton onClick={() => handleProfileImageDelete()}>
                                {localization.myProfilePageContent.deleteCurrentPictureButtonText}
                            </DeleteProfileImageButton>
                        </div>
                    </Row>

                    <Row style={{ justifyContent: "center", marginLeft: "50px" }}>
                        <LabelText style={{ fontSize: "11.5px", fontWeight: "normal", color: "#808080" }}>
                            {localization.myProfilePageContent.formatsText}
                        </LabelText>
                    </Row>

                    <Row style={{ justifyContent: "center" }}>
                        <ProfileSocialsTitle style={{ width: "625px", marginLeft: "90px" }}>
                            <Row>
                                <ProfilePageSectionTitle style={{ marginLeft: "15px", marginBottom: "15px" }}>
                                    {localization.communicationArea.settingsTitle}
                                </ProfilePageSectionTitle>
                            </Row>

                            <Row>
                                <div style={{ width: "500px" }}>
                                    <NetworkingToggle />
                                </div>
                            </Row>

                            <Row>
                                <div style={{ width: "500px" }}>
                                    <DoNotDisturbToggle />
                                </div>
                            </Row>
                        </ProfileSocialsTitle>
                    </Row>
                </UserBasicInfoContainer>
            </PersonImageAndLogo>
        }
    </>
})



function isSocialMediaLinkCorrect(link: string, socialMedia: SocialMediaType): boolean {
    /* TODO
    switch (socialMedia) {
        case SocialMediaType.FACEBOOK: {
            return link.includes("facebook.com/")
        }
        case SocialMediaType.TWITTER: {
            return link.includes("twitter.com/")
        }
        case SocialMediaType.LINKEDIN: {
            return link.includes("linkedin.com/")
        }
        case SocialMediaType.XING: {
            return link.includes("xing.com/")
        }
        case SocialMediaType.INSTAGRAM: {
            return link.includes("instagram.com/")
        }
        case SocialMediaType.YOUTUBE: {
            return link.includes("youtube.com/")
        }
        case SocialMediaType.PINTEREST: {
            return link.includes("pinterest.com/") || link.includes("pinterest.de/")
        }
        default: {
            return false
        }
    }
    */
    return true
}


interface ProfileInformationProps {
    required?: boolean
    labelText: string
    value: string
    setValue: (value: string) => void
    fieldMarker: Field
    placeholder: String

    disabled?: boolean
}

const ProfileInformation: React.FunctionComponent<ProfileInformationProps> = React.memo((props: ProfileInformationProps) => {

    const required = props.required || branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)
    const disabled = props.disabled || branding.myProfilePageContent.editDisabledFields.includes(props.fieldMarker)

    return <Col xs={12} md={6}>
        <EditProfileInformationField>
            <EditProfileLabel>
                {props.labelText} {(required && !disabled) && <span>*</span>}
            </EditProfileLabel>

            <TextField
                disabled={disabled}
                width="100%"
                height="40px"
                fontSize="14px"
                paddingLeft="8px"
                textColor={branding.mainInfoColor}
                fontFamily={branding.font1}
                value={props.value}
                setValue={props.setValue}
                placeholder={props.placeholder as string}
                backgroundColor={props.value === "" ? branding.myProfilePageContent.textFieldsBackgroundColor : ""}
                borderAround={"1px solid " + branding.myProfilePageContent.textFieldsBorderColor}
                outline="1px solid"
                borderRadius={"5px"}
            />
        </EditProfileInformationField>
    </Col>
})

interface ProfileBiographyProps {
    labelText: string
    biography: string
    setBiography: (value: string) => void
    placeholder: string
    fieldMarker: Field
}

const ProfileBiography: React.FunctionComponent<ProfileBiographyProps> = React.memo((props: ProfileBiographyProps) => {
    const maxTextLength: number = 1000
    const required = branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)

    return <Col xs={24} md={12}>
        <EditProfileInformationField>
            <EditProfileLabel>
                {props.labelText} {required && <span>*</span>}
            </EditProfileLabel>
            <MultiLineTextField
                width="100%"
                height="129px"
                fontSize="14px"
                textColor={branding.mainInfoColor}
                fontFamily={branding.font1}
                value={props.biography}
                setValue={props.setBiography}
                placeholder={props.placeholder}
                rows={6}
                backgroundColor={props.biography === "" ? branding.myProfilePageContent.textFieldsBackgroundColor : ""}
                borderAround="1px solid gray"
                outline={"1px solid " + branding.mainInfoColor}
                maxLength={maxTextLength}
                zIndex={0} />

            <TextLimit textLength={props.biography.length || 0} maxTextLength={maxTextLength} />
        </EditProfileInformationField>
    </Col>
})

interface SocialMediaProps {
    labelText: string
    type: SocialMediaType
    link: string
    setLink: (value: string) => void
    icon: any
    invalidLinkWarning: string
    fieldMarker: Field
}

const SocialMediaItem: React.FunctionComponent<SocialMediaProps> = React.memo((props: SocialMediaProps) => {

    return <Col xs={12} md={6}>
        <EditProfileInformationField>
            <EditProfileLabel>{props.labelText}</EditProfileLabel>
            <div style={{ display: "inline-block", width: "20px", marginRight: "20px" }}>
                {props.icon}
            </div>
            <div style={{ display: "inline-block", width: "calc(100% - 45px)" }}>
                <TextField
                    disabled={branding.myProfilePageContent.editDisabledSocialMedia.includes(props.type)}
                    width="100%"
                    height="30px"
                    fontSize="14px"
                    fontFamily={branding.mainInfoColor}
                    value={props.link}
                    borderColor="gray"
                    setValue={props.setLink}
                    placeholder=""
                    isSearchBar={false}
                    isEditProfile={true}
                />
            </div>
        </EditProfileInformationField>
    </Col>


})


const StyledSelect = styled(Select)`
    width: 100%;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 14px;
    margin-top: 8px;
    height: 35px;
    /* z-index: 100; // causing problem with dropdowns overlay */

`

interface CountrySwitcherProps {
    country: string
    setCountry: (value: string) => void
}

const CountrySwitcher: React.FunctionComponent<CountrySwitcherProps> = React.memo((props: CountrySwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const countries = getCountries(lang)
    const countriesFiltered = countries.map(c => { return { label: lang === "de" ? c.de : c.en, value: c.code } })

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.COUNTRY)

    return <Col xs={12} md={6}>
        <EditProfileInformationField>
            <EditProfileLabel>
                {strings.myProfilePageContent.countryLabel} {required && <span>*</span>}
            </EditProfileLabel>
            <StyledSelect
                placeholder={strings.myProfilePageContent.countrySwitcherText}
                isMulti={false}
                isSearchable={true}
                isClearable={true}
                options={countriesFiltered}
                value={props.country !== "" ? { label: findCountryName(props.country, lang), value: props.country } : null}
                onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                    if (value !== null && action.action === "select-option") {
                        const option = value as OptionTypeBase
                        if (props.country !== option.value)
                            props.setCountry(option.value)
                    } else
                        props.setCountry("")
                }}
                theme={SelectThemeCustom}
            />
        </EditProfileInformationField>
    </Col>
})

interface LanguageSwitcherProps {
    languages: any[]
    setLanguages: (value: any[]) => void
}

const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = React.memo((props: LanguageSwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const languages = getLanguages(lang)
    const languagesFiltered = languages.map(lng => { return { label: lang === "de" ? lng.de : lng.en, value: lng.code } })
    const [selectedLanguages, setSelectedLanguages] = useState<(OptionTypeBase | null)[]>(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.LANGUAGE)

    useEffect(() => {
        setSelectedLanguages(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))
    }, [lang]) // eslint-disable-line 


    return <Col xs={12} md={6}>
        <EditProfileInformationField>
            <EditProfileLabel>
                {strings.myProfilePageContent.languagesLabel} {required && <span>*</span>}
            </EditProfileLabel>

            <StyledSelect
                placeholder={strings.myProfilePageContent.languagesSwitcherText}
                isMulti={true}
                isSearchable={true}
                isClearable={true}
                options={languagesFiltered}
                value={selectedLanguages}
                onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                    if (value !== null) {
                        const newLanguages = value.map(x => { return (x as OptionTypeBase).value })
                        setSelectedLanguages(value)
                        props.setLanguages(newLanguages)
                    }
                }}
                theme={SelectThemeCustom}
                styles={colorStyles}
            />
        </EditProfileInformationField>
    </Col>
})

const TimeZoneSwitcher: React.FunctionComponent = React.memo(() => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    const timezones = getTimezones()
    const timezonesCustom = timezones.map(t => { return { label: t.name.slice(0, t.name.indexOf(" - ") || 150), value: t.timezones[0] } })
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone ?? moment.tz.guess())
    const timezoneNameFull = findTimezoneName(selectedTimezone)
    const timezoneName = timezoneNameFull.slice(0, timezoneNameFull.indexOf(" - ") || 150)
    const selectedTimezoneCustom = { label: timezoneName, value: selectedTimezone }

    function onTimezoneSelect(timezone: string) {
        setSelectedTimezone(timezone)
        appState.setTimeZone(timezone)
    }

    return <Col xs={12} md={6}>
        <EditProfileInformationField>
            <EditProfileLabel>
                {strings.myProfilePageContent.timeZoneLabel}
            </EditProfileLabel>

            <StyledSelect
                placeholder={timezoneName}
                isMulti={false}
                isSearchable={true}
                options={timezonesCustom}
                value={selectedTimezoneCustom}
                onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                    if (value !== null && action.action === "select-option") {
                        const option = value as OptionTypeBase
                        if (selectedTimezone !== option.value)
                            onTimezoneSelect(option.value)
                    }
                }}
                theme={SelectThemeCustom}
            />
        </EditProfileInformationField>
    </Col>
})

interface SpecialSearchBarProps {
    title: string
    searchParam: string
    setSearchParam: (value: string) => void
    dropdownSearch?: boolean
    dropdownOnClick?: () => void
}

const SpecialSearchBar: React.FunctionComponent<SpecialSearchBarProps> = React.memo((props: SpecialSearchBarProps) => {
    const [iconColor, setIconColor] = useState<string>("gray")

    return <div onMouseEnter={() => setIconColor(branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor)}
        onMouseLeave={() => {
            if (props.searchParam.length <= 0)
                setIconColor("gray")
        }}>
        <TextField
            placeholder={props.title}
            value={props.searchParam}
            width="100%"
            fontSize="14px"
            height="35px"
            paddingLeft="8px"
            setValue={props.setSearchParam}
            backgroundColor={branding.myProfilePageContent.textFieldsBackgroundColor}
            borderAround={"1px solid " + branding.myProfilePageContent.textFieldsBorderColor}
            outline="1px solid"

            startAdornment={<div style={{ marginBottom: '5px' }}>
                {!props.dropdownSearch && IconSearchSmall({ width: "20", height: "20", fill: iconColor })}
            </div>}
            borderRadius={"5px"}
        />
    </div>
})


export function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsLabel

        case CategoryType.OFFERS:
            return strings.imOfferingLabel

        case CategoryType.NEEDS:
            return strings.imLookingToBuyLabel

        default:
            return strings.interestsLabel
    }
}

export interface ProfileCategoriesProps {
    type: CategoryType
    data: CategoryData
    setCategoryBindings: (categories: Category[]) => void
    limitNumberOfSelectedCategories?: boolean
    required?: boolean

    setSectionTitle?: (title: string) => void
}

const ProfileCategories: React.FunctionComponent<ProfileCategoriesProps> = React.memo((props: ProfileCategoriesProps) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent

    const [searchParam, setSearchParam] = useState<string>("")

    let availableCategoriesToShow: Category[];
    let workingDataSet: Category[] = [];
    let selectedDataSet: Category[] = [];

    useEffect(() => {
        if (props.setSectionTitle) {
            props.setSectionTitle(getCategorySectionTitle(strings, props.type) +
                (props.limitNumberOfSelectedCategories ? " (" + (selectedDataSet.length || 0) + "/" + getLimitNumber() + ")"
                    : ""))
        }
        // eslint-disable-next-line
    }, [selectedDataSet])

    function addToMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet.push(category)
        props.setCategoryBindings(selectedDataSet)
    }

    function removeFromMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet = selectedDataSet.filter(i => i !== category)
        props.setCategoryBindings(selectedDataSet)
    }

    function getLimitNumber(): number {
        if (props.type === CategoryType.OFFERS) {
            return branding.myProfilePageContent.numberOfOffers
        }
        else if (props.type === CategoryType.NEEDS) {
            return branding.myProfilePageContent.numberOfNeeds
        }
        else {
            return 5
        }
    }



    switch (props.type) {
        case CategoryType.INTERESTS:
            workingDataSet = props.data.interests
            selectedDataSet = props.data.selectedInterests
            break
        case CategoryType.OFFERS:
            workingDataSet = props.data.offerings
            selectedDataSet = props.data.selectedOfferings
            break
        case CategoryType.NEEDS:
            workingDataSet = props.data.lookingfor
            selectedDataSet = props.data.selectedLookingfor
            break
    }
    workingDataSet = workingDataSet.filter(i => !selectedDataSet.includes(i));

    availableCategoriesToShow = workingDataSet.filter((i: any) => i.name.toLowerCase().startsWith(searchParam.toLowerCase()))

    return <Row className="ml-20">
        <Col xs={24} md={12}>
            {(workingDataSet.length > 0 || selectedDataSet.length > 0) &&
                <>
                    <div>
                        <CategoriesViewRow key="selected-categories" style={{ marginLeft: "-20px" }}>
                            <Col xs={24} md={12}>
                                {selectedDataSet.map((category, key) => {
                                    return (<>
                                        <CategoriesItem
                                            key={key}
                                            onClick={() => removeFromMyCategories(selectedDataSet, category)}>
                                            {category.name}
                                        </CategoriesItem>

                                    </>
                                    );
                                })
                                }
                            </Col>
                        </CategoriesViewRow>

                        <Row key="search">
                            <Col xs={24} md={12}>
                                <div style={{ marginTop: "15px" }}>
                                    <SpecialSearchBar searchParam={searchParam} setSearchParam={setSearchParam} title={strings.searchPlaceholder} />

                                </div>
                            </Col>
                        </Row>

                        {
                            props.type === CategoryType.INTERESTS &&
                            <CategoriesViewRow key="available-categories" style={{ marginLeft: "-20px" }}>
                                <ProfileCategoriesList
                                    items={availableCategoriesToShow}
                                    searchParam={searchParam}
                                    isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                    limitNumber={getLimitNumber()}
                                    selectedCategoriesNumber={selectedDataSet.length}
                                    addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                    strings={strings}
                                />
                            </CategoriesViewRow>
                        }

                        {
                            (props.type === CategoryType.OFFERS || props.type === CategoryType.NEEDS) &&
                            <CategoriesViewRow key="available-categories" style={{ marginLeft: "-20px" }}>
                                {branding.myProfilePageContent.offersNeedsCategories.length > 0 &&
                                    branding.myProfilePageContent.offersNeedsCategories.filter(x => x.id !== "default").map((parentCategory, key) => {
                                        let parentCategoryItems = availableCategoriesToShow.filter((i: any) => i.parent === parentCategory.id)
                                        return <div key={key}>
                                            {parentCategoryItems.length > 0 &&
                                                <Col md={12}>
                                                    <LabelText style={{ marginLeft: "20px", fontWeight: "normal" }}>
                                                        {parentCategory.name}
                                                    </LabelText>

                                                    <ProfileCategoriesList
                                                        items={parentCategoryItems}
                                                        searchParam={searchParam}
                                                        isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                                        limitNumber={getLimitNumber()}
                                                        selectedCategoriesNumber={selectedDataSet.length}
                                                        addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                                        strings={strings}
                                                    />
                                                </Col>
                                            }
                                        </div>

                                    })
                                }
                                {branding.myProfilePageContent.offersNeedsCategories.length === 0 &&
                                    <CategoriesViewRow key="available-categories" style={{ marginLeft: "-20px" }}>
                                        <ProfileCategoriesList
                                            items={availableCategoriesToShow}
                                            searchParam={searchParam}
                                            isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                            limitNumber={getLimitNumber()}
                                            selectedCategoriesNumber={selectedDataSet.length}
                                            addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                            strings={strings}
                                        />
                                    </CategoriesViewRow>
                                }
                            </CategoriesViewRow>
                        }


                    </div>
                </>
            }
        </Col>
    </Row >
})

export interface ProfileCategoriesListProps {
    items: any[]
    searchParam: string
    strings: any
    isNumberLimited: boolean
    limitNumber: number
    selectedCategoriesNumber: number
    addToMyCategories: (item: any) => void
}

const ProfileCategoriesList: React.FunctionComponent<ProfileCategoriesListProps> = React.memo((props: ProfileCategoriesListProps) => {
    const [showMore, setShowMore] = useState<boolean>(false)

    return <Col xs={24} md={12}>
        {((showMore || props.searchParam.length > 0) ? props.items : props.items.slice(0, branding.myProfilePageContent.numberOfCategories)).map((category, key) => {
            return (
                <CategoriesItem
                    border="1px solid #727272"
                    textColor="#727272"
                    key={key}
                    onClick={() => props.addToMyCategories(category)}
                    style={{
                        pointerEvents: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "none" : "auto",
                        cursor: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "not-allowed" : "pointer"
                    }}>
                    {category.name}
                </CategoriesItem>
            );
        })
        }
        {(!showMore && props.searchParam.length === 0 && props.items.length > branding.myProfilePageContent.numberOfCategories) &&
            <WhiteButton onClick={() => setShowMore(true)}>
                {props.strings.showMoreButtonText}
            </WhiteButton>
        }
        {(showMore && props.searchParam.length === 0) &&
            <WhiteButton onClick={() => setShowMore(false)}>
                {props.strings.showLessButtonText}
            </WhiteButton>
        }
    </Col>
})

const WhiteButton = styled(Button)`
    display: inline-block;
    background-color: #fff !important;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'} !important;
    padding: .5rem;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: ${branding.primaryColor} !important;
    user-select: none;
    border-radius: 1.25rem;
    margin-left: 10px;
    text-align: center;
    height: 30px;
    margin: .3rem;

    &:active, &:focus, &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: ${branding.primaryColor} !important;
        background-color: #fff;
        border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    }
`

export const SelectThemeCustom = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

const colorStyles = {
    control: (styles: any) => ({ ...styles, maxHeight: "70px" }),
    valueContainer: (styles: any) => ({ ...styles, maxHeight: "65px", overflow: "auto" })
};